import React from "react";
import { Container, Typography, Grid, Box, Button } from "@mui/material";
import ProductListView from "../../../../Components/Widgets/ProductListView";
import WishlistDialog from "../../../../Components/Widgets/Dialogs/WishlistDialog";
import ProductListSkeletonView from "../../widgets/product_list_skeleton_view";

export default function Wishlist(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const products = component.getData("default.products", null);
  const [wishlistDialogOpen, setWishlistDialogOpen] = React.useState(false);

  return (
    <Container
      sx={{
        pb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "600px",
      }}
      disableGutters
      maxWidth="lg"
    >
      <Box sx={{ textAlign: "center", py: 3 }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "28px",
          }}
        >
          {component.ucfirst("wishlist")}
        </Typography>

        <Typography
          component="h3"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "14px", md: "18px" },
            color: "#000",
            maxWidth: "800px",
            mt: 3,
          }}
        >
          {component.ucfirst("wishlist-offer")}
        </Typography>
        <Button
          onClick={() => {
            setWishlistDialogOpen(true);
          }}
          sx={{
            visibility:
              products && products.length !== 0 ? "visible" : "hidden",
            background: "rgb(251, 191, 105,1)",
            color: "#000",
            borderColor: "#000",
            fontWeight: 600,
            width: 150,
            "&:hover": {
              borderColor: "#000",
              backgroundColor: "rgb(251, 191, 105,1)",
            },
            mt: 2,
            fontSize: 16,
          }}
          endIcon={
            <i style={{ fontSize: 18 }} className="fa-light fa-paper-plane" />
          }
        >
          send
        </Button>
      </Box>

      {!products ? (
        <Grid
          container
          direction="row"
          sx={{
            alignItems: "stretch",
          }}
          columnSpacing={1}
          rowSpacing={1}
        >
          {[...new Array(40)].map((product, index) => (
            <ProductListSkeletonView
              key={`skeleton-${index}`}
              {...{
                ...props,
                ...{
                  grid: 4,
                },
              }}
            />
          ))}
        </Grid>
      ) : (
        <>
          {products.length !== 0 ? (
            <Grid
              container
              sx={{
                flexGrow: 1,
                alignItems: "stretch",
              }}
              columnSpacing={1}
              rowSpacing={1}
            >
              {products.map((product, index) => (
                <ProductListView
                  key={devHelper.getObjectValue(product, "_id")}
                  {...{
                    ...props,
                    ...{
                      product,
                      grid: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                "@media (max-width: 899px)": { display: "block" },
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  mt: "50px",
                }}
              >
                <Typography>
                  {component.ucfirst("empty-wishlist-message")}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
      <WishlistDialog
        {...{
          ...props,
          ...{
            open: wishlistDialogOpen,
            setOpen: (boolean) => setWishlistDialogOpen(boolean),
          },
        }}
      />
    </Container>
  );
}
