import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/category";

export default class Category extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    const { category } = this.getPage().getParams();
    this.getApp().updateWindowTitle(
      this.ucfirst(
        `${this.ucfirst(category)} | ${this.ucfirst("window-title")}`
      )
    );

    setTimeout(() => {
      this.scrollToTop();
    }, 50);
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (prevProps.location.pathname !== this.props.location.pathname) {
      const { category } = this.getPage().getParams();
      this.getApp().updateWindowTitle(
        this.ucfirst(
          `${this.ucfirst(category)} | ${this.ucfirst("window-title")}`
        )
      );
      this.getComponents().findById("grid-categories").first().getMenu();
      this.scrollToTop();
    }
  }
}
