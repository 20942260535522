import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Button,
  Typography,
  Grid,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  CircularProgress,
  CardActions,
  CardContent,
} from "@mui/material";
import AddressDialog from "../../../../Components/Widgets/Dialogs/AddressDialog";
import CloseIcon from "@mui/icons-material/Close";

const FullAddress = styled(Typography)(({ theme }) => ({
  // whiteSpace: "nowrap",
  width: "100%",
  // overflow: "hidden",
  // textOverflow: "ellipsis",
  marginBottom: 20,
}));

const MyTypography = styled(Typography)(({ theme }) => ({
  // whiteSpace: "nowrap",
  width: "100%",
  // overflow: "hidden",
  // textOverflow: "ellipsis",
}));

const ActionBtn = styled(Link)(({ theme }) => ({
  color: "#000",
  fontSize: "13px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginTop: "24px",
  padding: "12px 14px",
  background: "#fff",
  color: "#000",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const addresses = component.getData("default.addresses", null);

  const [newAddressDialogOpen, setNewAddressDialogOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [address_id, setId] = React.useState(null);

  const handleClickOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="xl"
    >
      <Typography
        align="center"
        component="h1"
        sx={{ lineHeight: 1, fontSize: "34px", color: "#000", mt: 3 }}
      >
        {component.ucfirst("addresses-title")}
      </Typography>
      <AddButton
        variant="outlined"
        onClick={() => {
          setNewAddressDialogOpen(true);
          component.activateAutocomplete();
        }}
        startIcon={<i className="fak fa-light-location-dot-circle-plus" />}
      >
        {component.trans("add-new-address-btn")}
      </AddButton>
      {!addresses ? (
        <CircularProgress
          size={30}
          sx={{
            my: 20,
            color: "#000",
          }}
        />
      ) : (
        <>
          {addresses.length !== 0 ? (
            <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 3 }}>
              {addresses.map((address) => (
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  key={devHelper.getObjectValue(address, "id")}
                >
                  <Paper
                    sx={{
                      padding: "20px",
                      margin: "5px 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      minHeight: "100%",
                    }}
                    elevation={3}
                  >
                    <CardContent sx={{ p: 0, mb: 2 }}>
                      <FullAddress variant="h6">
                        {devHelper.getObjectValue(address, "address")}
                      </FullAddress>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("first_name-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "first_name")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("last_name-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "last_name")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("street_address-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "street")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("city-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "city")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("state-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "state")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("postal_code-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "postal_code")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("country-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "country")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("phone-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "phone")}
                      </MyTypography>
                    </CardContent>{" "}
                    <CardActions
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        mt: "auto",
                        p: 0,
                      }}
                    >
                      <ActionBtn
                        underline="none"
                        onClick={() => {
                          component.setAddressId(
                            devHelper.getObjectValue(address, "id")
                          );
                          setNewAddressDialogOpen(true);
                          component.activateAutocomplete();
                        }}
                      >
                        {component.ucfirst("edit-btn")}
                      </ActionBtn>
                      <ActionBtn
                        underline="none"
                        onClick={() =>
                          handleClickOpen(
                            devHelper.getObjectValue(address, "id")
                          )
                        }
                        sx={{ ml: 1 }}
                      >
                        {component.ucfirst("remove-btn")}
                      </ActionBtn>
                    </CardActions>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography align="center" sx={{ my: 20, height: "30px" }}>
              {component.ucfirst("zero-addresses")}
            </Typography>
          )}
        </>
      )}

      <AddressDialog
        {...{
          ...props,
          ...{
            open: newAddressDialogOpen,
            setOpen: (boolean) => setNewAddressDialogOpen(boolean),
            retrieveAddresses: () => component.retrieveAddresses(),
          },
        }}
      />
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="xs">
        <MyDialogTitle onClose={() => setOpen(false)}>
          {component.ucfirst("delete-address-title")}
        </MyDialogTitle>
        <DialogContent dividers>
          <form
            onSubmit={(e) => {
              component.deleteAddress(e, address_id);
              setOpen(false);
            }}
          >
            <Typography gutterBottom>
              {component.ucfirst("delete-address-text")}
            </Typography>
            <MyButton type="submit" fullWidth>
              {component.ucfirst("confirm-btn")}
            </MyButton>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
