import React from "react";
import { useTheme, styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  useMediaQuery,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import ProductListView from "../../../Components/Widgets/ProductListView";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const BackNextButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "50%",
  backgroundColor: "#fff",
  color: "#000",
  fontSize: "20px",
  zIndex: 1,
  width: "35px",
  height: "35px",
  justifyContent: "center",
  alignItems: "center",
  "&:disabled": {
    pointerEvents: "all",
    backgroundColor: "#fff",
    color: "#000",
    opacity: "0.5",
  },
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

export default function RecentlyViewed(props) {
  const { component } = props;
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const items_per_view = md ? 5 : sm ? 3 : 2;

  const [activeView, setActiveView] = React.useState(0);
  const products = component.getData("default.products", []);
  const devHelper = component.getHelpers("dev");

  const handleViewChange = (step) => {
    setActiveView(step);
  };

  const handleNext = () => {
    setActiveView((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveView((prevActiveStep) => prevActiveStep - 1);
  };

  const item_width = 12 / items_per_view;

  return (
    <>
      {products.length !== 0 && (
        <Container maxWidth="lg" disableGutters sx={{ my: 2 }}>
          <Typography
            sx={{
              fontSize: { xs: 20, md: 26 },
              color: "#000",
              fontWeight: 500,
              mb: 2,
              ml: 1,
            }}
          >
            {component.trans("recently-viewed")}
          </Typography>
          <Box sx={{ position: "relative" }}>
            <BackNextButton
              sx={{
                left: 5,
                display: { xs: "none", md: "flex" },
              }}
              onClick={handleBack}
              disabled={activeView === 0}
            >
              <i className="fa-solid fa-chevron-left" />
            </BackNextButton>
            <BackNextButton
              sx={{
                right: 5,
                display: { xs: "none", md: "flex" },
              }}
              onClick={handleNext}
              disabled={
                activeView === Math.ceil(products.length / items_per_view) - 1
              }
            >
              <i className="fa-solid fa-chevron-right" />
            </BackNextButton>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeView}
              onChangeIndex={handleViewChange}
              enableMouseEvents
              interval={6000}
            >
              {Array.from(
                {
                  length: Math.ceil(products.length / items_per_view),
                },
                (_, index) => (
                  <Grid
                    key={"grid-" + index}
                    container
                    sx={{
                      alignItems: "flex-start",
                    }}
                    justifycontent="center"
                    direction="row"
                    spacing={"4px"}
                  >
                    {products
                      .slice(
                        index * items_per_view,
                        (index + 1) * items_per_view
                      )
                      .map((product, i) => (
                        <ProductListView
                          key={devHelper.getObjectValue(product, "_id")}
                          {...{
                            ...props,
                            ...{
                              product,
                              grid: item_width,
                              xs: item_width,
                            },
                          }}
                        />
                      ))}
                  </Grid>
                )
              )}
            </AutoPlaySwipeableViews>
          </Box>
        </Container>
      )}
    </>
  );
}
