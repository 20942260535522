import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/grid_brands";
import Services from "../../../Services/Services";

export default class GridBrands extends Component {
  id = "grid-brands";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(([service]) => {
      service
        .exec("brands", {
          params: {},
        })
        .then((response) => {
          const sliders = response.getData() || [];
          this.setData({
            "default.sliders": sliders,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
