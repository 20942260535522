import Home from "./Page/Home";
import Assistance from "./Page/Assistance";
import Company from "./Page/Company";
import Hotels from "./Page/Hotels";
import Category from "./Page/Category";
import SubCategory from "./Page/SubCategory";
import Stores from "./Page/Stores";
import Brands from "./Page/Brands";
import BrandHome from "./Page/BrandHome";
import B2BBrands from "./Page/B2BBrands";
import Bazaar from "./Page/Bazaar";
import Designers from "./Page/Designers";

import Collection from "./Page/Collection";
import Product from "./Page/Product";
import Wishlist from "./Page/Wishlist";
import Cart from "./Page/Cart";
import Checkout from "./Page/Checkout";

// User Section
import Profile from "./Page/User/Profile";
import Addresses from "./Page/User/Addresses";
import Orders from "./Page/User/Orders";
import Order from "./Page/User/Order";
import Coupons from "./Page/User/Coupons";

// Redirect Section
import Redirect from "./Page/Redirect";

const Module = {
  Home,
  Assistance,
  Company,
  Hotels,
  Category,
  SubCategory,
  Collection,
  Product,
  Wishlist,
  Stores,
  Cart,
  Checkout,
  Brands,
  B2BBrands,
  Bazaar,
  BrandHome,
  // User Section
  Profile,
  Addresses,
  Orders,
  Order,
  Coupons,
  Designers,
  // Redirect Section
  Redirect,
};

export default Module;
