import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/wishlist_dialog";
import Services from "../../../Services/Services";

export default class WishlistDialog extends Component {
  id = "wishlist-dialog";

  template = templateDefault;

  default_fields = {
    name: "",
    email: "",
    phone: "",
    store: "",
    message: "",
  };

  stores = [
    { slug: "store-1", name: "ΚΗΦΙΣΙΑ", email: "info1@casadipatsi.gr" },
    { slug: "store-2", name: "ΑΛΙΜΟΣ", email: "info2@casadipatsi.gr" },
    {
      slug: "store-3",
      name: "ΑΓΙΑ ΠΑΡΑΣΚΕΥΗ",
      email: "info3@casadipatsi.gr",
    },
    {
      slug: "store-6",
      name: "ΗΡΑΚΛΕΙΟ ΚΡΗΤΗΣ",
      email: "g.patsis@casadipatsi.gr",
    },
  ];

  onAuth() {
    this.setAuthFields();
  }

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
      "default.stores": this.stores,
      "default.fields": {
        ...this.default_fields,
      },
    });

    this.setAuthFields();
  }

  setAuthFields() {
    const auth = this.getAuth();

    if (auth.isAuthenticated()) {
      const { name, username } = auth.getUser();

      this.setData({
        "default.fields.name": name,
        "default.fields.email": username,
      });
    } else {
      this.setData({
        "default.fields.name": "",
        "default.fields.email": "",
      });
    }
  }

  sendRequest(e) {
    e.preventDefault();

    var wishlist = this.getPage().getData("default.wishlist", "");

    const error = {
      fields: {},
    };

    const {
      name,
      email,
      phone,
      store,
      //  message
    } = this.getData("default.fields", {});

    if (!name) {
      error.fields["name"] = this.ucfirst("fullname-helpertext");
    }

    if (!email) {
      error.fields["email"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(email)) {
        error.fields["email"] = this.ucfirst("invalid-email");
      }
    }

    if (!phone) {
      error.fields["phone"] = this.ucfirst("phone-helpertext");
    } else if (phone.length !== 10) {
      error.fields["phone"] = this.ucfirst("phone-helpertext-2");
    }

    if (!store) {
      error.fields["store"] = this.ucfirst("store-helpertext");
    }

    // if (!message) {
    //   error.fields["message"] = this.ucfirst("message-helpertext");
    // }

    this.setData({
      error,
    });

    if (Object.keys(error.fields).length === 0) {
      const fields = {
        ...this.getData("default.fields", {
          ...this.default_fields,
        }),
      };
      fields["store"] = this.stores.find((st) => st.slug === store);

      Services.get("hook").then(async ([hookService]) => {
        await hookService.post("wishlist", {
          ...fields,
          ...{ products: wishlist },
        });

        this.setData({
          "default.fields": {
            ...this.default_fields,
          },
          error: null,
        });

        this.props.setOpen(false);

        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            "message-duration": 2000,
            "success-message": this.trans("message-send"),
          });
      });
    }
  }
}
