import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/wishlist/wishlist";
import Services from "../../Services/Services";

export default class Wishlist extends Component {
  id = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getWishlistProducts();
  }

  getWishlistProducts() {
    var wishlist = this.getHelpers("state").get("wishlist", null);

    if (wishlist) {
      Services.get("hook,wishlist").then(([service, wishlistService]) => {
        var promises = wishlist
          .split(",")
          .reverse()
          .map((slug) => {
            return service.exec("product", {
              params: { slug },
            });
          });

        Promise.all(promises).then((products) => {
          products = products
            .map((response) => response.getData())
            .filter((product) => product);

          wishlistService.updateWishlist(
            products.map(({ _values }) => _values.slug),
            this
          );

          this.setData({
            "default.products": products,
          });

          this.getPage().setData({
            "default.wishlist": products.map(({ _values }) => _values.slug),
          });
        });
      });
    } else {
      this.setData({
        "default.products": [],
      });
    }
  }
}
