import React from "react";
import {
  Container,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Link,
  Box,
  CardActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Translate from "../../../../../modules/Core/Components/Translate";

const MyLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#28282B",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
  "& i": {
    minWidth: 35,
    fontSize: "22px",
  },
  "& div": {
    textAlign: "center",
    display: "inline-flex",
    mb: 0,
  },
  "&:hover": {
    color: "#000",
  },
}));

export default function Stores(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const stores = component.getData("default.stores", null);

  return (
    <Box>
      <Typography
        component="h1"
        sx={{
          fontSize: { xs: "16px", md: "22px" },
          fontWeight: 600,
          textAlign: "center",
          color: "#28282B",
          px: 2,
          my: 4,
        }}
      >
        {component.ucfirst("stores-title")}
      </Typography>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 3,
        }}
        maxWidth="xl"
      >
        {!stores ? (
          <CircularProgress
            style={{
              margin: "80px 0",
              color: "#28282B",
            }}
          />
        ) : (
          <Grid container direction="row" spacing={0}>
            <Grid item lg={2.5} sm={12} xs={12}>
              <Card
                elevation={0}
                sx={{
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  bgcolor: "transparent",
                }}
              >
                <Box sx={{ "& div": { mb: 1 } }}>
                  <Typography
                    sx={{
                      fontSize: { xs: 18, md: 22 },
                      fontWeight: 500,
                      color: "rgb(255, 99, 99,1)",
                      mb: 1,
                      "& i": { mr: 1 },
                    }}
                  >
                    <i className="fa-light fa-calendar-clock" />
                    {component.ucfirst("stores-title-2")}
                  </Typography>
                  <Translate
                    entity="literal"
                    eslug="stores-hours"
                    scope="default"
                    slug="default"
                    auto
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 18, md: 22 },
                      fontWeight: 500,
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    {component.ucfirst("deliveries-department")}
                  </Typography>
                  <MyLink
                    href={
                      "tel:" + component.trans("deliveries-department-phone")
                    }
                    onClick={() =>
                      component.getApp().addClick({
                        type: "phone",
                        info: component.trans("deliveries-department-phone"),
                      })
                    }
                  >
                    <Box>
                      <i className="fa-light fa-circle-phone" />
                    </Box>
                    {component.trans("deliveries-department-phone")}
                  </MyLink>
                  <MyLink disabled>
                    {component.trans("deliveries-department-days")}
                    <br />
                    {component.trans("deliveries-department-hours")}
                  </MyLink>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={8.5} sm={12} xs={12}>
              <Grid container direction="row" spacing={2}>
                {stores.map((store) => {
                  const { slug, name, url, phone, email, website } =
                    devHelper.getObjectValue(store, "_values");

                  return (
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={12}
                      key={devHelper.getObjectValue(store, "_id")}
                    >
                      <Card
                        elevation={1}
                        sx={{
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          p: 1,
                        }}
                      >
                        <CardContent>
                          <Typography
                            sx={{
                              fontSize: { xs: 18, md: 22 },
                              fontWeight: 500,
                              color: "#0E2431",
                              mb: 2,
                            }}
                          >
                            <Translate
                              entity="item"
                              eslug={slug}
                              scope="default"
                              slug="name"
                              auto
                            />
                          </Typography>
                          <MyLink
                            href={url}
                            target="_blank"
                            underline="none"
                            onClick={() =>
                              component.getApp().addClick({
                                store: slug,
                                type: "address",
                                info: name,
                              })
                            }
                          >
                            <Box>
                              <i className="fa-light fa-location-dot" />
                            </Box>
                            <Translate
                              entity="item"
                              eslug={slug}
                              scope="default"
                              slug="address"
                              auto
                            />
                          </MyLink>
                          <MyLink
                            href={"tel:" + phone}
                            onClick={() =>
                              component.getApp().addClick({
                                store: slug,
                                type: "phone",
                                info: phone,
                              })
                            }
                          >
                            <Box>
                              <i className="fa-light fa-circle-phone" />
                            </Box>
                            {phone}
                          </MyLink>
                          <MyLink
                            href={"mailto:" + email}
                            target="_blank"
                            onClick={() =>
                              component.getApp().addClick({
                                store: slug,
                                type: "email",
                                info: email,
                              })
                            }
                          >
                            <Box>
                              <i className="fa-light fa-circle-envelope" />
                            </Box>
                            {email}
                          </MyLink>
                          {website && (
                            <MyLink href={"https://" + website} target="_blank">
                              <Box>
                                <i className="fa-light fa-browser" />
                              </Box>
                              {website}
                            </MyLink>
                          )}
                          <Box
                            sx={{
                              ".title": {
                                color: "rgb(255, 99, 99,1)",
                              },
                              textAlign: "center",
                            }}
                          >
                            <Translate
                              entity="item"
                              eslug={slug}
                              scope="default"
                              slug="extra_info"
                              auto
                            />
                          </Box>
                        </CardContent>
                        <CardActions>
                          <Link
                            href={url}
                            target="_blank"
                            variant="button"
                            underline="none"
                            sx={{
                              bgcolor: "#28282B",
                              color: "#fff",
                              width: "100%",
                              textAlign: "center",
                              borderRadius: "10px",
                              textTransform: "none",
                              mx: 2,
                              p: "6px",
                              "&:hover": {
                                opacity: "0.9",
                              },
                            }}
                            onClick={() =>
                              component.getApp().addClick({
                                store: slug,
                                type: "address",
                                info: name,
                              })
                            }
                          >
                            {component.trans("map-btn")}
                          </Link>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
}
