import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/address_dialog";
import Services from "../../../Services/Services";

export default class AddressDialog extends Component {
  id = "address-dialog";

  template = templateDefault;

  default_address = {
    first_name: "",
    last_name: "",
    street: "",
    city: "",
    postal_code: "",
    phone: "",
    state: "",
    country: "",
  };

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
      "default.address": { ...this.default_address },
    });
  }

  selectAddress(id) {
    Services.get("address").then(([addressService]) => {
      addressService
        .getAddress(id)
        .then((addressService) => {
          this.setData({
            "default.address": addressService.getData("address", {
              ...this.default_address,
            }),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  resetAddress() {
    this.setData({
      "default.address": { ...this.default_address },
    });
    this.deleteData("error");
  }

  addAddress(e) {
    e.preventDefault();

    const { address } = this.getData("default", { ...this.default_address });

    const invalid = this.getData("invalid", false);
    const addressId = address._id;
    const onUpdate = addressId ? true : false;

    const error = {
      address: this.getHelpers("validate").validate(address, {
        first_name: [
          {
            rule: "required",
            message: this.ucfirst("first_name-helpertext"),
          },
        ],
        last_name: [
          {
            rule: "required",
            message: this.ucfirst("last_name-helpertext"),
          },
        ],
        street: [
          {
            rule: "required",
            message: this.ucfirst("street_address-helpertext"),
          },
        ],
        street_number: [
          {
            rule: "required",
            message: this.ucfirst("street_number-helpertext"),
          },
        ],
        city: [
          {
            rule: "required",
            message: this.ucfirst("city-helpertext"),
          },
        ],
        postal_code: [
          {
            rule: "required",
            message: this.ucfirst("postal_code-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("postal_code-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("postal_code-helpertext-2"),
            min: 5,
            max: 5,
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
      }),
    };

    this.setData({
      error,
    });

    if (!invalid && !error.address) {
      address.street = `${address.street.replace(/[0-9]/g, "")}${
        address.street_number
      }`;

      address.address = `${address.street}, ${address.city},${address.state} ${address.postal_code} ${address.country}`;

      Services.get("address").then(([addressService]) => {
        if (onUpdate) {
          addressService
            .updateAddress(addressId, address)
            .then((addressService) => {
              this.props.retrieveAddresses();
              this.props.setOpen(false);
              this.resetAddress();
            })
            .catch((err) => {
              console.log("error", err);
            });
        } else {
          addressService
            .createAddress(address)
            .then((addressService) => {
              this.props.retrieveAddresses();
              this.props.setOpen(false);
              this.resetAddress();
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
      });
    }
  }

  initGoogleAutocomplete() {
    setTimeout(() => {
      let autocomplete;
      if (document.getElementById("autocomplete")) {
        autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("autocomplete"),
          {
            types: ["address"],
            componentRistrictions: { country: "gr" },
            fields: ["address_components", "geometry"],
          }
        );

        autocomplete.addListener("place_changed", () => {
          var place = autocomplete.getPlace();
          if (place.geometry) {
            let postcode = "";
            let street_number = "";
            let street = "";
            let country = "";
            let state = "";
            let city = "";

            for (const component of place.address_components) {
              const componentType = component.types[0];

              switch (componentType) {
                case "street_number": {
                  street_number = component.long_name;
                  break;
                }
                case "route": {
                  street = component.short_name;
                  break;
                }
                case "postal_code": {
                  postcode = `${component.long_name}${postcode}`;
                  break;
                }
                case "postal_code_suffix": {
                  postcode = `${postcode}-${component.long_name}`;
                  break;
                }
                case "locality":
                  city = component.short_name;
                  break;
                case "administrative_area_level_3": {
                  state = component.short_name;
                  break;
                }
                case "country":
                  country = component.long_name;
                  break;
                default: {
                  //
                }
              }
            }

            const { address } = this.getData("default", {
              ...this.default_address,
            });

            if (street_number === "" || street === "") {
              this.getComponents()
                .findById("main-message")
                .first()
                .setData({
                  "error-message": this.trans("incomplete-address-message"),
                });

              this.setData({
                invalid: true,
              });
            } else {
              address["street"] = `${street} ${street_number}`;
              address["street_number"] = street_number;
              address["city"] = city || "";
              address["postal_code"] = postcode.replace(" ", "") || "";
              address["state"] = state;
              address["country"] = country;

              this.setData({
                "default.address": address,
                invalid: false,
              });
            }
          }
        });
      }
    }, 500);
  }
}
