import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/assistance/assistance";
import Services from "../../Services/Services";

export default class Assistance extends Component {
  id = "assistance";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setSection();

    this.getFooterLinks();
  }

  setSection() {
    this.getApp().updateWindowTitle(
      `Assistance | ${this.ucfirst("window-title")}`
    );
    this.setData({
      "default.section": this.getPage().getParam("section", ""),
    });
  }

  getFooterLinks() {
    Services.get("hook").then(([service]) => {
      service
        .exec("statics", {
          params: { scope: "legal-and-privacy" },
        })
        .then((response) => {
          const links = response.getData() || {};
          this.setData({
            "default.links": links,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
