import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/brands_slider";
import Services from "../../../Services/Services";

export default class BrandsSlider extends Component {
  id = "brands-slider";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(([service]) => {
      service
        .exec("brands", {
          params: {},
        })
        .then((response) => {
          const slides = response.getData() || [];
          this.setData({
            "default.slides": slides.sort(function (a, b) {
              const positionA =
                typeof a._values.position === "undefined"
                  ? 1000
                  : a._values.position;
              const positionB =
                typeof b._values.position === "undefined"
                  ? 1000
                  : b._values.position;

              return positionA - positionB;
            }),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
