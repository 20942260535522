import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/home";

export default class Home extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(`${this.ucfirst("window-title")}`);
    setTimeout(() => {
      this.scrollToTop();
    }, 50);
  }
}
