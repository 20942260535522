import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  InputBase,
  Stack,
} from "@mui/material";
import DesignerListView from "../../../../Components/Widgets/DesignerListView";
import DesignerListSkeletonView from "./designer_list_skeleton_view";
// import Translate from "../../../../../modules/Core/Components/Translate";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "30px",
  margin: "8px auto",
  display: "block",
  backgroundColor: "#fff",
  border: "1px solid #000",
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#000 !important",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#777",
  width: "100%",
  "& .MuiInputBase-input": {
    color: "#000",
    padding: "12px 8px 12px 0px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function Designers(props) {
  const { component } = props;

  const { designers = null } = component.getData("default", {});
  const term = component.getSearchTerm();
  const devHelper = component.getHelpers("dev");

  // const [showText, setShowText] = React.useState(false);

  useEffect(() => {
    const onScroll = () => {
      const max = 0.6;

      if (
        !component.getData("default.loading") &&
        component.canFetchMore() &&
        (window.scrollY + window.innerHeight) / document.body.clientHeight > max
      ) {
        component.fetchMore();
      }

      localStorage.setItem("scrollPosition", window.scrollY);
      localStorage.setItem("scrollList", window.location.pathname);
    };

    const onBack = () => {
      localStorage.removeItem("saveScrollPosition");
      component.getPage().redirect("/");
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("popstate", onBack);

    return () => {
      if (onScroll) {
        window.removeEventListener("scroll", onScroll);
      }

      if (onBack) {
        window.removeEventListener("popstate", onBack);
      }

      const scrollY = localStorage.getItem("scrollPosition");

      localStorage.setItem("saveScrollPosition", scrollY);
    };
  }, [component]);

  return (
    <Container disableGutters maxWidth="lg">
      <Box sx={{ mt: 2, mb: 4, px: 2 }}>
        <Typography
          component="h1"
          sx={{
            fontSize: { xs: 26, md: 32 },
            lineHeight: 1,
          }}
        >
          {term
            ? `${component.ucfirst("designers")} ${decodeURIComponent(term)}`
            : component.ucfirst("designers")}
        </Typography>
        {/* <Typography
          component="h3"
          sx={{
            color: "#000",
            fontSize: 16,
            mt: 2,
            maxHeight: showText ? "unset" : 45,
            overflowY: "hidden",
          }}
        >
          <Translate
            entity="item"
            eslug="designers"
            scope="default"
            slug="description"
            auto
          />
        </Typography> */}
        {/* <Link
          underline="hover"
          sx={{
            fontSize: 16,
            float: "right",
            color: "#000",
            mt: 0.5,
            cursor: "pointer",
          }}
          onClick={() => setShowText(!showText)}
        >
          {component.trans(showText ? "less-btn" : "more-btn")}
        </Link> */}
      </Box>
      {/* filters bar */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "space-between",
          px: 1,
        }}
      >
        <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
          <form onSubmit={(e) => component.checkSearch(e)} method="get">
            <Search sx={{}}>
              <SearchIconWrapper
                type="submit"
                disableRipple
                aria-label="Search"
              >
                <i className="fa-light fa-magnifying-glass" />
              </SearchIconWrapper>
              <StyledInputBase
                value={component.getData("default.search_term", "")}
                onChange={(e) => {
                  component.setData({
                    "default.search_term": e.target.value,
                  });
                }}
                placeholder={component.ucfirst("search-designer-holder")}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </form>
        </Box>
        <Button
          sx={{
            borderRadius: 0,
            fontSize: 18,
            textTransform: "none",
            p: 0,
            color: "#000",
            "&:hover": {
              background: "transparent",
            },
            "&:disabled": {
              color: "#000",
            },
          }}
          disabled
          disableRipple
        >
          {`${component.getData("default.total", 0)} ${component.ucfirst(
            "designers"
          )} `}
        </Button>
      </Stack>
      <Box
        sx={{
          position: "relative",
          overflowX: "hidden",
          my: 1,
          minHeight: "600px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            transition: "margin-left 0.3s",
            px: { xs: 0, md: 1 },
            // marginLeft: filterDrawer ? "350px" : 0,
            // "@media (max-width: 500px)": {
            //   marginLeft: filterDrawer ? "100%" : 0,
            // },
          }}
        >
          {!designers ? (
            <Grid
              container
              direction="row"
              sx={{
                alignItems: "stretch",
              }}
              columnSpacing={2}
              rowSpacing={1}
            >
              {[...new Array(40)].map((product, index) => (
                <DesignerListSkeletonView
                  key={`skeleton-${index}`}
                  {...{
                    ...props,
                    ...{
                      grid: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          ) : (
            <>
              {designers.length !== 0 ? (
                <Grid
                  container
                  direction="row"
                  sx={{
                    alignItems: "stretch",
                  }}
                  columnSpacing={2}
                  rowSpacing={1}
                >
                  {designers.map((designer) => (
                    <DesignerListView
                      key={`${devHelper.getObjectValue(designer, "_id")}`}
                      {...{
                        ...props,
                        ...{
                          designer,
                          grid: 4,
                        },
                      }}
                    />
                  ))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      mt: "50px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        mb: 6,
                      }}
                    >
                      {component.ucfirst("no-designers-found-message")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}
