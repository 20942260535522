import React, { useState, useEffect, useRef } from "react";
import { Box, Link, Grid, Typography, Container, Fade } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function GridSubCategories(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("new_files_url");
  const menu = component.getData("default.menu", null);
  const submenu = component.getData("default.submenu", null);

  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  // Function to update image width
  const updateImageWidth = () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }
  };

  // UseEffect to add event listener for resize
  useEffect(() => {
    updateImageWidth(); // Initial width on component mount
    window.addEventListener("resize", updateImageWidth); // Update on resize
    return () => window.removeEventListener("resize", updateImageWidth); // Cleanup
  }, [menu]);

  return (
    <Box>
      {menu && submenu && (
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            px: { xs: 1, md: 1 },
            mb: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              mb: 4,
              "& i": {
                mr: 2,
                fontSize: 20,
                color: devHelper
                  .getObjectValue(menu, "_values.color")
                  .replace("1)", "0.5)"),
              },
            }}
          >
            <i className="fa-solid fa-circle" />
            <Typography
              component="h1"
              sx={{
                display: "block",
                color: "#000",
                fontSize: 30,
                fontWeight: 500,
              }}
            >
              {component.ucfirst(submenu)}
            </Typography>
          </Box>
          <Grid
            container
            justifycontent="center"
            direction="row"
            rowSpacing={2}
            columnSpacing={2}
          >
            {devHelper
              .getObjectValue(menu, `_values.subMenu.${submenu}`)
              .map((submenu) => {
                const { slug = null, url = null } = submenu;
                return (
                  <Grid item xs={6} sm={4} md={3} key={slug}>
                    <Box
                      ref={imageRef}
                      sx={{
                        mt: "4px",
                        mb: { xs: "4px", md: "12px" },
                        borderRadius: "12px",
                        transition: "all .3s ease-in-out",
                        webkitTransition: "all .3s ease-in-out",
                        "&:hover": {
                          mt: 0,
                          mb: 2,
                          boxShadow:
                            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                        },
                      }}
                    >
                      <Fade
                        in={component.getData(`default.ready-${slug}`, false)}
                      >
                        <Link
                          component={RouterLink}
                          to={url}
                          sx={{
                            display: "flex",
                            "& img": {
                              width: imageWidth,
                              height: "auto",
                              borderRadius: "12px",
                            },
                          }}
                        >
                          <img
                            src={`${filesUrl}/marketing/subcategories/${slug}.jpg?tr=w-${imageWidth}`}
                            alt={`${component.trans(slug)}`}
                            title={`${component.trans(slug)}`}
                            width={imageWidth}
                            onLoad={() => {
                              component.setData({
                                [`default.ready-${slug}`]: true,
                              });
                            }}
                            onError={() => {
                              component.setData({
                                [`default.ready-${slug}`]: true,
                              });
                            }}
                          />
                        </Link>
                      </Fade>
                    </Box>
                    <Link
                      component={RouterLink}
                      to={url}
                      underline="none"
                      sx={{
                        color: "#000",
                        fontSize: { xs: 20, md: 24 },
                        fontWeight: 500,
                      }}
                    >
                      {component.ucfirst(slug)}
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      )}
    </Box>
  );
}
