import React, { useMemo, lazy, Suspense } from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import Footer from "../../../Components/Widgets/Footer";

// Lazy load components
const MenuDrawer = lazy(() => import("../../../Components/Widgets/MenuDrawer"));
const SubMenuDrawer = lazy(() =>
  import("../../../Components/Widgets/SubMenuDrawer")
);
const CartDrawer = lazy(() => import("../../../Components/Widgets/CartDrawer"));

const TopBannersSlider = lazy(() =>
  import("../../../Components/Widgets/Sliders/TopBannersSlider")
);
const MenuSlider = lazy(() =>
  import("../../../Components/Widgets/Sliders/MenuSlider")
);
const GridBanners = lazy(() =>
  import("../../../templates/default/widgets/sliders/grid_banners")
);
const DoubleSlider = lazy(() =>
  import("../../../Components/Widgets/Sliders/DoubleSlider")
);
const ResetPasswordDialog = lazy(() =>
  import("../../../Components/Widgets/Dialogs/ResetPasswordDialog")
);

const ExportDefault = (props) => {
  // Memoize the styles object
  const containerStyles = useMemo(
    () => ({
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      bgcolor: "#F7F8FA",
    }),
    []
  );

  // Memoize frequently reused objects
  const topBannerProps = useMemo(
    () => ({
      ...props,
      text_color: "#fff",
      top: "50%",
      left: "50%",
      center: "-50%,-50%",
      slides: [
        {
          slug: "slider-main-event",
          url: "/home/brand/cattelan-italia",
        },
        // { slug: "slider-main-sofas", url: "/products/type/sofa" },
        // { slug: "slider-main-tables", url: "/products/type/table" },
        // { slug: "slider-main-outdoor", url: "/catalogue/outdoor" },
      ],
    }),
    [props]
  );

  const menuSliderProps = useMemo(
    () => ({
      ...props,
      slides: [
        {
          slug: "slider-new",
          url: "/products/category/new",
        },
        {
          slug: "slider-furniture",
          url: "/catalogue/furniture",
        },
        {
          slug: "slider-lighting",
          url: "/catalogue/lighting",
        },
        {
          slug: "slider-outdoor",
          url: "/catalogue/outdoor",
        },
        {
          slug: "slider-wardrobe",
          url: "/products/type/wardrobe",
        },
        {
          slug: "slider-kitchen",
          url: "/products/type/kitchen",
        },
        {
          slug: "slider-brands",
          url: "/all-brands",
        },
        {
          slug: "slider-showrooms",
          url: "/stores",
        },
      ],
    }),
    [props]
  );

  const gridBannerProps = useMemo(
    () => ({
      ...props,
      text_color: "#fff",
      top: "0%",
      left: "0%",
      descriptionMaxWidthMobile: "450px",
      slides: [
        {
          slug: "slider-grid-banners-1",
          url: "/catalogue/furniture/dinning-area",
          width: 872,
          height: 522,
        },
        {
          slug: "slider-grid-banners-2",
          url: "/catalogue/furniture/living-area",
          width: 800,
          height: 676,
        },
        {
          slug: "slider-grid-banners-3",
          url: "/products/brand/cattelan-italia",
          width: 500,
          height: 725,
        },
        {
          slug: "slider-grid-banners-4",
          url: "/products/brand/porada",
          width: 500,
          height: 725,
        },
        {
          slug: "slider-grid-banners-5",
          url: "/products/type/bed",
          width: 744,
          height: 522,
        },
        {
          slug: "slider-grid-banners-6",
          url: "/catalogue/outdoor",
          width: 1284,
          height: 265,
        },
        {
          slug: "slider-grid-banners-7",
          url: "/products/type/wardrobe",
          width: 872,
          height: 522,
        },
        {
          slug: "slider-grid-banners-8",
          url: "/products/category/new",
          width: 800,
          height: 676,
        },
        {
          slug: "slider-grid-banners-9",
          url: "/products/brand/desiree",
          width: 500,
          height: 725,
        },
        {
          slug: "slider-grid-banners-10",
          url: "/products/brand/moroso",
          width: 500,
          height: 725,
        },
        {
          slug: "slider-grid-banners-11",
          url: "/catalogue/lighting",
          width: 744,
          height: 522,
        },
        {
          slug: "slider-grid-banners-12",
          url: "/hotels",
          width: 1284,
          height: 265,
        },
      ],
      grids: [
        { xs: 12, sm: 7 },
        { xs: 12, sm: 5 },
        { xs: 6, sm: 3 },
        { xs: 6, sm: 3 },
        { xs: 12, sm: 6 },
        { xs: 12, sm: 12 },
        { xs: 12, sm: 7 },
        { xs: 12, sm: 5 },
        { xs: 6, sm: 3 },
        { xs: 6, sm: 3 },
        { xs: 12, sm: 6 },
        { xs: 12, sm: 12 },
      ],
    }),
    [props]
  );

  const doubleSliderProps = useMemo(
    () => ({
      ...props,
      fscope: "slider-brands",
    }),
    [props]
  );

  return React.createElement(
    Box,
    { sx: containerStyles },
    React.createElement(NavBar, { ...props }),
    React.createElement(
      Suspense,
      { fallback: React.createElement("div", null, "") },
      React.createElement(MenuDrawer, { ...props }),
      React.createElement(SubMenuDrawer, { ...props }),
      React.createElement(CartDrawer, { ...props }),
      React.createElement(TopBannersSlider, { ...topBannerProps }),
      React.createElement(MenuSlider, { ...menuSliderProps }),
      React.createElement(GridBanners, { ...gridBannerProps }),
      React.createElement(DoubleSlider, { ...doubleSliderProps }),
      React.createElement(ResetPasswordDialog, { ...props }),
      React.createElement(Footer, { ...props })
    )
  );
};

export default ExportDefault;
