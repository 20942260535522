import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/cart/product_cart_view";
import Services from "../../Services/Services";
import axios from "axios";

export default class ProductCartView extends Component {
  id = "procut-cart-view";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProducts();
    this.checkWishlist();
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);
    if (prevProps.product.amount !== this.props.product.amount) {
      this.setProducts();
      this.checkWishlist();
    }
  }

  setProducts() {
    var product = { ...this.props.product };

    const {
      types = [],
      brands = [],
      collections = [],
      designers = [],
      categories = [],
      combinations = [],
      group,
      materials = [],
    } = product.item;

    var code = product.sku.split(":")[1];

    var comb = combinations.find((c) => c.code === code);

    const { size = null, final_price, price, options } = comb;

    const mats = materials.filter((mat) =>
      options.includes(mat.item._values.slug)
    );

    const brand = brands ? brands[0] : null;

    product.item = {
      ...product.item,
      code: code,
      size,
      combination: options,
      price: final_price,
      first_price: price,
      type: types ? types[0] : null,
      brand: brand,
      collection: collections ? collections[0] : null,
      designer: designers ? designers[0] : null,
      categorie: categories ? categories[0] : null,
      simple: product.sku === "default",
      comb_materials: mats,
    };

    const dev = this.getHelpers("dev");
    this.filterImages(group, dev.getObjectValue(brand, "item._values.slug"));

    this.setData({ "default.product": product, "default.": mats });
  }

  filterImages(group, b_slug) {
    axios
      .get(
        `https://files.automatapp.com/listing/casadipatsi/shop/products/${b_slug}/images/${group}`
      )
      .then((res) => {
        this.setData({
          "default.image": res.data.length ? res.data.sort()[0] : null,
        });
      });

    return this;
  }

  checkWishlist() {
    var product = { ...this.props.product };

    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      this.setData({
        "default.wishlist": wishlist.some(
          (wishlist_product) => wishlist_product === product.item.slug
        ),
      });
    });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }

  updateCart(product, skuPath, sku, amount) {
    var firstAmount = amount;

    Services.get("order").then(async ([orderService]) => {
      const { id } = product;
      sku = typeof sku === "string" ? sku : sku.join(",");

      if (amount !== 0) {
        amount = this.getProductAmount(product, skuPath, sku) + amount;
      }

      await orderService.updateItem({
        itemId: id,
        itemType: "product",
        sku: skuPath ? `${skuPath}:${sku}` : sku,
        amount,
      });

      const order = orderService.getOrder();

      this.getApp().sendToGA4(product, "add_to_cart", amount);

      this.getComponents()
        .findByGroup("order")
        .forEach((c) => {
          c.setData({ "default.order": order });
        });

      if (firstAmount > 0) {
        if (this.getProductAmount(product, skuPath, sku) !== amount) {
          this.setData({ "default.in_stock": false });
        }
      } else {
        this.setData({ "default.in_stock": true });
      }
    });
  }

  getProductAmount(product, skuPath, sku) {
    const { items = [] } =
      this.getComponents().findById("cart-drawer").first().setOrder() || {};

    const { amount = 0 } =
      items.find((orderItem) => {
        return (
          product.id === orderItem.item.id &&
          (!sku || orderItem.sku === `${skuPath}:${sku}`)
        );
      }) || {};

    return amount;
  }
}
