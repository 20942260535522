import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product_list_view";
import Services from "../../Services/Services";

export default class ProductListView extends Component {
  id = "procut-list-view";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();
  }

  setProduct() {
    var product = { ...this.props.product };

    var { combinations = [] } = product._values;

    combinations.sort(function (a, b) {
      return a.final_price - b.final_price;
    });

    if (combinations.length) {
      this.setProductValues(product, combinations[0]);
      this.checkWishlist();
    }
  }

  setProductValues(product, values) {
    const { code, sku, final_price, price } = values;
    const {
      types = null,
      brands = null,
      collections = null,
      designers = null,
      categories = null,
    } = product._values;

    const brand = brands ? brands[0] : null;

    product._values = {
      ...product._values,
      code,
      sku,
      price: final_price,
      first_price: price,
      type: types ? types[0] : null,
      brand: brand,
      collection: collections ? collections[0] : null,
      designer: designers ? designers[0] : null,
      categorie: categories ? categories[0] : null,
    };

    this.setData({
      "default.product": product,
    });

    return this;
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      var product = this.getData("default.product");
      this.setData({
        "default.wishlist": wishlist.some((el) => el === product._values.slug),
      });
    });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }
}
