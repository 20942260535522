import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
} from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

const MyButton = styled(Button)(({ theme }) => ({
  margin: "16px auto",
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  maxWidth: "400px",
  "&:hover": {
    background: "#000",
  },
  "&:disabled": {
    color: "#fff",
    opacity: 0.8,
  },
}));

const RadioBtn = styled(Radio)(({ theme }) => ({
  color: "#000",
  "&.Mui-checked": {
    color: "#000",
    "&:hover": {
      background: "transparent",
    },
  },
  "&:hover": {
    background: "transparent",
  },
}));

export default function PaymentSection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { paymentTypes = [] } = component.getData("default", {});

  return (
    <Box component="form" onSubmit={(e) => component.complete(e)}>
      <AccordionDetails>
        <RadioGroup
          name="payment_method"
          value={component.findPaymentTypeSelected()}
        >
          {paymentTypes.map((paymentType) => {
            return (
              <FormControlLabel
                key={devHelper.getObjectValue(paymentType, "slug")}
                value={devHelper.getObjectValue(paymentType, "id")}
                control={<RadioBtn disableRipple />}
                onClick={() =>
                  component.updatePaymentType(
                    devHelper.getObjectValue(paymentType, "id")
                  )
                }
                label={component.ucfirst(
                  devHelper.getObjectValue(paymentType, "slug")
                )}
              />
            );
          })}
        </RadioGroup>
        {component.findPaymentTypeSelectedSlug() === "bank-deposit" && (
          <Box
            sx={{ mt: 2 }}
            dangerouslySetInnerHTML={{
              __html: component.ucfirst("bank-deposit-checkout"),
            }}
          ></Box>
        )}
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Typography component="h3" align="center">
            <Translate
              entity="literal"
              eslug="agree-privacy-policy-terms-checkbox"
              scope="default"
              slug="default"
              auto
            />
          </Typography>
          <MyButton type="submit" fullWidth>
            {component.ucfirst("place-order-btn")}
          </MyButton>
        </Box>
      </AccordionDetails>
    </Box>
  );
}
