import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  TextField,
  AccordionDetails,
  Typography,
  Link,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ForgotPasswordDialog from "../../../../Components/Widgets/Dialogs/ForgotPasswordDialog";

const MyButton2 = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#fff",
  color: "#000",
  border: "1px solid #000",
  "&:hover": {
    background: "#fff",
  },
}));

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

export default function UserSection(props) {
  const { component } = props;

  const { guest } = component.getData("default", {});

  //change user type
  const changeUserType = () => {
    component.setData({
      "default.guest": !guest,
      "error.fields.auth": "",
      "error.message.auth": "",
    });
  };

  //show/hide password
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //forgot password dialog
  const [open, setOpen] = React.useState(false);

  return (
    <Box component="form" onSubmit={(e) => component.login(e)}>
      <AccordionDetails>
        <Grid
          container
          maxWidth="400px"
          direction="row"
          rowSpacing={1}
          columnSpacing={0}
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12}>
            <MyTextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label={component.ucfirst("email-textfield")}
              name="email"
              autoComplete="email"
              helperText={component.getData("error.fields.auth.username")}
              value={component.getData("default.fields.auth.username", "")}
              onChange={(e) => {
                component.setData({
                  "default.fields.auth.username": e.target.value,
                });
                component.deleteData("error.fields.auth.username");
              }}
              error={component.dataExists("error.fields.auth.username")}
            />
          </Grid>
          {!guest && (
            <Grid item xs={12}>
              <PasswordField
                variant="outlined"
                error={component.dataExists("error.fields.auth.password")}
              >
                <InputLabel>
                  {component.ucfirst("password-textfield")}
                </InputLabel>
                <OutlinedInput
                  type={values.showPassword ? "text" : "password"}
                  value={component.getData("default.fields.auth.password", "")}
                  onChange={(e) => {
                    component.setData({
                      "default.fields.auth.password": e.target.value,
                    });
                    component.deleteData("error.fields.auth.password");
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        disableRipple
                        sx={{
                          "&:hover, &:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={component.ucfirst("password-textfield")}
                />
                {component.dataExists("error.fields.auth.password") && (
                  <FormHelperText error style={{ margin: "3px 14px 0" }}>
                    {component.getData("error.fields.auth.password")}
                  </FormHelperText>
                )}
              </PasswordField>
            </Grid>
          )}
          <Grid item xs={12}>
            <MyButton type="submit" fullWidth>
              {guest
                ? component.ucfirst("guest-btn")
                : component.ucfirst("sign-in-btn")}
            </MyButton>
            {!guest && (
              <Link
                variant="body2"
                component="button"
                underline="hover"
                type="button"
                onClick={() => setOpen(true)}
                style={{
                  color: "#7d7d7d",
                }}
              >
                {component.ucfirst("forgot-password")}
              </Link>
            )}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography>{component.trans("or")}</Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <MyButton2 onClick={() => changeUserType()} fullWidth>
              {!guest
                ? component.ucfirst("guest-btn")
                : component.ucfirst("sign-in-btn")}
            </MyButton2>
          </Grid>
        </Grid>
      </AccordionDetails>
      <ForgotPasswordDialog
        {...{
          ...props,
          ...{
            open,
            setOpen: (boolean) => setOpen(boolean),
          },
        }}
      />
    </Box>
  );
}
