import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../../Components/Widgets/SubMenuDrawer";
import CartDrawer from "../../../../Components/Widgets/CartDrawer";
import Orders from "../../../../Components/Widgets/User/Orders";
import Footer from "../../../../Components/Widgets/Footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar {...props} />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <CartDrawer {...props} />
      <Orders {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
