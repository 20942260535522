import React from "react";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Link,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClickAwayListener from "@mui/base/ClickAwayListener";

export default function Filters(props) {
  const { component, filterDrawer } = props;
  const devHelper = component.getHelpers("dev");
  const filters = component.getData("default.filters", []);
  const { type } = component.getPage().getParams();

  const sortBy = component
    .getData("default.activeFilters.sortBy", [])
    .join("-");

  return (
    <>
      {filterDrawer && (
        <ClickAwayListener onClickAway={() => props.setFilterDrawer(false)}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              height: "100%",
              left: 0,
              overflowY: "auto",
              transition: "visibility 0.3s",
              visibility: filterDrawer ? "visible" : "hidden",
              px: filterDrawer ? 1 : 0,
              width: filterDrawer ? "350px" : 0,
              "@media (max-width: 500px)": {
                width: filterDrawer ? "100%" : 0,
                margin: 0,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                alignItems: "center",
              }}
            >
              <Link
                underline="hover"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  component.clearFiltersAndFetch();
                }}
                id="clear_filters"
              >
                {component.ucfirst("clear-filters-btn")}
              </Link>
              <IconButton
                sx={{
                  color: "#000",
                  px: 0,
                }}
                disableRipple
                onClick={() => {
                  props.setFilterDrawer(false);
                }}
              >
                <i className="fa-light fa-circle-xmark" />
              </IconButton>
            </Box>
            <Box
              sx={{
                mb: 2,
              }}
            >
              <FormControl fullWidth>
                <TextField
                  select
                  value={sortBy.includes("price") ? sortBy : ""}
                  label={component.ucfirst("sort-by")}
                  onChange={(e) => component.sortBy(e.target.value)}
                  SelectProps={{
                    MenuProps: { disablePortal: true },
                  }}
                >
                  <MenuItem value={"price-asc"}>
                    {component.ucfirst("price-asc")}
                  </MenuItem>
                  <MenuItem value={"price-desc"}>
                    {component.ucfirst("price-desc")}
                  </MenuItem>
                </TextField>
              </FormControl>
            </Box>
            {filters
              .filter(
                (f) =>
                  f.type !== "category" && f.type !== "price" && f.type !== type
              )
              .map((filter) => {
                const { type } = filter;
                const name = type === "price_range" ? "price" : type;

                return (
                  <Accordion
                    key={`filter-${type}`}
                    sx={{
                      backgroundColor: "transparent",
                      "&::before": { opacity: "1 !important" },
                      "& .MuiButtonBase-root": {
                        px: 1,
                      },
                    }}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {component.ucfirst(name)}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        px: { xs: 2, md: 3 },
                        py: 0,
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormGroup>
                          {component
                            .getData(`default.${type}s`, [])
                            .map((item, i) => {
                              const filterAmount = component.getFilterAmount(
                                type,
                                item
                              );
                              return (
                                filterAmount > 0 && (
                                  <FormControlLabel
                                    key={devHelper.getObjectValue(
                                      item,
                                      "_values.slug"
                                    )}
                                    disabled={
                                      devHelper.getObjectValue(
                                        item,
                                        "_values.total"
                                      ) === 0
                                    }
                                    id={
                                      devHelper.getObjectValue(
                                        item,
                                        "_values.slug"
                                      ) +
                                      "-" +
                                      i
                                    }
                                    control={
                                      <Checkbox
                                        size="small"
                                        onChange={(event) =>
                                          component.toggleFilter(type, item)
                                        }
                                        className="checkbox"
                                        checked={component.isFilterSelected(
                                          type,
                                          item
                                        )}
                                      />
                                    }
                                    sx={{
                                      "& .MuiTypography-root": {
                                        display: "contents!important",
                                      },
                                    }}
                                    label={
                                      <Box>
                                        {type === "price_range"
                                          ? `${devHelper.getObjectValue(
                                              item,
                                              type === "type"
                                                ? "_values.slug"
                                                : "_values.name"
                                            )} (${filterAmount})`
                                          : `${component.ucfirst(
                                              devHelper.getObjectValue(
                                                item,
                                                type === "type"
                                                  ? "_values.slug"
                                                  : "_values.name"
                                              )
                                            )} (${filterAmount})`}
                                      </Box>
                                    }
                                  />
                                )
                              );
                            })}
                        </FormGroup>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            <Button
              variant="contained"
              sx={{
                display: "block",
                mx: "auto",
                textTransform: "none",
                mt: 2,
              }}
              onClick={() => {
                props.setFilterDrawer(false);
              }}
            >
              {`${component.getData("default.total", 0)} ${component.ucfirst(
                "products"
              )} `}
            </Button>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
}
