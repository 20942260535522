import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Order extends Service {
  //
  fetchOrder(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .retrieve(account, repository, {
          params: {
            orderId,
          },
        })
        .then((r) => {
          if (this.evaluate(r, "order")) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  fetchPaymentTypes(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.paymentTypes(account, repository).then((r) => {
        const result = this.evaluate(r, "paymentTypes");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  updatePaymentType(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      paymentTypeId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "paymentType", {
          orderId,
          paymentTypeId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateAddress(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      billingAddress,
      shippingAddress,
      billingAddressId,
      shippingAddressId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "address", {
          orderId,
          billingAddress,
          shippingAddress,
          billingAddressId,
          shippingAddressId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateItem(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      itemId,
      itemType,
      sku = "",
      values = {},
      amount = 1,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "item", {
          orderId,
          itemId,
          itemType,
          sku,
          values,
          amount,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateService(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      serviceId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "service", {
          orderId,
          serviceId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateCoupon(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      couponCode,
      couponId,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "coupon", {
          orderId,
          couponCode,
          couponId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  releaseCoupon(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "update", "coupon", { orderId })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  addGuest(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      guest,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      MainApi.order
        .action(account, repository, "add", "guest", { orderId, guest })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  prepare(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      // this.setError();

      MainApi.order
        .action(account, repository, "prepare", "order", {
          orderId,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });

      // if (orderId) {

      // } else {
      //   this.setError("order-missing").delete();
      //   reject(this);
      // }
    });
  }

  place(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      transaction = null,
    } = options || {};

    return new Promise((resolve, reject) => {
      const orderId = this.getOrderId();

      // this.setError();

      MainApi.order
        .action(account, repository, "place", "order", {
          orderId,
          transaction,
        })
        .then((r) => {
          const result = this.evaluate(r, "order");

          if (result) {
            this.delete();
            resolve(this);
          } else {
            reject(this);
          }
        });

      // if (orderId) {

      // } else {
      //   this.setError("order-missing").delete();
      //   reject(this);
      // }
    });
  }

  evaluate(r, name) {
    const result = super.evaluate(r, name);
    const order = this.getData("order");
    const message = this.getData("_response").getMessage();
    const badMessages = ["order-missing", "order-expired", "order-placed"];

    if (result && order) {
      this.save(order);
    } else {
      if (badMessages.indexOf(message) >= 0) {
        this.setError(message);
        this.delete();
      }
    }

    return result;
  }

  save(order) {
    if (order) {
      Helpers.State.set("order_id", order._id, { path: "/" });
      this.order = order;
    } else {
      this.delete();
    }
    return this;
  }

  delete() {
    Helpers.State.remove("order_id", { path: "/" });
    this.setData("order", null);
  }

  getOrder() {
    return this.getData("order");
  }

  getOrderId() {
    return Helpers.State.get("order_id", null, { path: "/" });
  }

  getOrders(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.user.order
        .collection(account, repository, {
          params: {
            orderBy: "created_at",
            orderType: "desc",
          },
        })
        .then((r) => {
          const result = this.evaluate(r, "user-orders");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }
}

export default new Order();
