import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/recently_viewed";
import Services from "../../Services/Services";

export default class RecentlyViewed extends Component {
  id = "recently-viewed";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getRecentlyViewed();
  }

  getRecentlyViewed() {
    var viewed = this.getHelpers("state").get("viewed", null);

    if (viewed) {
      Services.get("hook,recentlyViewed").then(([service, recentlyViewed]) => {
        var promises = viewed.split(",").map((slug) => {
          return service.exec("product", {
            params: { slug },
          });
        });

        Promise.all(promises).then((products) => {
          products = products
            .map((response) => response.getData())
            .filter((product) => product);

          recentlyViewed.updateRecentlyViewed(
            products.map(({ _values }) => _values.slug),
            this
          );

          this.setData({
            "default.products": products.reverse(),
          });
        });
      });
    } else {
      this.setData({
        "default.products": [],
      });
    }
  }
}
