import React, { useState, useEffect, useRef } from "react";
import { Box, Link, Grid, Typography, Container, Fade } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function GridCategories(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("new_files_url");
  const menu = component.getData("default.menu", null);

  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  // Function to update image width
  const updateImageWidth = () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }
  };

  // UseEffect to add event listener for resize
  useEffect(() => {
    updateImageWidth(); // Initial width on component mount
    window.addEventListener("resize", updateImageWidth); // Update on resize
    return () => window.removeEventListener("resize", updateImageWidth); // Cleanup
  }, [menu]);

  return (
    <Box>
      {menu && (
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            px: { xs: 1, md: 1 },
            mb: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              mb: 4,
              "& i": {
                mr: 2,
                fontSize: 20,
                color: devHelper.getObjectValue(menu, "_values.color"),
              },
            }}
          >
            <i className="fa-solid fa-circle" />
            <Typography
              component="h1"
              sx={{
                display: "block",
                color: "#000",
                fontSize: 30,
                fontWeight: 500,
              }}
            >
              {component.ucfirst(
                devHelper.getObjectValue(menu, "_values.slug")
              )}
            </Typography>
          </Box>
          <Grid
            container
            justifycontent="center"
            direction="row"
            rowSpacing={2}
            columnSpacing={2}
          >
            {menu._values &&
              menu._values.menu_types.map((submenu) => {
                const { slug = null, link = null, has_menu } = submenu;
                return (
                  <Grid item xs={6} sm={4} md={3} key={slug}>
                    <Box
                      ref={imageRef}
                      sx={{
                        mt: "4px",
                        mb: { xs: "4px", md: "12px" },
                        borderRadius: "12px",
                        transition: "all .3s ease-in-out",
                        webkitTransition: "all .3s ease-in-out",
                        "&:hover": {
                          mt: 0,
                          mb: 2,
                          boxShadow:
                            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                        },
                      }}
                    >
                      <Fade
                        in={component.getData(`default.ready-${slug}`, false)}
                      >
                        <Link
                          component={RouterLink}
                          to={link}
                          sx={{
                            display: "flex",
                            "& img": {
                              width: imageWidth,
                              height: "auto",
                              borderRadius: "12px",
                            },
                          }}
                        >
                          <img
                            src={`${filesUrl}/marketing/categories/${slug}.jpg?tr=w-${imageWidth}`}
                            alt={`${component.trans(slug)}`}
                            title={`${component.trans(slug)}`}
                            width={imageWidth}
                            onLoad={() => {
                              component.setData({
                                [`default.ready-${slug}`]: true,
                              });
                            }}
                            onError={() => {
                              component.setData({
                                [`default.ready-${slug}`]: true,
                              });
                            }}
                          />
                        </Link>
                      </Fade>
                    </Box>
                    <Link
                      component={RouterLink}
                      to={link}
                      underline="none"
                      sx={{
                        color: "#000",
                        fontSize: { xs: 20, md: 24 },
                        fontWeight: 500,
                      }}
                    >
                      {component.ucfirst(slug)}
                    </Link>
                    {has_menu && (
                      <ul
                        style={{
                          margin: "0px",
                          padding: "0px",
                          listStyleType: "none",
                        }}
                      >
                        {menu._values.subMenu[slug] &&
                          menu._values.subMenu[slug].slice(0, 4).map((type) => {
                            const { slug, url } = type;
                            return (
                              <Link
                                key={`inner-link-${slug}`}
                                underline="hover"
                                component={RouterLink}
                                to={url}
                                sx={{
                                  display: "flex",
                                  fontSize: 16,
                                  mb: "4px",
                                  color: "#393d41",
                                }}
                              >
                                {component.ucfirst(slug)}
                              </Link>
                            );
                          })}
                        <Link
                          underline="hover"
                          component={RouterLink}
                          to={link}
                          sx={{
                            display: "flex",
                            fontSize: 16,
                            mb: "4px",
                            color: "#393d41",
                            fontWeight: 500,
                          }}
                        >
                          {component.ucfirst("view-all")}...
                        </Link>
                      </ul>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      )}
    </Box>
  );
}
