import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddressForm from "./address_form";

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  const invalid = component.getData("invalid");
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("md"));

  return (
    <Dialog
      onClose={() => {
        component.props.setOpen(false);
        component.resetAddress();
      }}
      open={open}
      fullScreen={fullScreen}
      sx={
        {
          // "& .MuiPaper-rounded": {
          //   borderRadius: { xs: 0, md: "10px" },
          // },
        }
      }
    >
      <MyDialogTitle
        onClose={() => {
          component.props.setOpen(false);
          component.resetAddress();
        }}
      >
        {component.ucfirst("add-new-address-dialog-title")}
      </MyDialogTitle>
      <DialogContent dividers>
        <form onSubmit={(e) => component.addAddress(e)} autoComplete="on">
          <AddressForm
            {...{
              ...props,
              ...{
                type: "address",
              },
            }}
          />
          <button type="submit" hidden></button>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 1 }}>
        <MyButton
          fullWidth
          disabled={invalid}
          onClick={(e) => {
            component.addAddress(e);
          }}
        >
          {component.ucfirst("submit-btn")}
        </MyButton>
      </DialogActions>
    </Dialog>
  );
}
