import React from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Tab,
  CardMedia,
} from "@mui/material";
import { TabPanel, TabList, TabContext } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import Login from "../../../../Components/Widgets/Login";
import Register from "../../../../Components/Widgets/Register";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    "&.Mui-selected": {
      color: "#000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        pb: 0,
        backgroundColor: "#fff",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function LoginRegisterDialog(props) {
  const { component } = props;
  const open = component.getData("default.open", false);

  const [value, setValue] = React.useState("1");
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      onClose={() => {
        component.setData({ "default.open": false });
      }}
      fullScreen={fullScreen}
      open={open}
      sx={
        {
          // "& .MuiPaper-rounded": {
          //   borderRadius: { xs: "0px", sm: "0px" },
          // },
        }
      }
    >
      <MyDialogTitle
        onClose={() => {
          component.setData({ "default.open": false });
        }}
      >
        <CardMedia
          component="img"
          image="/images/logos/logo.png"
          alt="Casa Di Patsi Logo"
          title="Casa Di Patsi Home"
          sx={{
            width: "140px",
          }}
        />
      </MyDialogTitle>
      <DialogContent sx={{ px: { xs: 1, sm: 2 } }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="fullWidth"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#000 !important",
                },
              }}
            >
              <StyledTab label={component.ucfirst("sign-in")} value="1" />
              <StyledTab label={component.ucfirst("register")} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <Login
              {...{
                ...props,
                ...{
                  dialog: true,
                },
              }}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <Register
              {...{
                ...props,
                ...{
                  dialog: true,
                },
              }}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
}
