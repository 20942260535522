import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  AccordionDetails,
  Typography,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import AddressDialog from "../../../../Components/Widgets/Dialogs/AddressDialog";
import AddressForm from "../dialogs/address_form";

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  maxWidth: "400px",
  "&:hover": {
    background: "#000",
  },
}));

const MyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "25px",
  flexGrow: 1,
}));

const RadioBtn = styled(Radio)(({ theme }) => ({
  color: "#000",
  "&.Mui-checked": {
    color: "#000",
    "&:hover": {
      background: "transparent",
    },
  },
  "&:hover": {
    background: "transparent",
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  padding: "12px 14px",
  background: "#fff",
  color: "#000",
  textTransform: "none",
  marginLeft: "30px",
  marginBottom: "20px",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const ActionBtn = styled(Link)(({ theme }) => ({
  color: "#000",
  fontSize: "13px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

const RadioLabel = styled("div")(({ theme }) => ({
  border: "1px solid #000",
  borderRadius: "4px",
  fontSize: 18,
  padding: 10,
}));

const MyFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 10,
  "& .MuiFormControlLabel-label": {
    width: "100%",
    flexGrow: 1,
  },
}));

export default function AddressesSection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { guest, addresses = null } = component.getData("default", {});

  const [checked, setChecked] = React.useState(true);

  const [newAddressDialogOpen, setNewAddressDialogOpen] = React.useState(false);

  const invalidShippingAddress = component.getData(
    "invalid_shipping_address",
    false
  );
  const invalidBillingAddress = component.getData(
    "invalid_billing_address",
    false
  );

  const onAddressSubmit = component.getData("onSubmit", true);

  //same billing adrress or not
  const handleCheck = () => {
    setChecked((prev) => !prev);
    if (checked) {
      setTimeout(() => {
        var element = document.getElementById("billing_address_title");
        window.scrollTo({
          top: element.getBoundingClientRect().top + window.pageYOffset - 10,
          behavior: "smooth",
        });
      }, 100);
      component.initGoogleAutocomplete("billing_address");
    }
  };

  return (
    <Box component="form" onSubmit={(e) => component.addAddress(e)}>
      <AccordionDetails sx={{ display: "block" }}>
        {!guest && addresses && addresses.length !== 0 ? (
          <>
            <RadioGroup
              name="shipping_address"
              id="shipping_address"
              value={component.findAddressSelected("shipping")}
              onChange={(event) => {
                component.selectAddress(event.target.value, "shipping");
              }}
            >
              {addresses.map((address) => {
                return (
                  <MyFormControlLabel
                    key={devHelper.getObjectValue(address, "id")}
                    value={devHelper.getObjectValue(address, "id")}
                    control={<RadioBtn disableRipple />}
                    label={
                      <RadioLabel>
                        {component.ucfirst(
                          devHelper.getObjectValue(address, "first_name")
                        )}{" "}
                        {component.ucfirst(
                          devHelper.getObjectValue(address, "last_name")
                        )}
                        <br />
                        {component.ucfirst(
                          devHelper.getObjectValue(address, "address")
                        )}
                        <br />
                        {component.ucfirst(
                          devHelper.getObjectValue(address, "phone")
                        )}
                        <br />
                        <ActionBtn
                          underline="none"
                          onClick={() => {
                            component.setAddressId(
                              devHelper.getObjectValue(address, "id")
                            );
                            setNewAddressDialogOpen(true);
                            component.activateAutocomplete();
                          }}
                        >
                          {component.ucfirst("edit-btn")}
                        </ActionBtn>
                      </RadioLabel>
                    }
                  />
                );
              })}
            </RadioGroup>
            <AddButton
              variant="outlined"
              onClick={() => {
                setNewAddressDialogOpen(true);
                component.activateAutocomplete();
              }}
              startIcon={
                <i className="fak fa-light-location-dot-circle-plus" />
              }
            >
              {component.trans("add-new-address-btn")}
            </AddButton>
          </>
        ) : (
          <AddressForm
            {...{
              ...props,
              ...{
                type: "shipping_address",
              },
            }}
          />
        )}
        <Box sx={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheck}
                name="checkbox"
                sx={{
                  marginTop: "-2px",
                  color: "#000",
                  "&.Mui-checked": {
                    color: "#000",
                  },
                }}
              />
            }
            label={
              <Box style={{ fontSize: "18px" }}>
                {component.ucfirst("billing-address-checkbox")}
              </Box>
            }
          />
        </Box>
        {!checked && (
          <>
            <MyTitle
              style={{ margin: "10px 0 20px" }}
              id="billing_address_title"
            >
              {component.ucfirst("billing-address")}
            </MyTitle>
            {!guest && addresses && addresses.length !== 0 ? (
              <>
                <RadioGroup
                  name="billing_address"
                  id="billing_address"
                  value={component.findAddressSelected("billing")}
                  onChange={(event) => {
                    component.selectAddress(event.target.value, "billing");
                  }}
                >
                  {addresses.map((address) => {
                    return (
                      <MyFormControlLabel
                        key={devHelper.getObjectValue(address, "id")}
                        value={devHelper.getObjectValue(address, "id")}
                        control={<RadioBtn disableRipple />}
                        label={
                          <RadioLabel>
                            {component.ucfirst(
                              devHelper.getObjectValue(address, "first_name")
                            )}{" "}
                            {component.ucfirst(
                              devHelper.getObjectValue(address, "last_name")
                            )}
                            <br />
                            {component.ucfirst(
                              devHelper.getObjectValue(address, "address")
                            )}
                            <br />
                            {component.ucfirst(
                              devHelper.getObjectValue(address, "phone")
                            )}
                            <br />
                            <ActionBtn
                              underline="none"
                              onClick={() => {
                                component.setAddressId(
                                  devHelper.getObjectValue(address, "id")
                                );
                                setNewAddressDialogOpen(true);
                                component.activateAutocomplete();
                              }}
                            >
                              {component.ucfirst("edit-btn")}
                            </ActionBtn>
                          </RadioLabel>
                        }
                      />
                    );
                  })}
                </RadioGroup>
                <AddButton
                  variant="outlined"
                  onClick={() => {
                    setNewAddressDialogOpen(true);
                    component.activateAutocomplete();
                  }}
                  startIcon={
                    <i className="fak fa-light-location-dot-circle-plus" />
                  }
                >
                  {component.trans("add-new-address-btn")}
                </AddButton>
              </>
            ) : (
              <AddressForm
                {...{
                  ...props,
                  ...{
                    type: "billing_address",
                  },
                }}
              />
            )}
          </>
        )}
        <Box sx={{ textAlign: "center" }}>
          <MyButton
            type={onAddressSubmit ? "submit" : "button"}
            fullWidth
            disabled={invalidShippingAddress || invalidBillingAddress}
            onClick={(e) => {
              if (!onAddressSubmit) {
                component.addAddress(e);
              }
            }}
          >
            {component.ucfirst("address-submit-btn")}
          </MyButton>
        </Box>
      </AccordionDetails>
      <AddressDialog
        {...{
          ...props,
          ...{
            open: newAddressDialogOpen,
            setOpen: (boolean) => setNewAddressDialogOpen(boolean),
            retrieveAddresses: () => component.retrieveAddresses(),
          },
        }}
      />
    </Box>
  );
}
