import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Container,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

export default function Profile(props) {
  const { component } = props;

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        align="center"
        component="h1"
        sx={{ lineHeight: 1, fontSize: "34px", color: "#000", mt: 3 }}
      >
        {component.ucfirst("profile-btn")}
      </Typography>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <form
          style={{ width: "100%", marginTop: "8px" }}
          onSubmit={(e) => component.updateProfile(e)}
        >
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("fullname-textfield")}
            name="name"
            helperText={component.getData("error.fields.name")}
            error={component.dataExists("error.fields.name")}
            value={component.getData("default.fields.name", "")}
            onChange={(e) => {
              component.setData({ "default.fields.name": e.target.value });
              component.deleteData("error.fields.name");
            }}
          />
          <MyTextField
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("email-textfield")}
            name="email"
            value={component.getData("default.fields.username", "")}
          />

          <PasswordField
            variant="outlined"
            error={component.dataExists("error.fields.password")}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {component.ucfirst("password-textfield")}
            </InputLabel>
            <OutlinedInput
              type={values.showPassword ? "text" : "password"}
              value={component.getData("default.fields.password", "")}
              onChange={(e) => {
                component.setData({
                  "default.fields.password": e.target.value,
                });
                component.deleteData("error.fields.password");
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disableRipple
                    sx={{
                      "&:hover, &:focus": { backgroundColor: "transparent" },
                    }}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label={component.ucfirst("password-textfield")}
            />
            {component.dataExists("error.fields.password") && (
              <FormHelperText error style={{ margin: "3px 14px 0" }}>
                {component.getData("error.fields.password")}
              </FormHelperText>
            )}
          </PasswordField>

          <PasswordField
            variant="outlined"
            error={component.dataExists("error.fields.verify")}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {component.ucfirst("confirm-password-textfield")}
            </InputLabel>
            <OutlinedInput
              type={values.showPassword ? "text" : "password"}
              value={component.getData("default.fields.verify", "")}
              onChange={(e) => {
                component.setData({
                  "default.fields.verify": e.target.value,
                });
                component.deleteData("error.fields.verify");
              }}
              label={component.ucfirst("confirm-password-textfield")}
            />
            {component.dataExists("error.fields.verify") && (
              <FormHelperText error style={{ margin: "3px 14px 0" }}>
                {component.getData("error.fields.verify")}
              </FormHelperText>
            )}
          </PasswordField>

          <MyButton type="submit" fullWidth>
            {component.ucfirst("submit-btn")}
          </MyButton>
        </form>
      </Container>
    </Container>
  );
}
