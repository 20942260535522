import * as React from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  Container,
  Button,
  // CardMedia,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import Translate from "../../../../modules/Core/Components/Translate";
import ContactUs from "../../../Components/Widgets/Dialogs/ContactUs";

function Copyright() {
  return (
    <Typography variant="body2" align="center" sx={{ color: "#fff", my: 2 }}>
      {"Copyright © Casa Di Patsi "}
      {new Date().getFullYear()}
    </Typography>
  );
}

function DevelopedBy() {
  return (
    <Box sx={{ bgcolor: "#e8e8e8", py: 2 }}>
      <Typography variant="body2" align="center" sx={{ color: "#818181" }}>
        {"Developed by "}
        <Link
          color="inherit"
          href="https://www.automatapp.com/"
          underline="none"
          sx={{ fontWeight: 600 }}
        >
          Automatapp
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

const FooterTitle = styled("h2")(({ theme }) => ({
  fontSize: "18px",
  color: "#fff",
  fontWeight: "500",
  marginBottom: "15px",
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "#d1d1d1",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    color: "#fff",
  },
}));

const SocialLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#fff",
  fontSize: 24,
  "&:hover": {
    opacity: 0.9,
  },
}));

export default function StickyFooter(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  // const filesUrl = component.getHelpers("env").get("files_url");
  const links = component.getData("default.links", []);

  const [contactUsOpen, setContactUsOpen] = React.useState(false);

  const customLink = {
    textDecoration: "none",
    color: "#d1d1d1",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    "& i": {
      minWidth: 35,
      fontSize: "22px",
    },
    "& div": {
      textAlign: "center",
      display: "inline-flex",
    },
    "&:hover": {
      color: "#fff",
    },
  };

  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
        bgcolor: "#212b35",
      }}
    >
      <Box
        sx={{
          bgcolor: "#f5efe8",
        }}
      >
        <Container maxWidth="lg" sx={{ px: 2, position: "relative" }}>
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              component
                .getApp()
                .newsletterSubscribe(
                  component.getData("default.subscribe.email")
                );

              component.setData({ "default.subscribe.email": "" });
            }}
            sx={{
              maxWidth: { xs: "unset", md: 700, lg: 750 },
              textAlign: { xs: "center", md: "left" },
              py: 3,
            }}
          >
            <Typography
              component="h2"
              sx={{
                mb: 1,
                fontSize: { xs: 26, sm: 32 },
              }}
            >
              {component.trans("newsletter-title")}
            </Typography>
            <Typography
              component="h3"
              sx={{
                fontSize: { xs: 14, sm: 16 },
                mb: 4,
              }}
            >
              {component.trans("newsletter-description")}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              type="text"
              placeholder="Email"
              onChange={(e) =>
                component.setData({
                  "default.subscribe.email": e.target.value,
                })
              }
              value={component.getData("default.subscribe.email", "")}
              sx={{
                border: "none",
                maxWidth: 350,
                borderRadius: 30,
                mr: 2,
                fontSize: 16,
                bgcolor: "#fff",
                verticalAlign: "middle",
                "& label.Mui-focused": {
                  color: "#000",
                  borderRadius: 30,
                },
                "& .MuiInput-underline:after": {
                  borderRadius: 30,
                },
                "& .MuiOutlinedInput-input": {
                  px: 3,
                  py: 2,
                  fontSize: 16,
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: 30,
                  },
                  "&:hover fieldset": {
                    borderRadius: 30,
                  },
                  "&.Mui-focused fieldset": {
                    borderRadius: 30,
                  },
                },
              }}
            />
            <Button
              sx={{
                fontSize: 16,
                borderRadius: 30,
                backgroundColor: "#313131",
                p: 2,
                height: "55px",
                fontWeight: 400,
                color: "#fff",
                mt: 2,
                mb: 1,
                "& i": {
                  mr: 1,
                },
                "&:hover,&:focus": { bgcolor: "#313131" },
              }}
              type="submit"
            >
              <i className="fa-light fa-envelope" />
              {component.trans("newsletter-btn")}
            </Button>
            {/* <CardMedia
              component="img"
              sx={{
                position: { xs: "relative", md: "absolute" },
                right: 0,
                bottom: 0,
                width: { xs: "100%", sm: 400 },
                mx: "auto",
              }}
              image={filesUrl.concat(`/marketing/newsletter.png`)}
              alt="newsletter image"
            /> */}
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          bgcolor: "#2d3843",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            px: 2,
            position: "relative",
            py: 2,
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <SocialLink
            href="https://www.facebook.com/casadipatsi"
            target="_blank"
            sx={{ m: { xs: "0px 20px", md: "0px 40px 0px 0px" } }}
            aria-label="facebook"
          >
            <i className="fa-brands fa-facebook-f" />
          </SocialLink>
          <SocialLink
            href="https://www.instagram.com/casadipatsi/"
            target="_blank"
            aria-label="instagram"
            sx={{ m: { xs: "0px 20px", md: "0px 40px 0px 0px" } }}
          >
            <i className="fa-brands fa-instagram" />
          </SocialLink>
          <SocialLink
            href="https://gr.pinterest.com/casadipatsi/"
            target="_blank"
            aria-label="pinterest"
            sx={{ m: { xs: "0px 20px", md: "0px 40px 0px 0px" } }}
          >
            <i className="fa-brands fa-pinterest-p" />
          </SocialLink>
          <SocialLink
            href="https://twitter.com/CasadiPatsi"
            target="_blank"
            aria-label="twitter"
            sx={{ m: { xs: "0px 20px", md: "0px 40px 0px 0px" } }}
          >
            <i className="fa-brands fa-twitter" />
          </SocialLink>
        </Container>
      </Box>
      <Container
        maxWidth="lg"
        sx={{
          position: "relative",
          py: 2,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          rowSpacing={{ xs: 1 }}
          columnSpacing={{ xs: 3 }}
        >
          <Grid item xs={12}>
            <FooterTitle>{component.trans("footer-title-1")}</FooterTitle>
            <Typography
              component="h3"
              className="maxRows2"
              sx={{
                color: "#d1d1d1",
              }}
            >
              {component.ucfirst("footer-subtitle-1")}
            </Typography>
            <FooterLink
              component={RouterLink}
              to="/company"
              underline="hover"
              sx={{
                display: "inline-flex",
                mt: 1,
                mb: 0,
                float: "right",
              }}
            >
              {component.trans("more-btn")}
            </FooterLink>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FooterTitle>{component.trans("footer-title-2")}</FooterTitle>
            {links.map((link) => {
              const { slug, url } = devHelper.getObjectValue(link, "_values");
              return (
                <FooterLink
                  key={slug}
                  component={RouterLink}
                  to={url}
                  underline="hover"
                >
                  <Translate
                    entity="item"
                    eslug={slug}
                    scope="default"
                    slug="name"
                    auto
                  />
                </FooterLink>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FooterTitle>{component.trans("footer-title-3")}</FooterTitle>
            <FooterLink
              component={RouterLink}
              to="/all-brands"
              underline="hover"
            >
              {component.ucfirst("brands")}
            </FooterLink>
            <FooterLink component={RouterLink} to="/hotels" underline="hover">
              {component.ucfirst("hotels")}
            </FooterLink>
            <FooterLink component={RouterLink} to="/stores" underline="hover">
              {component.ucfirst("stores")}
            </FooterLink>
            <FooterLink
              component={RouterLink}
              to="/designers"
              underline="hover"
            >
              {component.ucfirst("designers")}
            </FooterLink>
            <FooterLink
              target="_blank"
              href="https://www.casadipatsistock.gr/"
              underline="hover"
            >
              {component.ucfirst("Casa Di Patsi Stockhouse")}
            </FooterLink>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FooterTitle>{component.trans("footer-title-4")}</FooterTitle>
            <Link
              sx={customLink}
              href={"tel:" + component.trans("phone-number")}
              onClick={() =>
                component.getApp().addClick({
                  type: "phone",
                  info: component.trans("phone-number"),
                })
              }
            >
              <Box>
                <i className="fa-light fa-circle-phone" />
              </Box>
              {component.trans("phone-number")}
            </Link>
            <Link
              sx={customLink}
              href={"mailto:" + component.trans("shop-email")}
              target="_blank"
              onClick={() =>
                component.getApp().addClick({
                  type: "email",
                  info: component.trans("shop-email"),
                })
              }
            >
              <Box>
                <i className="fa-light fa-circle-envelope" />
              </Box>
              {component.trans("shop-email")}
            </Link>
            <Link sx={customLink} component={RouterLink} to="/stores">
              <Box>
                <i className="fa-light fa-location-dot" />
              </Box>
              {component.ucfirst("stores")}
            </Link>
            <Button
              sx={{
                textTransform: "none",
                p: 0,
                color: "#d1d1d1",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
                fontSize: "1rem",
                lineHeight: 1.5,
                fontWeight: 400,
                "& i": {
                  minWidth: 35,
                  fontSize: "22px",
                },
                "& div": {
                  textAlign: "center",
                  display: "inline-flex",
                },
                "&:hover": {
                  color: "#fff",
                },
              }}
              onClick={() => setContactUsOpen(true)}
            >
              <Box>
                <i className="fa-light fa-message-question" />
              </Box>
              {component.trans("contact-form")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FooterTitle>
              {component.trans("deliveries-department")}
            </FooterTitle>
            <Link
              sx={customLink}
              href={"tel:" + component.trans("deliveries-department-phone")}
              onClick={() =>
                component.getApp().addClick({
                  type: "phone",
                  info: component.trans("deliveries-department-phone"),
                })
              }
            >
              <Box>
                <i className="fa-light fa-circle-phone" />
              </Box>
              {component.trans("deliveries-department-phone")}
            </Link>
            <Box sx={customLink}>
              {component.trans("deliveries-department-days")}
              <br />
              {component.trans("deliveries-department-hours")}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Copyright />
      <DevelopedBy />
      <ContactUs
        {...{
          ...props,
          ...{
            open: contactUsOpen,
            setOpen: (boolean) => setContactUsOpen(boolean),
          },
        }}
      />
    </Box>
  );
}
