import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Popover,
  IconButton,
} from "@mui/material";
import CouponDialog from "../../../../Components/Widgets/Dialogs/CouponDialog";
import Translate from "../../../../../modules/Core/Components/Translate";

const AddButton = styled(Button)(({ theme }) => ({
  marginTop: "24px",
  padding: "12px 14px",
  background: "#fff",
  color: "#000",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const Coupon = styled(Paper)(({ theme }) => ({
  height: "160px",
  backgroundColor: "#000",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export default function Coupons(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const coupons = component.getData("default.coupons", null);
  const [newCouponDialogOpen, setNewCouponDialogOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [c_slug, setCouponSlug] = React.useState(null);

  const handleClick = (event, slug) => {
    setAnchorEl(event.currentTarget);
    setCouponSlug(slug);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 3,
      }}
      maxWidth="lg"
    >
      <Typography
        align="center"
        component="h1"
        sx={{ lineHeight: 1, fontSize: "34px", color: "#000", mt: 3 }}
      >
        {component.ucfirst("coupons-title")}
      </Typography>
      <AddButton
        variant="outlined"
        onClick={() => {
          setNewCouponDialogOpen(true);
        }}
        startIcon={<i className="fak fa-light-ticket-circle-plus" />}
      >
        {component.trans("add-new-coupon-btn")}
      </AddButton>
      {!coupons ? (
        <CircularProgress
          size={30}
          sx={{
            my: 20,
            color: "#000",
          }}
        />
      ) : (
        <>
          {coupons.length !== 0 ? (
            <>
              <Grid
                container
                alignItems="center"
                sx={{ mt: 3, mb: 2 }}
                direction="row"
                spacing={2}
              >
                {coupons.map((coupon) => (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={devHelper.getObjectValue(coupon, "_id")}
                  >
                    <Coupon elevation={2}>
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          color: "#fff",
                        }}
                        onClick={(e) =>
                          handleClick(
                            e,
                            devHelper.getObjectValue(coupon, "slug")
                          )
                        }
                      >
                        <i className="fa-light fa-circle-info" />
                      </IconButton>
                      <Typography
                        align="center"
                        sx={{
                          color: "#fff",
                          fontSize: "60px",
                        }}
                      >
                        <Translate
                          entity="item"
                          eslug={devHelper.getObjectValue(coupon, "slug")}
                          scope="default"
                          slug="name"
                          auto
                        />
                      </Typography>
                    </Coupon>
                  </Grid>
                ))}
              </Grid>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 1 }}>
                  <Translate
                    entity="item"
                    eslug={c_slug}
                    scope="default"
                    slug="info"
                    auto
                  />
                </Typography>
              </Popover>
            </>
          ) : (
            <Typography align="center" sx={{ my: 20, height: "30px" }}>
              {component.ucfirst("zero-coupons")}
            </Typography>
          )}
        </>
      )}
      <CouponDialog
        {...{
          ...props,
          ...{
            open: newCouponDialogOpen,
            setOpen: (boolean) => setNewCouponDialogOpen(boolean),
            retrieveCoupons: () => component.retrieveCoupons(),
          },
        }}
      />
    </Container>
  );
}
