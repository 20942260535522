import React from "react";
import { Container } from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

export default function Company(props) {
  return (
    <Container
      sx={{
        px: 2,
        my: 3,
        "& h5": {
          fontWeight: 400,
          lineHeight: 2,
          fontSize: 15,
        },
      }}
      disableGutters
      maxWidth="lg"
    >
      <iframe
        style={{ aspectRatio: "16 / 9", width: "100%" }}
        src="https://www.youtube.com/embed/WKyzjdn3SrQ?autoplay=1&loop=1&playlist=WKyzjdn3SrQ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <Translate
        entity="item"
        eslug="company"
        scope="default"
        slug="info"
        auto
      />
    </Container>
  );
}
