import React from "react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  Link,
  Box,
  Button,
  IconButton,
  InputBase,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LanguageSelect from "../../../../Components/Widgets/LanguageSelect";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "30px",
  margin: "8px auto",
  display: "block",
  backgroundColor: "#f7f8fa",
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#000 !important",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#777",
  width: "100%",
  "& .MuiInputBase-input": {
    color: "#000",
    padding: "12px 8px 12px 0px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const TitleLink = styled(Link)(({ theme }) => ({
  color: "#000",
  padding: "12px",
  backgroundColor: "transparent",
  borderRadius: "0px",
  fontSize: "18px",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid #e4e5e7",
  "& i": { marginRight: "16px" },
}));

const OuterLink = styled(Link)(({ theme }) => ({
  color: "#000",
  padding: "12px",
  backgroundColor: "transparent",
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "16px",
  fontWeight: 450,
  borderBottom: "1px solid #e4e5e7",
  "& div": {
    marginLeft: "20px",
  },
}));

const InnerLink = styled(Link)(({ theme }) => ({
  color: "#000",
  padding: "12px",
  backgroundColor: "transparent",
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "16px",
  fontWeight: 400,
  borderBottom: "1px solid #e4e5e7",
  paddingLeft: "50px",
}));

export default function SubMenuDrawer(props) {
  const { component } = props;
  const menu = component.getData("default.menu", null);

  const mainMenu = component.getMainMenu();

  const closeAllDrawers = () => {
    component.toggleMenuDrawer();
    component.toggle();
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => closeAllDrawers()}
      onOpen={() => {}}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 3,
        "& .MuiDrawer-paper": {
          p: 0,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: { xs: "90%", sm: "500px" },
        },
        "& .MuiDrawer-paper::-webkit-scrollbar": {
          display: "none",
        },
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      swipeAreaWidth={0}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          mt: 1,
          pt: 1,
          px: 3,
        }}
      >
        <IconButton
          onClick={() => closeAllDrawers()}
          style={{
            position: "absolute",
            right: 16,
            top: 0,
            fontSize: 22,
            color: "#000",
          }}
        >
          <i className="fa-light fa-xmark" />
        </IconButton>
        <IconButton
          onClick={() => component.toggle()}
          style={{
            position: "absolute",
            left: 16,
            top: 0,
            fontSize: 18,
            color: "#000",
          }}
        >
          <i className="fa-regular fa-chevron-left" />
        </IconButton>
        <Box sx={{ textAlign: "center" }}>
          <LanguageSelect {...props} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <form onSubmit={(e) => component.checkSearch(e)} method="get">
            <Search
              sx={{
                width: { md: "500px", lg: "600px" },
              }}
            >
              <SearchIconWrapper type="submit" disableRipple>
                <i className="fa-light fa-magnifying-glass" />
              </SearchIconWrapper>
              <StyledInputBase
                value={component.getData("default.search_term", "")}
                onChange={(e) => {
                  component.setData({
                    "default.search_term": e.target.value,
                  });
                }}
                placeholder={component.ucfirst("search-holder")}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </form>
        </Box>
      </Box>
      <Box sx={{ px: 2, py: 1 }}>
        {mainMenu.map((sub_menu, index) => {
          const {
            slug,
            subMenu = {},
            menu_types = [],
            menu_template,
            url,
          } = sub_menu;

          return (
            <Box
              key={`sub-menu-${slug}`}
              sx={{
                display: menu === menu_template ? "block" : "none",
              }}
            >
              <TitleLink
                underline="none"
                to={url}
                component={RouterLink}
                onClick={() => closeAllDrawers()}
              >
                {component.ucfirst(slug)}
              </TitleLink>
              <ul
                style={{
                  margin: "0px",
                  padding: "0px",
                  listStyleType: "none",
                }}
              >
                {menu_types.map((type) => {
                  const { slug, link, has_menu } = type;
                  return (
                    <Box key={`outer-link-${slug}`}>
                      <OuterLink
                        underline="none"
                        component={RouterLink}
                        to={link}
                        onClick={() => closeAllDrawers()}
                      >
                        <Box>{component.ucfirst(slug)}</Box>
                        <i
                          className="fa-solid fa-chevron-down"
                          style={{
                            visibility: has_menu ? "visible" : "hidden",
                          }}
                        />
                      </OuterLink>
                      {subMenu[slug] &&
                        subMenu[slug].map((type) => {
                          const { slug, url } = type;
                          return (
                            <InnerLink
                              key={`inner-link-${slug}`}
                              underline="none"
                              component={RouterLink}
                              to={url}
                              onClick={() => closeAllDrawers()}
                            >
                              {component.ucfirst(slug)}
                            </InnerLink>
                          );
                        })}
                    </Box>
                  );
                })}
              </ul>
            </Box>
          );
        })}
      </Box>
    </SwipeableDrawer>
  );
}
