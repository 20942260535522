import React, { useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  Typography,
  CardMedia,
  Link,
  Fade,
} from "@mui/material";
import Filters from "../filters";
import ProductListView from "../../../../Components/Widgets/ProductListView";
import ProductListSkeletonView from "../../widgets/product_list_skeleton_view";
import Translate from "../../../../../modules/Core/Components/Translate";

export default function Collection(props) {
  const { component } = props;

  const { products = null, filters = [] } = component.getData("default", {});
  const term = component.getSearchTerm();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [showText, setShowText] = React.useState(false);

  const {
    pageScope,
    b2b = false,
    catalogues = [],
  } = component.getData("default", {});

  const { type, value } = component.getPage().getParams();

  const pageType = type;
  const pageValue = value;
  const page = `${pageType}-${pageValue}`;

  useEffect(() => {
    const onScroll = () => {
      const max = 0.6;

      if (
        !component.getData("default.loading") &&
        component.canFetchMore() &&
        (window.scrollY + window.innerHeight) / document.body.clientHeight > max
      ) {
        component.fetchMore();
      }

      localStorage.setItem("scrollPosition", window.scrollY);
      localStorage.setItem("scrollList", window.location.pathname);
    };

    const onBack = () => {
      localStorage.removeItem("saveScrollPosition");
      component.getPage().redirect("/");
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("popstate", onBack);

    return () => {
      if (onScroll) {
        window.removeEventListener("scroll", onScroll);
      }

      if (onBack) {
        window.removeEventListener("popstate", onBack);
      }

      const scrollY = localStorage.getItem("scrollPosition");

      localStorage.setItem("saveScrollPosition", scrollY);
    };
  }, [component]);

  return (
    <Container disableGutters maxWidth="lg">
      <Box sx={{ mt: 2, mb: 4, px: 2 }}>
        {pageType === "designer" || pageType === "brand" ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                border: "1px solid #e4e5e7",
                borderRadius: "12px",
                display: "inline-block",
                p: 1,
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: { xs: 130, md: 150 },
                  height: { xs: 130, md: 150 },
                  borderRadius: "12px",
                }}
                title={component.ucfirst(pageValue)}
                image={filesUrl.concat(
                  pageType === "designer"
                    ? `/designers/images/${pageValue}.jpg`
                    : `/brands/images/square/${pageValue}.jpeg`
                )}
                alt={component.ucfirst(pageValue)}
              />
            </Box>
            <Box
              sx={{
                display: "inline-block",
                ml: 2,
              }}
            >
              <Typography
                component="h1"
                sx={{
                  fontSize: { xs: 18, md: 24 },
                  lineHeight: 1,
                  mb: 1,
                  fontWeight: 500,
                }}
              >
                {devHelper.getObjectValue(pageScope, "_values.name")}
              </Typography>
              <Typography
                component="h2"
                sx={{
                  fontSize: { xs: 14, md: 16 },
                  lineHeight: 1,
                  color: "#777",
                }}
              >
                {component.ucfirst(pageType)}
              </Typography>
              {b2b &&
                devHelper.getObjectValue(pageScope, "_values.website") && (
                  <Button
                    href={devHelper.getObjectValue(
                      pageScope,
                      "_values.website"
                    )}
                    target="_blank"
                    sx={{ bgcolor: "#f3f3f3", mt: 3, px: 2 }}
                  >
                    website
                  </Button>
                )}
            </Box>
          </Box>
        ) : (
          <Typography
            component="h1"
            sx={{
              fontSize: { xs: 26, md: 32 },
              lineHeight: 1,
            }}
          >
            {term
              ? `${component.ucfirst("products")} ${decodeURIComponent(term)}`
              : component.ucfirst(pageValue)}
          </Typography>
        )}
        <Typography
          component="h3"
          sx={{
            color: "#000",
            fontSize: 16,
            mt: 2,
            maxHeight: showText ? "unset" : 45,
            overflowY: "hidden",
          }}
        >
          <Translate
            entity="item"
            eslug={pageValue}
            scope="default"
            slug="description"
            auto
          />
        </Typography>
        {(pageType === "designer" || pageType === "brand") && (
          <Link
            underline="hover"
            sx={{
              fontSize: 16,
              float: "right",
              color: "#000",
              mt: 0.5,
              cursor: "pointer",
            }}
            onClick={() => setShowText(!showText)}
          >
            {component.trans(showText ? "less-btn" : "more-btn")}
          </Link>
        )}
      </Box>
      {catalogues.length !== 0 && (
        <Box sx={{ my: 6, mb: 4 }}>
          <Typography
            component="h2"
            sx={{ fontSize: 18, fontWeight: 600, mb: 2 }}
          >
            {component.ucfirst("catalogues")}
          </Typography>

          <Grid container direction="row" rowSpacing={2} columnSpacing={1}>
            {catalogues.map((cat, index) => (
              <Grid
                item
                xs={6}
                sm={3}
                key={`catalogue-${index}`}
                sx={{ textAlign: "center" }}
              >
                <Fade in={component.getData(`default.pdf-${index}`, false)}>
                  <Link
                    target="_blank"
                    href={filesUrl.concat(
                      `/brands/catalogues/${value}/${cat}.pdf`
                    )}
                    underline="none"
                  >
                    <CardMedia
                      sx={{
                        width: "100%",
                        mx: "auto",
                      }}
                      component="img"
                      image={filesUrl.concat(
                        `/brands/catalogues/${value}/${cat}.jpg`
                      )}
                      onLoad={() => {
                        component.setData({
                          [`default.pdf-${index}`]: true,
                        });
                      }}
                      onError={() => {
                        component.setData({
                          [`default.pdf-${index}`]: true,
                        });
                      }}
                      alt={`${cat} pdf`}
                    />
                    <Typography
                      component="h3"
                      sx={{ fontWeight: 500, fontSize: 14, mt: 1 }}
                    >
                      {cat}
                    </Typography>
                  </Link>
                </Fade>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {/* filters bar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 1,
        }}
      >
        <Button
          sx={{
            fontSize: 18,
            textTransform: "none",
            p: 0,
            color: "#000",
            mr: 4,
            "&:hover": {
              background: "transparent",
            },
            "&:disabled": {
              color: "#000",
            },
            "& > i": {
              fontSize: 20,
              mr: 0.5,
            },
          }}
          onClick={() => setFilterDrawer((prev) => !prev)}
          disableRipple
        >
          <i className="fa-light fa-sliders" />
          {component.ucfirst("filters-btn")}
        </Button>
        <Button
          sx={{
            borderRadius: 0,
            fontSize: 18,
            textTransform: "none",
            p: 0,
            color: "#000",
            "&:hover": {
              background: "transparent",
            },
            "&:disabled": {
              color: "#000",
            },
          }}
          disabled
          disableRipple
        >
          {`${component.getData("default.total", 0)} ${component.ucfirst(
            "products"
          )} `}
        </Button>
      </Box>
      <Box
        sx={{
          position: "relative",
          overflowX: "hidden",
          my: 1,
          minHeight: "600px",
        }}
      >
        <Filters
          {...{
            ...props,
            ...{
              filterDrawer,
              filters,
              setFilterDrawer: (boolean) => setFilterDrawer(boolean),
            },
          }}
        />
        <Box
          sx={{
            width: "100%",
            transition: "margin-left 0.3s",
            px: { xs: 0, md: 1 },
            marginLeft: filterDrawer ? "350px" : 0,
            "@media (max-width: 500px)": {
              marginLeft: filterDrawer ? "100%" : 0,
            },
          }}
        >
          {!products ? (
            <Grid
              container
              direction="row"
              sx={{
                alignItems: "stretch",
              }}
              columnSpacing={1}
              rowSpacing={1}
            >
              {[...new Array(40)].map((product, index) => (
                <ProductListSkeletonView
                  key={`skeleton-${index}`}
                  {...{
                    ...props,
                    ...{
                      grid: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          ) : (
            <>
              {products.length !== 0 ? (
                <Grid
                  container
                  direction="row"
                  sx={{
                    alignItems: "stretch",
                  }}
                  columnSpacing={1}
                  rowSpacing={1}
                >
                  {products.map((product) => (
                    <ProductListView
                      key={`${page}-${devHelper.getObjectValue(
                        product,
                        "_id"
                      )}`}
                      {...{
                        ...props,
                        ...{
                          product,
                          grid: 4,
                        },
                      }}
                    />
                  ))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      mt: "50px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        mb: 6,
                      }}
                    >
                      {component.ucfirst("no-products-found-message")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}
