import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  List,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ProductCartView from "../../../../Components/Widgets/ProductCartView";

const MyTableCell = styled(TableCell)({
  padding: "8px 0px",
  borderBottom: "none",
  fontSize: 16,
  "@media (max-width: 580px )": {
    padding: "8px 10px 8px 0px",
  },
});

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

export default function Cart(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const order = component.getData("default.order", null);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "unset",
        px: { xs: 1, sm: 2 },
      }}
      disableGutters
      maxWidth="lg"
    >
      <Typography
        align="center"
        component="h1"
        sx={{ lineHeight: 1, fontSize: "34px", color: "#000", mt: 3 }}
      >
        {component.ucfirst("cart")}
      </Typography>
      {!order ? (
        <CircularProgress
          size={30}
          sx={{
            my: 20,
            color: "#000",
          }}
        />
      ) : (
        <>
          {order.items && order.items.length !== 0 ? (
            <>
              <List
                sx={{
                  width: { xs: "100%" },
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        borderBottom: "1px solid rgba(0,0,0,.1)",
                        verticalAlign: "top",
                      }}
                    >
                      <MyTableCell
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        {component.trans("item")}
                      </MyTableCell>
                      <MyTableCell
                        sx={{
                          textTransform: "uppercase",
                        }}
                        align="right"
                      >
                        {component.trans("total")}
                      </MyTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.items.map((product, index) => (
                      <ProductCartView
                        key={devHelper.getObjectValue(product, "sku")}
                        {...{
                          ...props,
                          ...{
                            product,
                          },
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody style={{ float: "right" }}>
                    <TableRow>
                      <MyTableCell
                        sx={{
                          width: 70,
                          fontSize: 16,
                          textTransform: "uppercase",
                          fontWeight: 500,
                        }}
                      >
                        {component.trans("total")}
                      </MyTableCell>
                      <MyTableCell
                        sx={{ fontSize: 16, color: "#ff6363", fontWeight: 500 }}
                      >
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.items")
                          )}
                      </MyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </List>
              <Typography
                align="right"
                sx={{
                  fontSize: 12,
                  color: "#9b9b9b",
                  fontWeight: 400,
                  mt: -2.5,
                }}
              >
                {component.trans("vat-included")}
              </Typography>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <MyButton
                  component={RouterLink}
                  to="/checkout"
                  sx={{ minWidth: "200px" }}
                  disableRipple
                >
                  {component.trans("checkout")}
                </MyButton>
              </Box>
            </>
          ) : (
            <Typography align="center" sx={{ my: 20, height: "30px" }}>
              {component.trans("empty-cart-message")}
            </Typography>
          )}
        </>
      )}
    </Container>
  );
}
