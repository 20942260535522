import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/designers";
import Services from "../../Services/Services";

export default class Designers extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
    this.addSearch();
    this.loadCollection(true);
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    const newPage =
      prevProps.location.pathname !== this.props.location.pathname;

    if (newPage || prevProps.location.search !== this.props.location.search) {
      this.loadCollection(newPage);
    }
  }

  loadCollection(newPage) {
    this.getComponents()
      .findById("designers")
      .forEach((c) => {
        c.loadCollection(newPage);
      });

    return this;
  }

  async addSearch() {
    const term = this.getQuery("term");

    if (term) {
      const [hookService] = await Services.get("hook");
      await hookService.post("add-search", { term });
    }
  }
}
