import React from "react";
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Link,
  Button,
  Box,
  Typography,
  Grid,
  Container,
  Fade,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const InnerLink = styled(Link)(({ theme }) => ({
  padding: 0,
  backgroundColor: "transparent",
  borderRadius: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: 16,
  marginBottom: 8,
  color: "#393d41",
}));

const Title = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontSize: 20,
  fontWeight: 500,
  textAlign: "left",
  marginBottom: 16,
}));

const Menu = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  zIndex: 3000,
  width: "100%",
  backgroundColor: "transparent",
}));

export default function MainMenu(props) {
  const { component } = props;

  const [openMenu, setOpenMenu] = React.useState(false);
  const [menu, setMenu] = React.useState("furniture");
  const [sMenu, setSubMenu] = React.useState("living-area");

  const [menuEffect, setMenuEffect] = React.useState(false);

  const mainMenu = component.getData("default.mainMenu", []);

  const handleToggle = (boolean, menu) => {
    setOpenMenu(boolean);
    if (menu !== undefined) {
      const { menu_template } = menu;
      setMenu(menu_template);
      changeSubMenu(menu);
    }
  };

  const changeSubMenu = (menu) => {
    const { menu_types = [], subMenu } = menu;
    setSubMenu(
      menu_types[0] && subMenu[menu_types[0].slug] ? menu_types[0].slug : ""
    );
  };

  React.useEffect(() => {
    if (mainMenu.length) {
      setTimeout(() => {
        setMenuEffect(true);
      }, 500);
    }
  }, [mainMenu.length]);

  return (
    <Box
      sx={{
        display: { xs: "none", md: "block" },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: "0px !important",
        }}
      >
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            px: { xs: 0, md: 1 },
            py: 0,
            display: mainMenu.length ? "flex" : "none",
            justifyContent: "flex-start",
          }}
        >
          {mainMenu.map((menu) => {
            const { slug, url, menu_types = [], color } = menu;
            return (
              <Box
                key={`menu-btn-${slug}`}
                onMouseEnter={() => {
                  if (menuEffect && menu_types.length !== 0) {
                    handleToggle(true, menu);
                  }
                }}
                onMouseLeave={() => handleToggle(false, menu)}
                sx={{
                  "& a": {
                    bgcolor:
                      slug === "new" || slug === "shop" || slug === "event"
                        ? color
                        : "transparent",
                    color:
                      slug === "shop" || slug === "event" ? "#fff" : "#000",
                  },
                  py: 1,
                  "&:hover,&:focus": {
                    "& a": {
                      bgcolor: color,
                    },
                  },
                }}
              >
                <Button
                  onClick={() => handleToggle(false, menu)}
                  to={url}
                  component={RouterLink}
                  sx={{
                    color: "#000",
                    fontWeight: 400,
                    textTransform: "none",
                    fontSize: 16,
                    p: "4px 8px",
                    mr: 1,
                    bgcolor: "transparent",
                    borderRadius: 30,
                  }}
                  disableRipple
                >
                  {component.ucfirst(slug)}
                </Button>
              </Box>
            );
          })}
        </Container>
      </Toolbar>
      <Fade in={openMenu} timeout={{ enter: 500, exit: 0 }}>
        {/* <Fade in={true} timeout={{ enter: 500, exit: 0 }}> */}
        <Container
          sx={{
            position: "relative",
            textAlign: "center",
            backgroundColor: "transparent",
          }}
          maxWidth="lg"
          disableGutters
        >
          {mainMenu.map((sub_menu, index) => {
            const { slug, subMenu = {}, menu_types = [], color } = sub_menu;

            return (
              <Menu
                key={`menu-${slug}`}
                onMouseEnter={() => {
                  handleToggle(true);
                }}
                onMouseLeave={() => {
                  handleToggle(false);
                }}
                sx={{
                  display: menu === sub_menu.menu_template ? "block" : "none",
                }}
              >
                <Grid container direction="row" sx={{ minHeight: "410px" }}>
                  <Grid item xs={4} sx={{ bgcolor: "#fff" }}>
                    <Box
                      sx={{
                        bgcolor: color.replace("1)", "0.5)"),
                        py: 3,
                        height: "100%",
                      }}
                    >
                      <Title variant="h4" component="h1" sx={{ pl: 4 }}>
                        {component.ucfirst(slug)}
                      </Title>
                      <ul
                        style={{
                          margin: "0px",
                          padding: "0px",
                          listStyleType: "none",
                        }}
                      >
                        {menu_types.map((type) => {
                          const { slug, link, has_menu } = type;
                          return (
                            <Box
                              key={`outer-link-${slug}`}
                              sx={{
                                borderRadius: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                fontSize: 16,
                                fontWeight: 500,
                                mb: 1,
                                cursor: "pointer",
                                bgcolor: sMenu === slug ? color : "transparent",
                                "& i": { mr: 2, color: "#000" },
                                "&:hover,&:focus": {
                                  "& a": { textDecoration: "underline" },
                                },
                              }}
                              onMouseEnter={() => setSubMenu(slug)}
                              onClick={() => {
                                handleToggle(false);
                                component.getPage().redirect(link);
                              }}
                            >
                              <Box
                                component={RouterLink}
                                to={link}
                                sx={{
                                  px: 4,
                                  py: "4px",
                                  color: "#393d41",
                                  textDecoration: "none",
                                }}
                              >
                                {component.ucfirst(slug)}
                              </Box>
                              <i
                                className="fa-solid fa-chevron-right"
                                style={{
                                  visibility: has_menu ? "visible" : "hidden",
                                }}
                              />
                            </Box>
                          );
                        })}
                      </ul>
                    </Box>
                  </Grid>
                  {menu_types.map((type) => {
                    const { slug, has_menu } = type;

                    return (
                      <Grid
                        item
                        xs={8}
                        key={`sub-menu-${slug}`}
                        sx={{
                          display:
                            sMenu === slug && has_menu ? "block" : "none",
                          bgcolor: "#fff",
                        }}
                      >
                        <Box sx={{ bgcolor: color, py: 3, height: "100%" }}>
                          <Title variant="h4" component="h1" sx={{ pl: 4 }}>
                            {component.ucfirst(slug)}
                          </Title>
                          <ul
                            style={{
                              margin: "0px",
                              padding: "0px",
                              listStyleType: "none",
                            }}
                          >
                            {subMenu[slug] &&
                              subMenu[slug].map((type) => {
                                const { slug, url } = type;
                                return (
                                  <InnerLink
                                    key={`inner-link-${slug}`}
                                    underline="hover"
                                    component={RouterLink}
                                    to={url}
                                    sx={{ px: 4 }}
                                    onClick={() => {
                                      handleToggle(false);
                                    }}
                                  >
                                    {component.ucfirst(slug)}
                                  </InnerLink>
                                );
                              })}
                          </ul>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Menu>
            );
          })}
        </Container>
      </Fade>
    </Box>
  );
}
