import React from "react";
import { styled } from "@mui/material/styles";
import { Select, FormControl, InputBase, MenuItem } from "@mui/material";

const MyFormControl = styled(FormControl)(({ theme }) => ({
  color: "#777777",
  backgroundColor: "#fff",
  "& label.Mui-focused": {
    color: "#777777",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    display: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#777777",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#777777",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#777777",
      borderRadius: "12px",
    },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
    color: "#777777",
    position: "relative",
    border: "none",
    fontSize: "12px",
    padding: "0px 26px 0px 0px",
    [theme.breakpoints.down("md")]: {
      // fontSize: "14px",
    },
    "&:focus": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  "& .MuiSvgIcon-root": {
    color: "#777777",
  },
  "& img": {},
}));

export default function Footer(props) {
  const { component } = props;
  const lang = component.getData("default.lang", "en");
  const languages = component.getData("default.languages", []);

  return (
    <>
      {languages.length !== 0 && (
        <MyFormControl variant="standard" sx={{ mr: 1, pl: 0 }}>
          <Select
            value={lang}
            onChange={(e) => component.selectLang(e.target.value)}
            input={<BootstrapInput />}
          >
            {languages.map((language) => {
              const { slug } = language;
              return (
                <MenuItem
                  key={`language-select-${slug}`}
                  value={slug}
                  sx={{ color: "#777777", fontSize: "12px" }}
                >
                  <img
                    src={`https://flagcdn.com/16x12/${component.trans(
                      slug + "-flag"
                    )}.png`}
                    srcSet={`https://flagcdn.com/32x24/${component.trans(
                      slug + "-flag"
                    )}.png 2x,https://flagcdn.com/48x36/${component.trans(
                      slug + "-flag"
                    )}.png 3x`}
                    width="16"
                    height="12.5"
                    style={{ margin: "0px 4px 0px 0px" }}
                    alt={`${component.ucfirst(slug)} flag`}
                  />
                  {component.ucfirst(slug)}
                </MenuItem>
              );
            })}
          </Select>
        </MyFormControl>
      )}
    </>
  );
}
