import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/grid_subcategories";
import Services from "../../../Services/Services";

export default class GridSubCategories extends Component {
  id = "grid-subcategories";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.getMenu();
  }

  getMenu() {
    Services.get("hook").then(([service]) => {
      service
        .exec("main-menu", {
          params: { slug: this.getPage().getParam("category", "") },
        })
        .then((response) => {
          const menu = response.getData() || {};

          this.setData({
            "default.menu": this.fixMainMenu(menu),
            "default.submenu": this.getPage().getParam("subcategory", ""),
          });
        });
    });
  }

  fixMainMenu(menu) {
    const { _values = {} } = menu;
    const subMenu = _values["sub-menu"] || [];
    const newSubMenu = {};

    subMenu.forEach((subMenu) => {
      const { type } = subMenu;

      if (!newSubMenu[type]) {
        newSubMenu[type] = [];
      }

      newSubMenu[type].push(subMenu);
    });

    for (var i in newSubMenu) {
      newSubMenu[i] = newSubMenu[i].sort((a, b) => {
        const posA = a.position * 1;
        const posB = b.position * 1;

        return posA - posB;
      });
    }

    _values["subMenu"] = newSubMenu;

    const menu_types = _values["menu_types"] || [];
    menu_types.forEach((type) => {
      const { slug } = type;
      type["has_menu"] = Object.hasOwn(newSubMenu, slug);
    });

    return menu;
  }
}
