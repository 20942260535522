import React from "react";
import { Box, Container } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import CartDrawer from "../../../Components/Widgets/CartDrawer";
import TopBannersSlider from "../../../Components/Widgets/Sliders/TopBannersSlider";
import GridBanners from "../../../templates/default/widgets/sliders/grid_banners";
import Translate from "../../../../modules/Core/Components/Translate";
import Footer from "../../../Components/Widgets/Footer";

const ExportDefault = (props) => {
  const { component } = props;
  const { brand } = component.getPage().getParams();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#F7F8FA",
      }}
    >
      <NavBar {...props} />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <CartDrawer {...props} />
      <TopBannersSlider
        {...{
          ...props,
          ...{
            text_color: "#fff",
            top: "50%",
            left: "50%",
            center: "-50%,-50%",
            slides: [
              {
                slug: "slider-cattelan-italia",
                url: "/home/brand/cattelan-italia",
              },
            ],
          },
        }}
      />
      <Container
        maxWidth="lg"
        sx={{
          textAlign: "center",
          "& h5": {
            mt: 4,
            mb: 1,
            fontWeight: 500,
            lineHeight: 1,
            fontSize: { xs: 18, md: 22 },
          },
          "& p": {
            fontSize: { xs: 16, md: 18 },
          },
        }}
      >
        <Translate
          entity="item"
          eslug={`home-${brand}`}
          scope="default"
          slug="default"
          auto
        />
      </Container>
      <GridBanners
        {...{
          ...props,
          ...{
            fscope: `slider-grid-${brand}`,
            text_color: "#fff",
            top: "0%",
            left: "0%",
            descriptionMaxWidthMobile: "450px",
            slides: [
              {
                slug: "slider-grid-cattelan-italia-1",
                url: "/products/brand/cattelan-italia?type=table&scope=type",
                width: 744,
                height: 522,
              },
              {
                slug: "slider-grid-cattelan-italia-2",
                url: "/products/brand/cattelan-italia?type=chair&scope=type",
                width: 500,
                height: 725,
              },
              {
                slug: "slider-grid-cattelan-italia-3",
                url: "/products/brand/cattelan-italia?type=sideboard&scope=type",
                width: 500,
                height: 725,
              },
              {
                slug: "slider-grid-cattelan-italia-4",
                url: "/products/brand/cattelan-italia?type=bookcase&scope=type",
                width: 500,
                height: 725,
              },
              {
                slug: "slider-grid-cattelan-italia-5",
                url: "/products/brand/cattelan-italia?type=console&scope=type",
                width: 500,
                height: 725,
              },
              {
                slug: "slider-grid-cattelan-italia-6",
                url: "/products/brand/cattelan-italia?type=coffee-table,side-table&scope=type",
                width: 744,
                height: 522,
              },
              {
                slug: "slider-grid-cattelan-italia-7",
                url: "/products/brand/cattelan-italia?type=lamp&scope=type",
                width: 1284,
                height: 265,
              },
              {
                slug: "slider-grid-cattelan-italia-8",
                url: "/products/brand/cattelan-italia?type=mirror&scope=type",
                width: 744,
                height: 522,
              },
              {
                slug: "slider-grid-cattelan-italia-9",
                url: "/products/brand/cattelan-italia?type=desk,dressing-table&scope=type",
                width: 500,
                height: 725,
              },
              {
                slug: "slider-grid-cattelan-italia-10",
                url: "/products/brand/cattelan-italia?type=bar-stool&scope=type",
                width: 500,
                height: 725,
              },
              {
                slug: "slider-grid-cattelan-italia-11",
                url: "/products/brand/cattelan-italia?type=bed,dressers-bedside-table&scope=type",
                width: 1284,
                height: 265,
              },
              {
                slug: "slider-grid-cattelan-italia-12",
                url: "/products/brand/cattelan-italia?type=tv-unit&scope=type",
                width: 744,
                height: 522,
              },
              {
                slug: "slider-grid-cattelan-italia-13",
                url: "/products/brand/cattelan-italia?type=complements,rug,trolley-bar,stool,daybed,hanger&scope=type",
                width: 744,
                height: 522,
              },
            ],
            grids: [
              { xs: 12, sm: 6 },
              { xs: 6, sm: 3 },
              { xs: 6, sm: 3 },
              { xs: 6, sm: 3 },
              { xs: 6, sm: 3 },
              { xs: 12, sm: 6 },
              { xs: 12, sm: 12 },
              { xs: 12, sm: 6 },
              { xs: 6, sm: 3 },
              { xs: 6, sm: 3 },
              { xs: 12, sm: 12 },
              { xs: 12, sm: 6 },
              { xs: 12, sm: 6 },
            ],
          },
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
