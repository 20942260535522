import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  Container,
  TextField,
  IconButton,
  DialogTitle,
  Select,
  MenuItem,
  useMediaQuery,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Translate from "../../../../../modules/Core/Components/Translate";

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

const MyFormControl = styled(FormControl)(({ theme }) => ({
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function WishlistDialog(props) {
  const { component } = props;
  const { open = false } = component.props;
  const devHelper = component.getHelpers("dev");
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const stores = component.getData("default.stores", []);

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      fullScreen={fullScreen}
      maxWidth="xs"
      open={open}
    >
      <MyDialogTitle onClose={() => component.props.setOpen(false)}>
        {component.trans("request-info-btn-lowercase")}
      </MyDialogTitle>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form onSubmit={(e) => component.sendRequest(e)}>
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("fullname-textfield")}
            name="name"
            autoComplete="name"
            helperText={component.getData("error.fields.name")}
            value={component.getData("default.fields.name", "")}
            onChange={(e) => {
              component.setData({ "default.fields.name": e.target.value });
              component.deleteData("error.fields.name");
            }}
            error={component.dataExists("error.fields.name")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("email-textfield")}
            name="email"
            helperText={component.getData("error.fields.email")}
            value={component.getData("default.fields.email", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.email": e.target.value,
              });
              component.deleteData("error.fields.email");
            }}
            error={component.dataExists("error.fields.email")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={`${component.ucfirst("phone-textfield")}`}
            name="phone"
            helperText={component.getData("error.fields.phone")}
            value={component.getData("default.fields.phone", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.phone": e.target.value,
              });
              component.deleteData("error.fields.phone");
            }}
            error={component.dataExists("error.fields.phone")}
          />
          <MyFormControl
            fullWidth
            sx={{ mt: 2, mb: 1 }}
            error={component.dataExists("error.fields.store")}
          >
            <InputLabel>{`${component.ucfirst("store-textfield")}`}</InputLabel>
            <Select
              value={component.getData("default.fields.store", "")}
              label={`${component.ucfirst("store-textfield")}`}
              onChange={(e) => {
                component.setData({
                  "default.fields.store": e.target.value,
                });
                component.deleteData("error.fields.store");
              }}
            >
              {stores.map((store) => {
                const slug = devHelper.getObjectValue(store, "slug");
                return (
                  <MenuItem value={slug} key={slug}>
                    <Translate
                      entity="item"
                      eslug={slug}
                      scope="default"
                      slug="name"
                      auto
                    />
                  </MenuItem>
                );
              })}
            </Select>
            {component.dataExists("error.fields.store") && (
              <FormHelperText error sx={{ m: "3px 14px 0" }}>
                {component.getData("error.fields.store")}
              </FormHelperText>
            )}
          </MyFormControl>
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rows={3}
            label={component.ucfirst("message-textfield")}
            name="message"
            helperText={component.getData("error.fields.message")}
            value={component.getData("default.fields.message", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.message": e.target.value,
              });
              component.deleteData("error.fields.message");
            }}
            error={component.dataExists("error.fields.message")}
          />
          <MyButton type="submit" fullWidth>
            {component.ucfirst("submit")}
          </MyButton>
        </form>
      </Container>
    </Dialog>
  );
}
