import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/brands";

export default class Brands extends Page {
  title = "brands";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    //Set window title
    this.getApp().updateWindowTitle(
      `${this.ucfirst("brands")} | ${this.ucfirst("window-title")}`
    );
    this.scrollToTop();
  }
}
