import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Toolbar,
  useScrollTrigger,
  Box,
  AppBar,
  useMediaQuery,
} from "@mui/material";
import MainAppbar from "../../../../Components/Widgets/MainAppbar";

export default function Navbar(props) {
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";
  // const espa_view = useMediaQuery(theme.breakpoints.up("md")) ? 170.36 : 87.34;

  const main = useScrollTrigger({
    disableHysteresis: true,
    threshold: 5,
  });

  return (
    <Box sx={{ overflow: "hidden" }}>
      <AppBar
        elevation={!main ? 0 : 1}
        sx={{
          position: "fixed",
          backgroundColor: "#fff",
        }}
      >
        <MainAppbar {...props} />
      </AppBar>
      <Box
        sx={{
          height: {
            xs:
              // window.location.pathname === "/"
              //   ? espa_view
              //   : `${theme.heights[`main_${view}`]}px`,
              `${theme.heights[`main_${view}`]}px`,
          },
        }}
      />
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: "0.1px !important" }} />
    </Box>
  );
}
