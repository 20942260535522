import React, { useLayoutEffect, useState, useRef } from "react";
import {
  CardMedia,
  Link,
  Typography,
  Grid,
  Box,
  Fade,
  Stack,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function DesignerListView(props) {
  const { component } = props;

  const { grid = 3, xs = 6 } = component.props;

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const designer = component.getData("default.designer", null);

  const {
    slug = null,
    name = null,
    featuredProducts = [],
  } = devHelper.getObjectValue(designer, "_values", {});

  const ready = component.getData("default.ready", false);

  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        if (targetRef.current) {
          setHeight(targetRef.current.clientWidth / 2 - 24);
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }

  const img_height = SetImageHeight();

  return (
    <Grid item md={grid} xs={xs}>
      <Link
        component={RouterLink}
        to={`/products/designer/${slug}`}
        underline="none"
      >
        <Box
          sx={{
            height: "100%",
            pt: { xs: "0px", md: "4px" },
            pb: { xs: "0px", md: "12px" },
            transition: "all .3s ease-in-out",
            webkitTransition: "all .3s ease-in-out",
            "&:hover": {
              pt: { xs: 0, md: 0 },
              pb: { xs: 0, md: 2 },
              "& #child": {
                boxShadow: {
                  xs: "none",
                  md: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                },
              },
            },
          }}
        >
          <Stack
            spacing={1}
            id="child"
            sx={{
              p: 2,
              alignItems: "center",
              border: "1px solid #d0d6e2",
              bgcolor: "#F7F8FA",
            }}
            ref={targetRef}
          >
            <CardMedia
              component="img"
              sx={{
                width: { xs: 110, md: 130 },
                height: { xs: 110, md: 130 },
                borderRadius: "12px",
              }}
              title={component.ucfirst(name)}
              image={filesUrl.concat(`/designers/images/${slug}.jpg`)}
              alt={name}
            />
            <Typography
              component="h3"
              align="center"
              sx={{
                fontSize: 16,
                color: "#000",
                fontWeight: 500,
              }}
            >
              {name}
            </Typography>
            <Grid
              container
              direction="row"
              spacing={1}
              sx={{
                alignItems: "stretch",
                px: { xs: 0, md: 2 },
              }}
            >
              {img_height &&
                featuredProducts.map((product) => (
                  <Grid
                    item
                    xs={6}
                    key={devHelper.getObjectValue(product, "id")}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: img_height,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#F7F8FA",
                      }}
                    >
                      <Fade in={ready}>
                        <Box
                          sx={{
                            overflow: "hidden",
                          }}
                        >
                          <CardMedia
                            sx={{
                              maxWidth: "100%",
                              objectFit: "contain",
                              height: { xs: img_height },
                            }}
                            component="img"
                            image={filesUrl.concat(
                              `/products/${devHelper.getObjectValue(
                                product,
                                "brand.slug"
                              )}/images/${devHelper.getObjectValue(
                                product,
                                "group"
                              )}/1.jpg?v=c${img_version}`
                            )}
                            alt={`${devHelper.getObjectValue(
                              product,
                              "group"
                            )} image`}
                            onLoad={() => {
                              component.setData({
                                "default.ready": true,
                              });
                            }}
                            onError={(e) => {
                              e.target.src = filesUrl.concat(
                                `/assets/images/not-found.jpg`
                              );
                              component.setData({
                                "default.ready": true,
                              });
                            }}
                          />
                        </Box>
                      </Fade>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </Box>
      </Link>
    </Grid>
  );
}
