import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/subcategory";

export default class SubCategory extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    const { subcategory } = this.getPage().getParams();
    this.getApp().updateWindowTitle(
      this.ucfirst(
        `${this.ucfirst(subcategory)} | ${this.ucfirst("window-title")}`
      )
    );
    this.scrollToTop();
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (prevProps.location.pathname !== this.props.location.pathname) {
      const { subcategory } = this.getPage().getParams();
      this.getApp().updateWindowTitle(
        this.ucfirst(
          `${this.ucfirst(subcategory)} | ${this.ucfirst("window-title")}`
        )
      );
      this.getComponents().findById("grid-subcategories").first().getMenu();
      this.scrollToTop();
    }
  }
}
