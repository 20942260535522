import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  Container,
  Box,
  CardMedia,
  Typography,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Grid,
  Link,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  IconButton,
  Tabs,
  Tab,
  Fade,
  useMediaQuery,
  CardActionArea,
  Card,
  Dialog,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import RequestInfo from "../../../../Components/Widgets/Dialogs/RequestInfo";
import Zoom from "../../../../Components/Widgets/Zoom";
import Translate from "../../../../../modules/Core/Components/Translate";
import CloseIcon from "@mui/icons-material/Close";

const BpIcon = styled("span")(({ theme }) => ({
  width: "40px",
  height: "2px",
  backgroundColor: "#d7d8d9",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
  },
  "&:before": {
    display: "block",
    width: "12px",
    height: "12px",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  width: "40px",
  height: "2px",
  backgroundColor: "#000",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
  },
}));

function MyRadio(props) {
  return (
    <Radio
      sx={{
        px: "2px",
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const MyFormControl = styled(FormControl)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#28282B",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#28282B",
    borderRadius: "12px",
  },
  "& .Mui-error fieldset": {
    borderColor: "#d32f2f !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#28282B",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#28282B",
      borderRadius: "12px",
    },
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
    m: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase-root": { padding: 0 },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <i
        className="fa-solid fa-chevron-right"
        style={{ fontSize: "18px", color: "#000" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  border: "none",
  p: 0,
  fontWeight: 500,
  fontSize: "18px",
  color: "#000",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    border: "none",
    p: 0,
  },
  "& .MuiAccordionSummary-content": {
    border: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

const BackNextButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "50%",
  backgroundColor: "#fff",
  color: "#000",
  fontSize: "20px",
  zIndex: 1,
  width: "35px",
  height: "35px",
  justifyContent: "center",
  alignItems: "center",
  "&:disabled": {
    pointerEvents: "all",
    backgroundColor: "#fff",
    color: "#000",
    opacity: "0.5",
  },
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

const Details = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#4a4a4a",
  "& i": {
    marginRight: "8px",
    color: "#000",
  },
}));

const LinkContact = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#ff6363",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  fontSize: "18px",
  cursor: "pointer",
  "& i": {
    minWidth: 35,
    fontSize: "20px",
  },
  "& div": {
    textAlign: "center",
    display: "inline-flex",
  },
  "&:hover": {
    color: "#ff6363",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding: "0px 8px 8px",
    margin: "0px 4px",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function FirstSection(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const view = useMediaQuery(theme.breakpoints.up("md"));
  const [requestInfoOpen, setRequestInfoOpen] = React.useState(false);

  const [activeImage, setActiveImage] = React.useState(0);

  const {
    images = [],
    pdfs = [],
    filteredMaterials = {},
    wishlist = false,
    ready = false,
    product = null,
    catalogues = [],
  } = component.getData("default", {});

  const {
    slug = null,
    code = null,
    name = null,
    group = null,
    price = null,
    first_price = null,
    shipping_days = null,
    type = "",
    brand = "",
    collection = "",
    designer = "",
    materials = [],
    combinations = [],
  } = devHelper.getObjectValue(product, "_values", {});

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    boolean
      ? component.addToWishList(slug)
      : component.removeFromWishList(slug);

    component.setData("default.wishlist", true);
  };

  //Change slider image
  const handleNext = () => {
    setActiveImage((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveImage((prevActiveStep) => prevActiveStep - 1);
  };

  const handleImageChange = (step) => {
    setActiveImage(step);
  };

  const handleImageChange2 = (event, newValue) => {
    setActiveImage(newValue);
  };

  //css
  const main_title = {
    color: { xs: "#545454", md: "#000" },
    fontWeight: { xs: 400, md: 500 },
    fontSize: 18,
  };

  const title = {
    fontSize: 16,
    color: { xs: "#545454", md: "#000" },
    fontWeight: { xs: 400, md: 500 },
    minWidth: { xs: "unset", md: "200px" },
    display: { xs: "block", md: "inline-block" },
    mb: { xs: "2px", md: 0 },
  };

  const subtitle = {
    mb: { xs: 1, md: 0 },
    fontSize: { xs: 18, md: 16 },
    color: "#313131",
    fontWeight: { xs: 500, md: 400 },
    display: { xs: "inline-block", md: "inline-block" },
  };

  React.useEffect(() => {
    return () => {
      localStorage.setItem("scrollProduct", window.location.pathname);
    };
  }, []);

  const [open, setOpen] = React.useState(false);
  const [selectedVideo, setSelectedVideo] = React.useState(null);

  // Open the Dialog and set the selected video
  const handleClickOpen = (videoId) => {
    setSelectedVideo(videoId);
    setOpen(true);
  };

  // Close the Dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedVideo(null);
  };

  return (
    <Container maxWidth="lg" disableGutters sx={{ my: 2 }}>
      <Grid
        container
        direction="row"
        sx={{
          alignItems: "stretch",
        }}
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ xs: 0, md: 2 }}
      >
        {/* images section */}
        <Grid item xs={12} md={8}>
          <Fade in={ready}>
            <Box>
              <Box sx={{ position: "relative" }}>
                {images.length !== 0 && (
                  <>
                    <BackNextButton
                      sx={{
                        left: 16,
                        display: { xs: "none", md: "flex" },
                      }}
                      onClick={handleBack}
                      disabled={activeImage === 0}
                    >
                      <i className="fa-solid fa-chevron-left" />
                    </BackNextButton>
                    <BackNextButton
                      sx={{
                        right: 16,
                        display: { xs: "none", md: "flex" },
                      }}
                      onClick={handleNext}
                      disabled={activeImage === images.length - 1}
                    >
                      <i className="fa-solid fa-chevron-right" />
                    </BackNextButton>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                        width: "100%",
                        textAlign: "center",
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <RadioGroup
                        row
                        value={activeImage}
                        sx={{ display: "block" }}
                      >
                        {images.map((image, index) => (
                          <FormControlLabel
                            key={`radio-btn-${index}`}
                            value={index}
                            control={<MyRadio />}
                            onClick={(e) => {
                              handleImageChange(index);
                            }}
                            sx={{ m: 0 }}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </>
                )}
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeImage}
                  onChangeIndex={handleImageChange}
                  enableMouseEvents
                >
                  {images.map((image, index) => (
                    <CardMedia
                      key={"product-img-" + index}
                      sx={{
                        maxWidth: "100%",
                        objectFit: "contain",
                        cursor: "pointer",
                        height: { xs: "250px", sm: "350px", md: "630px" },
                        border: { xs: "unset", md: "1px solid #fff" },
                      }}
                      component="img"
                      image={filesUrl.concat(
                        `/products/${devHelper.getObjectValue(
                          brand,
                          "item._values.slug"
                        )}/images/${group}/${image}`
                      )}
                      alt={`${name} image`}
                      onLoad={() => {
                        component.setData({ "default.ready": true });
                      }}
                      onError={() => {
                        component.setData({ "default.ready": true });
                      }}
                      onClick={() => component.toggleZoom(images, activeImage)}
                    />
                  ))}
                </SwipeableViews>
              </Box>
              {images.length !== 0 && view && (
                <Tabs
                  value={activeImage}
                  onChange={handleImageChange2}
                  variant="scrollable"
                  scrollButtons={false}
                  sx={{
                    display: "flex",
                    padding: "4px 0px",
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#f05d32 !important",
                      height: "2px",
                    },
                    "& .MuiTabScrollButton-root": {
                      color: "#f05d32",
                    },
                    "& .MuiSvgIcon-fontSizeSmall": {
                      fontSize: "40px",
                    },
                  }}
                >
                  {images.map((image, index) => (
                    <StyledTab
                      key={"product-img-" + index}
                      disableRipple
                      label={
                        <CardMedia
                          sx={{
                            width: { xs: "150px" },
                            height: { xs: "73.2px" },
                            objectFit: "contain",
                          }}
                          component="img"
                          image={filesUrl.concat(
                            `/products/${devHelper.getObjectValue(
                              brand,
                              "item._values.slug"
                            )}/images/${group}/${image}`
                          )}
                          alt={`${name} image`}
                        />
                      }
                    ></StyledTab>
                  ))}
                </Tabs>
              )}
            </Box>
          </Fade>
        </Grid>
        {/* product info section */}
        <Grid item xs={12} md={4}>
          {product && (
            <Box
              sx={{
                p: 2,
                height: "100%",
                borderRadius: { xs: 0, md: "12px" },
                bgcolor: "#f7f8fa",
                border: "1px solid #e4e5e7",
                position: "relative",
              }}
            >
              <Box sx={{ position: "absolute", top: 16, right: 16 }}>
                <IconButton
                  disableRipple
                  onClick={(e) => {
                    updateWishList(!wishlist);
                  }}
                  sx={{ color: "#000", fontSize: 20 }}
                >
                  <i
                    className={
                      wishlist ? "fa-solid fa-heart" : "fa-light fa-heart"
                    }
                  />
                </IconButton>
                <IconButton
                  disableRipple
                  sx={{ color: "#000", fontSize: 20 }}
                  onClick={() => {
                    component.shareProduct();
                  }}
                >
                  <i className="fa-light fa-arrow-up-from-bracket" />
                </IconButton>
              </Box>
              <Link
                component={RouterLink}
                to={`/products/brand/${devHelper.getObjectValue(
                  brand,
                  "item._values.slug"
                )}`}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: { xs: 80, md: 108 },
                    borderRadius: "12px",
                    border: "1px solid #e4e5e7",
                    mb: 1.5,
                  }}
                  image={filesUrl.concat(
                    `/brands/images/square/${devHelper.getObjectValue(
                      brand,
                      "item._values.slug"
                    )}.jpeg`
                  )}
                  alt={devHelper.getObjectValue(brand, "item._values.name")}
                  title={devHelper.getObjectValue(brand, "item._values.name")}
                />
              </Link>
              <Link
                component={RouterLink}
                to={`/products/brand/${devHelper.getObjectValue(
                  brand,
                  "item._values.slug"
                )}`}
                underline="none"
                sx={{
                  fontSize: 20,
                  color: "#000",
                  fontWeight: 400,
                }}
              >
                {devHelper.getObjectValue(brand, "item._values.name")}
              </Link>
              <Typography
                component="h3"
                sx={{ fontSize: 22, color: "#000", fontWeight: 500, mb: 1 }}
              >
                {name}
              </Typography>
              {type && (
                <Typography
                  component="h3"
                  sx={{ fontSize: 16, color: "#000", fontWeight: 400, mb: 2 }}
                >
                  {component.ucfirst(
                    `${devHelper.getObjectValue(
                      type,
                      "item._values.slug"
                    )}-singular`
                  )}
                </Typography>
              )}
              {designer && (
                <Typography
                  component="h3"
                  sx={{ fontSize: 17, color: "#000", fontWeight: 400 }}
                >
                  {component.ucfirst("designer")}
                  <Link
                    sx={{ color: "#313131", ml: 1, fontWeight: 500 }}
                    underline="hover"
                    component={RouterLink}
                    to={`/products/designer/${devHelper.getObjectValue(
                      designer,
                      "item._values.slug"
                    )}`}
                  >
                    {devHelper.getObjectValue(designer, "item._values.name")}
                  </Link>
                </Typography>
              )}
              {collection && (
                <Typography
                  component="h3"
                  sx={{ fontSize: 17, color: "#000", fontWeight: 400 }}
                >
                  {component.ucfirst("collection")}
                  <Link
                    sx={{ color: "#313131", ml: 1, fontWeight: 500 }}
                    underline="hover"
                    component={RouterLink}
                    to={`/products/collection/${devHelper.getObjectValue(
                      collection,
                      "item._values.slug"
                    )}`}
                  >
                    {devHelper.getObjectValue(collection, "item._values.name")}
                  </Link>
                </Typography>
              )}
              <Box sx={{ my: 3 }}>
                {/* selects section */}
                {Object.keys(filteredMaterials).map((type, index) => (
                  <MyFormControl
                    key={type}
                    fullWidth
                    sx={{
                      my: 1,
                      bgcolor: "#fff",
                      borderRadius: "12px",
                      "&:hover": {
                        borderColor: "initial",
                      },
                    }}
                    error={component.dataExists(`error.fields.${type}`)}
                    disabled={
                      index !== 0 &&
                      component.getData(
                        `default.fields.${
                          Object.keys(filteredMaterials)[index - 1]
                        }`,
                        ""
                      ) === ""
                    }
                  >
                    <InputLabel>{`${component.ucfirst(type)}`}</InputLabel>
                    <Select
                      value={component.getData(`default.fields.${type}`, "")}
                      label={`${component.ucfirst(type)}`}
                      onChange={(e) => {
                        component.setData({
                          [`default.fields.${type}`]: e.target.value,
                        });
                        component.deleteData(`error.fields.${type}`);
                        if (type !== "size") {
                          component.updateSelects(
                            product,
                            combinations,
                            materials,
                            index
                          );
                        } else {
                          component.setSize(
                            product,
                            combinations,
                            e.target.value
                          );
                        }
                      }}
                      sx={{
                        "&:disabled": {
                          borderColor: "red",
                        },
                      }}
                    >
                      {filteredMaterials[type].map((material) => {
                        const { slug, name } = material;
                        return (
                          <MenuItem value={slug} key={slug}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </MyFormControl>
                ))}
              </Box>

              {price > 0 && first_price > 0 && first_price !== price && (
                <Typography
                  component="h3"
                  sx={{
                    fontSize: 16,
                    color: "#777",
                    fontWeight: 400,
                  }}
                >
                  <del>{component.getApp().priceFormater(first_price)}</del>
                </Typography>
              )}
              {price > 0 && (
                <>
                  <Typography
                    component="h3"
                    sx={{
                      fontSize: 30,
                      color: "#ff6363",
                      fontWeight: 500,
                    }}
                  >
                    {component.getApp().priceFormater(price)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#9b9b9b",
                      fontWeight: 400,
                    }}
                  >
                    {component.trans("vat-included")}
                  </Typography>
                </>
              )}
              <Button
                sx={{
                  mt: 2,
                  borderRadius: "8px",
                  color: "#fff",
                  backgroundColor: price ? "#ff6363" : "rgb(251, 191, 105,1)",
                  fontSize: { xs: 14, md: 18 },
                  py: 1,
                  "&:hover": {
                    backgroundColor: price ? "#ff6363" : "rgb(251, 191, 105,1)",
                    opacity: 0.9,
                  },
                }}
                fullWidth
                onClick={
                  price
                    ? () => {
                        component.updateCart(
                          product,
                          "combinations.code",
                          code,
                          1
                        );
                      }
                    : () => {
                        component.RequestInfo(product);
                        setRequestInfoOpen(true);
                      }
                }
              >
                {price
                  ? component.trans("add-to-cart-btn")
                  : component.trans("request-info-btn")}
              </Button>
              {price !== 0 && (
                <Details component="h5" sx={{ mt: 3 }}>
                  <i className="fa-solid fa-check" />
                  {`${component.trans("delivery-time")} ${
                    shipping_days
                      ? shipping_days
                      : component.trans("delivery-time-days")
                  } ${component.trans("days")}`}
                </Details>
              )}
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "12px",
                  bgcolor: "#fff",
                  border: "1px solid #e4e5e7",
                  p: 2,
                  maxWidth: "300px",
                  mx: "auto",
                  mt: 4,
                }}
              >
                <Typography
                  component="h6"
                  sx={{
                    fontSize: 16,
                    color: "#000",
                    fontWeight: 500,
                    mb: "4px",
                  }}
                >
                  {component.ucfirst("contact-us-title")}
                </Typography>
                <LinkContact href={"tel:" + component.trans("phone-number")}>
                  <Box>
                    <i className="fa-light fa-phone" />
                  </Box>
                  {component.trans("phone-number")}
                </LinkContact>
              </Box>
            </Box>
          )}
        </Grid>
        {/* overview section */}
        {product && (
          <Grid item xs={12} md={8}>
            <Box sx={{ mx: { xs: 1, md: 2 } }}>
              <Box
                sx={{
                  mb: 2,
                  px: { xs: 0, md: 2 },
                }}
              >
                <Typography sx={{ ...main_title, ...{ mb: 2 } }}>
                  {component.ucfirst("οverview")}
                </Typography>
                <Typography
                  component="h1"
                  sx={{ fontWeight: 500, fontSize: 20 }}
                >
                  {`${name} | ${component.ucfirst(
                    `${devHelper.getObjectValue(
                      type,
                      "item._values.slug"
                    )}-singular`
                  )} By `}
                  <Link
                    component={RouterLink}
                    to={`/products/brand/${devHelper.getObjectValue(
                      brand,
                      "item._values.slug"
                    )}`}
                    underline="hover"
                    sx={{
                      color: "#313131",
                    }}
                  >
                    {devHelper.getObjectValue(brand, "item._values.name")}
                  </Link>
                </Typography>
              </Box>
              <Box
                sx={{
                  mb: 2,
                  py: 2,
                  px: { xs: 0, md: 2 },
                  borderBottom: "1px solid #e4e5e7",
                  borderTop: "1px solid #e4e5e7",
                }}
              >
                {collection && (
                  <Box sx={{ my: 0.5 }}>
                    <Typography component="h3" sx={title}>
                      {component.ucfirst("collection")}
                    </Typography>
                    <Link
                      sx={subtitle}
                      underline="hover"
                      component={RouterLink}
                      to={`/products/collection/${devHelper.getObjectValue(
                        collection,
                        "item._values.slug"
                      )}`}
                    >
                      {devHelper.getObjectValue(
                        collection,
                        "item._values.name"
                      )}
                    </Link>
                  </Box>
                )}
                {type && (
                  <Box sx={{ my: 0.5 }}>
                    <Typography component="h3" sx={title}>
                      {component.ucfirst("type")}
                    </Typography>
                    <Link
                      sx={subtitle}
                      underline="hover"
                      component={RouterLink}
                      to={`/products/type/${devHelper.getObjectValue(
                        type,
                        "item._values.slug"
                      )}`}
                    >
                      {component.ucfirst(
                        `${devHelper.getObjectValue(
                          type,
                          "item._values.slug"
                        )}-singular`
                      )}
                    </Link>
                  </Box>
                )}
                {designer && (
                  <Box sx={{ my: 0.5 }}>
                    <Typography component="h3" sx={title}>
                      {component.ucfirst("designer")}
                    </Typography>
                    <Link
                      sx={subtitle}
                      underline="hover"
                      component={RouterLink}
                      to={`/products/designer/${devHelper.getObjectValue(
                        designer,
                        "item._values.slug"
                      )}`}
                    >
                      {devHelper.getObjectValue(designer, "item._values.name")}
                    </Link>
                  </Box>
                )}
                {pdfs.map((pdf, index) => (
                  <Box sx={{ my: 0.5 }} key={`pdf-${index}`}>
                    <Typography component="h3" sx={title}>
                      {component.ucfirst("technical-details")}
                    </Typography>
                    <Link
                      sx={{
                        fontSize: { xs: 18, md: 16 },
                        color: "#313131",
                        fontWeight: { xs: 500, md: 400 },
                        display: "inline-flex",
                        alignItems: "center",
                        "& > i": {
                          fontSize: 26,
                        },
                      }}
                      underline="hover"
                      target="_blank"
                      href={filesUrl.concat(
                        `/products/${devHelper.getObjectValue(
                          brand,
                          "item._values.slug"
                        )}/pdfs/${group}/${pdf}`
                      )}
                    >
                      <i className="fa-light fa-file-pdf" />
                    </Link>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  px: { xs: 0, md: 2 },
                }}
              >
                <Typography component="h5">
                  <Translate
                    entity="item"
                    eslug={slug}
                    scope="default"
                    slug="description"
                    auto
                  />
                </Typography>
              </Box>
              {catalogues.length !== 0 && (
                <Accordion
                  elevation={0}
                  disableGutters
                  defaultExpanded
                  sx={{
                    mb: 2,
                    px: { xs: 0, md: 2 },
                  }}
                >
                  <AccordionSummary>
                    {component.ucfirst("catalogues")}
                  </AccordionSummary>
                  <AccordionDetails sx={{ fontSize: { xs: 13, md: 14 } }}>
                    <Grid
                      container
                      direction="row"
                      rowSpacing={2}
                      columnSpacing={1}
                    >
                      {catalogues.map((cat, index) => (
                        <Grid
                          item
                          xs={6}
                          sm={3}
                          key={`catalogue-${index}`}
                          sx={{ textAlign: "center" }}
                        >
                          <Fade
                            in={component.getData(
                              `default.pdf-${index}`,
                              false
                            )}
                          >
                            <Link
                              target="_blank"
                              href={filesUrl.concat(
                                `/brands/catalogues/${devHelper.getObjectValue(
                                  brand,
                                  "item._values.slug"
                                )}/${cat}.pdf`
                              )}
                              underline="none"
                            >
                              <CardMedia
                                sx={{
                                  width: "100%",
                                  mx: "auto",
                                }}
                                component="img"
                                image={filesUrl.concat(
                                  `/brands/catalogues/${devHelper.getObjectValue(
                                    brand,
                                    "item._values.slug"
                                  )}/${cat}.jpg`
                                )}
                                onLoad={() => {
                                  component.setData({
                                    [`default.pdf-${index}`]: true,
                                  });
                                }}
                                onError={() => {
                                  component.setData({
                                    [`default.pdf-${index}`]: true,
                                  });
                                }}
                                alt={`${cat} pdf`}
                              />
                              <Typography
                                component="h3"
                                sx={{ fontWeight: 500, fontSize: 14, mt: 1 }}
                              >
                                {cat}
                              </Typography>
                            </Link>
                          </Fade>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
              {devHelper.getObjectValue(product, "_values.videos", [])
                .length !== 0 && (
                <Accordion
                  elevation={0}
                  disableGutters
                  defaultExpanded
                  sx={{
                    mb: 2,
                    px: { xs: 0, md: 2 },
                  }}
                >
                  <AccordionSummary>
                    {component.ucfirst("videos")}
                  </AccordionSummary>
                  <AccordionDetails sx={{ fontSize: { xs: 13, md: 14 } }}>
                    <Grid
                      container
                      direction="row"
                      rowSpacing={2}
                      columnSpacing={1}
                    >
                      {devHelper
                        .getObjectValue(product, "_values.videos", [])
                        .map((video) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={devHelper.getObjectValue(video, "url")}
                          >
                            <Card sx={{ bgcolor: "#e4e5e7" }}>
                              <CardActionArea
                                onClick={() =>
                                  handleClickOpen(
                                    devHelper.getObjectValue(video, "url")
                                  )
                                }
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    paddingTop:
                                      "56.25%" /* 16:9 aspect ratio */,
                                    width: "100%",
                                  }}
                                >
                                  {/* Thumbnail */}
                                  <Box
                                    component="img"
                                    src={`https://img.youtube.com/vi/${devHelper.getObjectValue(
                                      video,
                                      "url"
                                    )}/0.jpg`}
                                    alt={devHelper.getObjectValue(video, "url")}
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                  />
                                  {/* Play icon overlay */}
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                      color: "#fff",
                                      fontSize: 50,
                                    }}
                                  >
                                    <i className="fa-sharp fa-light fa-circle-play" />
                                  </Box>
                                </Box>
                              </CardActionArea>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      <Zoom
        {...{
          ...props,
          ...{
            activeImage,
            group,
            brand: devHelper.getObjectValue(brand, "item._values.slug"),
          },
        }}
      />
      <RequestInfo
        {...{
          ...props,
          ...{
            open: requestInfoOpen,
            setOpen: (boolean) => setRequestInfoOpen(boolean),
          },
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "black", // Set background to black
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "white",
            zIndex: 1, // Ensure it is above the iframe
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            boxSizing: "border-box",
          }}
        >
          {/* Responsive iframe */}
          <Box
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", md: "900px" }, // 100% on mobile, max 500px on desktop
              height: { xs: "unset", md: "506px" },
              position: "relative",
              paddingTop: view ? "0px" : "56.25%", // 16:9 aspect ratio
            }}
          >
            <iframe
              src={`https://www.youtube.com/embed/${selectedVideo}?autoplay=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
}
