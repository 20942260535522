import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  Link,
} from "@mui/material";

import UserSection from "../../widgets/checkout/user_section";
import AddressesSection from "../../widgets/checkout/addresses_section";
// import ShippingSection from "../../widgets/checkout/shipping_section";
import CouponsSection from "../../widgets/checkout/coupons_section";
import PaymentSection from "../../widgets/checkout/payment_section";
import SummarySection from "../../widgets/checkout/summary_section";

const MyTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "25px",
  flexGrow: 1,
}));

const MyAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "unset",
  boxShadow: "unset",
  "& .MuiAccordionSummary-root": {
    padding: "10px 0px",
  },
  "& .MuiAccordionSummary-root.Mui-expanded": {
    paddingBottom: 0,
  },
  "& .MuiAccordionSummary-root.Mui-disabled": {
    opacity: 1,
  },
  "&::before": { opacity: "0 !important" },
}));

const StepEditBtn = styled(Link)(({ theme }) => ({
  float: "right",
  color: "#000",
  fontSize: "15px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  pointerEvents: "auto",
  height: 25,
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

export default function Checkout(props) {
  const { component } = props;

  const {
    order = null,
    username,
    expanded = false,
    guest,
  } = component.getData("default", {});

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        px: { xs: 2, md: 3 },
        minHeight: "600px",
        "& .MuiAccordion-root.Mui-disabled": {
          backgroundColor: "transparent",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          paddingBottom: "20px",
        },
      }}
      maxWidth="xl"
      disableGutters
    >
      {order && (
        <Grid
          container
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          direction="row"
          spacing={4}
        >
          <Grid item md={7} xs={12}>
            <MyTitle
              variant="h3"
              gutterBottom
              sx={{
                my: 2,
              }}
            >
              {component.ucfirst("checkout-title-1")}
            </MyTitle>
            {/* Guest or signin */}
            <MyAccordion
              expanded={expanded === "step1"}
              disabled
              id="step1"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                }}
              >
                <MyTitle>
                  {expanded !== "step1" && expanded !== false
                    ? component.ucfirst("welcome") +
                      " " +
                      component.ucfirst(username) +
                      "!"
                    : component.ucfirst("checkout-title-2")}
                </MyTitle>
                {expanded !== "step1" && expanded !== false && guest && (
                  <StepEditBtn
                    underline="none"
                    onClick={() =>
                      component.setData({ "default.expanded": "step1" })
                    }
                  >
                    {component.ucfirst("sign-in")}
                  </StepEditBtn>
                )}
              </AccordionSummary>
              <UserSection {...props} />
            </MyAccordion>
            {/* Addresses */}
            <MyAccordion
              expanded={expanded === "step2"}
              disabled
              id="step2"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                }}
              >
                <MyTitle>{component.ucfirst("checkout-title-3")}</MyTitle>
                {(expanded === "step3" || expanded === "step4") && (
                  <StepEditBtn
                    underline="none"
                    onClick={() =>
                      component.setData({ "default.expanded": "step2" })
                    }
                  >
                    {component.ucfirst("edit-btn")}
                  </StepEditBtn>
                )}
              </AccordionSummary>
              <AddressesSection {...props} />
            </MyAccordion>
            {/* shipping method */}
            {/* <MyAccordion
              expanded={expanded === "step3"}
              disabled
              id="step3"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "20px 0px",
                }}
              >
                <MyTitle>{component.ucfirst("checkout-title-4")}</MyTitle>
                {expanded === "step4" && (
                  <StepEditBtn
                    underline="none"
                    onClick={() =>
                      component.setData({ "default.expanded": "step3" })
                    }
                  >
                    {component.ucfirst("edit-btn")}
                  </StepEditBtn>
                )}
              </AccordionSummary>
              <ShippingSection {...props} />
            </MyAccordion> */}
            {/* billing & payment */}
            <MyAccordion
              expanded={expanded === "step3"}
              disabled
              id="step3"
              disableGutters
            >
              <AccordionSummary
                sx={{
                  borderTop: "1px solid rgba(0,0,0,.1)",
                  padding: "20px 0px",
                }}
              >
                <MyTitle>{component.ucfirst("checkout-title-5")}</MyTitle>
              </AccordionSummary>

              <CouponsSection {...props} />
              <PaymentSection {...props} />
            </MyAccordion>
          </Grid>
          {/* order summary */}
          <Grid item md={5} xs={12}>
            <SummarySection {...props} />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
