import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  SwipeableDrawer,
  List,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import ProductCartView from "../../../../Components/Widgets/ProductCartView";
import CloseIcon from "@mui/icons-material/Close";

const StyledDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    height: "100%",
    width: 500,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 500px)": { width: "100%" },
  },
}));

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const ShowCartBtn = styled(Button)(({ theme }) => ({
  background: "#fff",
  borderBottom: "1px solid #000",
  borderRadius: 0,
  color: "#000",
  padding: "8px 14px",
  width: "200px",
  fontWeight: 400,
  "&:hover, &:focus": {
    borderBottom: "1px solid transparent",
    background: "transparent",
  },
}));

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0px",
  borderBottom: "none",
  fontSize: 16,
}));

const MyTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: "1px solid rgba(0,0,0,.1)",
  verticalAlign: "top",
}));

export default function CartDrawer(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const order = component.getData("default.order", null);
  return (
    <StyledDrawer
      anchor="right"
      open={component.getData("default.opened", false)}
      onClose={() => component.toggle()}
      onOpen={() => {}}
      transitionDuration={{ enter: 500, exit: 500 }}
      swipeAreaWidth={0}
      sx={{
        ".MuiDrawer-paper": { p: { xs: 1, md: 2 }, alignItems: "unset" },
      }}
    >
      <IconButton
        onClick={() => component.toggle()}
        style={{
          position: "absolute",
          left: 15,
          top: 5,
          color: "#000",
        }}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        align="center"
        sx={{ fontSize: "30px", fontWeight: 600, color: "#0E2431" }}
      >
        {component.ucfirst("cart")}
      </Typography>
      {!order ? (
        <CircularProgress
          size={30}
          sx={{
            my: 20,
            color: "#000",
          }}
        />
      ) : (
        <>
          {order.items && order.items.length !== 0 ? (
            <>
              <List
                sx={{
                  width: "100%",
                }}
              >
                <Table>
                  <TableHead>
                    <MyTableRow>
                      <MyTableCell
                        sx={{
                          textTransform: "uppercase",
                        }}
                      >
                        {component.trans("item")}
                      </MyTableCell>
                      <MyTableCell
                        sx={{
                          display: "none",
                        }}
                        align="center"
                      >
                        {component.trans("qty")}
                      </MyTableCell>
                      <MyTableCell
                        sx={{
                          display: "none",
                        }}
                        align="right"
                      >
                        {component.trans("total")}
                      </MyTableCell>
                    </MyTableRow>
                  </TableHead>
                  <TableBody>
                    {order.items.map((product) => (
                      <ProductCartView
                        key={devHelper.getObjectValue(product, "sku")}
                        {...{
                          ...props,
                          ...{
                            product,
                            drawer: true,
                          },
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody style={{ float: "right" }}>
                    <MyTableRow
                      sx={{
                        borderBottom: "none",
                      }}
                    >
                      <MyTableCell
                        sx={{
                          width: 70,
                          fontSize: 16,
                          textTransform: "uppercase",
                          fontWeight: 500,
                        }}
                      >
                        {component.trans("total")}
                      </MyTableCell>
                      <MyTableCell
                        sx={{ fontSize: 16, color: "#ff6363", fontWeight: 500 }}
                      >
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.items")
                          )}
                      </MyTableCell>
                    </MyTableRow>
                  </TableBody>
                </Table>
              </List>
              <Typography
                align="right"
                sx={{
                  fontSize: 12,
                  color: "#9b9b9b",
                  fontWeight: 400,
                  mt: -2.5,
                }}
              >
                {component.trans("vat-included")}
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <ShowCartBtn
                  component={RouterLink}
                  to="/cart"
                  onClick={() => component.toggle()}
                >
                  {component.trans("view-cart")}
                </ShowCartBtn>
                <MyButton
                  component={RouterLink}
                  to="/checkout"
                  fullWidth
                  onClick={() => component.toggle()}
                >
                  {component.trans("checkout")}
                </MyButton>
              </Box>
            </>
          ) : (
            <Typography align="center" sx={{ my: 20, height: "30px" }}>
              {component.trans("empty-cart-message")}
            </Typography>
          )}
        </>
      )}
    </StyledDrawer>
  );
}
