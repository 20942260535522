import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Box,
  Typography,
  List,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from "@mui/material";
import ProductCartView from "../../../../Components/Widgets/ProductCartView";

const SubTitle = styled(Typography)(({ theme }) => ({
  margin: "20px 0px",
  fontWeight: 400,
  fontSize: 20,
  border: "1px solid #000",
  fontFamily: "founders-grotesk,Helvetica,sans-serif",
  textAlign: "center",
  padding: 10,
}));

const InnerTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  fontFamily: "founders-grotesk,Helvetica,sans-serif",
  textAlign: "center",
}));

const MyTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0px",
  borderBottom: "none",
  fontSize: 16,
  fontWeight: 500,
  textTransform: "uppercase",
}));

export default function Order(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const order = component.getData("default.order", null);

  const months = [
    component.ucfirst("jan"),
    component.ucfirst("feb"),
    component.ucfirst("mar"),
    component.ucfirst("apr"),
    component.ucfirst("may"),
    component.ucfirst("jun"),
    component.ucfirst("jul"),
    component.ucfirst("aug"),
    component.ucfirst("sep"),
    component.ucfirst("oct"),
    component.ucfirst("nov"),
    component.ucfirst("dec"),
  ];
  const days = [
    component.ucfirst("sunday"),
    component.ucfirst("monday"),
    component.ucfirst("tuesday"),
    component.ucfirst("wednesday"),
    component.ucfirst("thursday"),
    component.ucfirst("friday"),
    component.ucfirst("saturday"),
  ];

  var date = null,
    time = null;
  if (order) {
    date = devHelper
      .getObjectValue(order, "_updated_at")
      .split(" ")
      .splice(0, 1)
      .join("");

    time = devHelper
      .getObjectValue(order, "_updated_at")
      .split(" ")
      .splice(-1)
      .join("");
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        px: { xs: 2, md: 3 },
      }}
      maxWidth="xl"
      disableGutters
    >
      {!order ? (
        <CircularProgress
          style={{
            margin: "80px 0",
            color: "#000",
          }}
        />
      ) : (
        <Grid
          container
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          direction="row"
          spacing={4}
        >
          <Grid item md={7} xs={12}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
                lineHeight: 1,
                my: 2,
              }}
            >
              {component.ucfirst("order")}{" "}
              {devHelper.getObjectValue(order, "_id").slice(-8)}
            </Typography>
            <MyTypography variant="subtitle1">
              {`${days[new Date(date).getUTCDay()]}, ${
                months[new Date(date).getMonth()]
              } ${new Date(date).getDate()} ${new Date(
                date
              ).getFullYear()} ${time}`}
            </MyTypography>
            <MyTypography variant="subtitle1">
              <b> {component.ucfirst("items")}:</b>{" "}
              {devHelper.getObjectValue(order, "amounts.items")}
            </MyTypography>
            <MyTypography variant="subtitle1">
              <b> {component.ucfirst("status")}:</b>{" "}
              {component.ucfirst(devHelper.getObjectValue(order, "_status"))}
            </MyTypography>
            <SubTitle variant="h3" gutterBottom>
              {component.ucfirst("shipping-information-title")}
            </SubTitle>
            <Grid container justifycontent="center" spacing={4}>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("shipping-address")}
                </InnerTitle>
                <div style={{ textAlign: "center" }}>
                  {component.ucfirst(
                    devHelper.getObjectValue(
                      order,
                      "shipping_address.first_name"
                    )
                  )}{" "}
                  {component.ucfirst(
                    devHelper.getObjectValue(
                      order,
                      "shipping_address.last_name"
                    )
                  )}
                  <br />
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.address")
                  )}
                  <br />
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.phone")
                  )}
                  <br />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("shipping-method")}
                </InnerTitle>
                {order.services
                  .filter((sev) => sev.selected && sev.scope === "shipping")
                  .map((service) => {
                    const { slug } = service;
                    return (
                      <div key={slug} style={{ textAlign: "center" }}>
                        {component.ucfirst(slug)}
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
            <SubTitle variant="h3" gutterBottom>
              {component.ucfirst("billing-information-title")}
            </SubTitle>
            <Grid container justifycontent="center" spacing={4}>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("billing-address")}
                </InnerTitle>
                <div style={{ textAlign: "center" }}>
                  {component.ucfirst(
                    devHelper.getObjectValue(
                      order,
                      "billing_address.first_name"
                    )
                  )}{" "}
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "billing_address.last_name")
                  )}
                  <br />
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "billing_address.address")
                  )}
                  <br />
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "billing_address.phone")
                  )}
                  <br />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("payment-method")}
                </InnerTitle>
                <div style={{ textAlign: "center" }}>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "paymentType.slug")
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* order summary */}
          <Grid item md={5} xs={12}>
            <Paper
              square
              elevation={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                py: 2,
                px: 3,
              }}
            >
              <Box style={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "20px", sm: "24px", md: "28px" },
                    lineHeight: 1,
                    mb: 2,
                  }}
                >
                  {component.ucfirst("checkout-title-6")}
                </Typography>
                {order && order.items.length !== 0 && (
                  <React.Fragment>
                    <List>
                      <Table>
                        <TableHead>
                          <TableRow
                            sx={{
                              borderBottom: "1px solid rgba(0,0,0,.1)",
                              verticalAlign: "top",
                            }}
                          >
                            <MyTableCell
                              sx={{
                                textTransform: "uppercase",
                              }}
                            >
                              {component.trans("item")}
                            </MyTableCell>
                            <MyTableCell
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "table-cell",
                                  md: "none",
                                  lg: "table-cell",
                                },
                                textTransform: "uppercase",
                              }}
                              align="right"
                            >
                              {component.trans("total")}
                            </MyTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.items.map((product, index) => (
                            <ProductCartView
                              key={index}
                              {...{
                                ...props,
                                ...{
                                  product,
                                },
                              }}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </List>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MyTableCell>
                            {component.trans("subtotal")}
                          </MyTableCell>
                          <MyTableCell align="right">
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(order, "totals.items")
                              )}
                          </MyTableCell>
                        </TableRow>
                        {devHelper.getObjectValue(order, "totals.discount") !==
                          0 && (
                          <TableRow>
                            <MyTableCell>
                              {component.trans("discount")}
                            </MyTableCell>
                            <MyTableCell align="right">
                              {`- ${component
                                .getApp()
                                .priceFormater(
                                  devHelper.getObjectValue(
                                    order,
                                    "totals.discount"
                                  )
                                )}`}
                            </MyTableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <MyTableCell>
                            {component.trans("shipping")}
                          </MyTableCell>
                          <MyTableCell align="right">
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(
                                  order,
                                  "totals.shipping"
                                )
                              )}
                          </MyTableCell>
                        </TableRow>
                        <TableRow>
                          <MyTableCell sx={{ fontSize: 20 }}>
                            {component.trans("total")}
                          </MyTableCell>
                          <MyTableCell
                            align="right"
                            sx={{ fontSize: 20, color: "#ff6363" }}
                          >
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(order, "totals.items")
                              )}
                          </MyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </React.Fragment>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
