import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/designers/designers";
import Services from "../../Services/Services";

export default class Designers extends Component {
  id = "designers";
  pageAmount = 14;

  template = templateDefault;

  loadCollection(clear) {
    const term = this.getSearchTerm();

    const collectionAmount =
      this.getHelpers("value").getValue("collection-amount");

    if (collectionAmount) {
      this.pageAmount = parseInt(collectionAmount);
    }

    if (clear) {
      this.setData({
        "default.designers": [],
      });
    }

    this.getApp().updateWindowTitle(
      this.ucfirst(
        term
          ? `${this.ucfirst("designers")} ${decodeURIComponent(
              term
            )} | ${this.ucfirst("window-title")}`
          : `${this.ucfirst("designers")} | ${this.ucfirst("window-title")}`
      )
    );

    this.execFetchProducts();

    if (clear) {
      const saveScrollPosition = localStorage.getItem("saveScrollPosition") * 1;

      if (!saveScrollPosition) {
        window.scrollTo({ top: 0 });
      }
    }
  }

  checkSearch(e) {
    e.preventDefault();
    const { search_term } = this.getData("default", null);
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (search_term && search_term.length > 1) {
      window.location.href = devHelper + "/designers?term=" + search_term;
    }
  }

  loadFilters() {
    if (window.location.search) {
      const {
        pageAmount,
        pageNumber = 0,
        sortBy,
        gender,
        ...search
      } = this.queryParamsToObject(window.location.search);

      for (let i in search) {
        search[i] = search[i].split(",");
      }

      if (sortBy) {
        search["sortBy"] = sortBy.split(",");
      }

      this.setData({
        "default.activeFilters": search,
        "default.params": {
          pageAmount,
          pageNumber,
        },
      });
    } else {
      this.setData({
        "default.activeFilters": {},
        "default.params": {
          pageAmount: this.pageAmount,
          pageNumber: 0,
        },
      });
    }
  }

  disableFilters() {
    let { type } = this.getPage().getParams();
    const scopes = this.getPage().getQuery("scope", "").split(",");

    scopes.push(type);

    this.setData({
      "default.disabledFilters": scopes,
    });
    return this;
  }

  prepareFilters(filters) {
    const buildFilters = [];
    const disabledFilters = this.getData("default.disabledFilters", []);

    for (let type in filters) {
      const disabled = disabledFilters.find((filter) => filter === type)
        ? true
        : false;

      if (!disabled && filters[type].length) {
        buildFilters.push({
          type,
          values: filters[type],
        });
      }
    }

    return buildFilters;
  }

  allTypes() {
    this.clearFiltersAndFetch();
    return this;
  }

  showTypes() {
    const { type } = this.getPage().getParams();
    return type !== "type";
  }

  isOnType(type) {
    return this.getData("default.activeFilters.type", []).find(
      (filter) => filter === type
    )
      ? true
      : false;
  }

  sortBy(sortBy) {
    this.setSortBy(sortBy).fetchProducts();
    return this;
  }

  setSortBy(sortBy) {
    this.setData({
      "default.activeFilters.sortBy":
        typeof sortBy === "string" ? sortBy.split("-") : sortBy,
    });
    return this;
  }

  setDefaultSort() {
    const { type } = this.getPage().getParams();

    if (type) {
      this.setSortBy([type, "asc"]);
    }

    return this;
  }

  hasSortBy() {
    return this.getData("default.activeFilters.sortBy") ? true : false;
  }

  selectType(type) {
    this.clearFilters();
    this.setData({ "default.activeFilters.type": [type] });
    this.fetchProducts();
    return this;
  }

  fetchProducts(more) {
    const params = this.buildParams(more);

    this.getPage().redirect(
      [window.location.pathname, this.objectToQueryParams(params)].join("?")
    );
  }

  buildParams(more) {
    const params = this.prepareParams(more);
    const activeFilters = this.getData("default.activeFilters", {});

    if (this.getSearchTerm()) {
      params["term"] = this.getSearchTerm();
    }

    for (let type in activeFilters) {
      if (activeFilters[type]) {
        const value = activeFilters[type];

        if (value) {
          params[type] = Array.isArray(value)
            ? value.filter((value) => value).join(",")
            : value;
        }
      }
    }

    for (let key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return params;
  }

  execFetchProducts(more) {
    const params = this.buildParams(more);

    this.setData(
      { "default.priceRange.value": [], "default.loading": true },
      {
        cb: () => {
          Services.get("hook").then(async ([hookService]) => {
            const designers = await hookService.exec("designers", {
              params,
            });

            let {
              data = [],
              filters = [],
              total = 0,
            } = designers.getData() || {};

            this.setData(
              {
                "default.designers": data,
                "default.filters": this.prepareFilters(filters),
                "default.total": total,
                "default.loading": false,
              },
              {
                cb: () => {
                  const saveScrollPosition =
                    localStorage.getItem("saveScrollPosition") * 1;

                  const scrollList = localStorage.getItem("scrollList");
                  const scrollProduct = localStorage.getItem("scrollProduct");

                  if (saveScrollPosition) {
                    if (scrollList === scrollProduct) {
                      setTimeout(() => {
                        window.scrollTo(0, saveScrollPosition);
                      }, 50);
                    }
                  }

                  localStorage.removeItem("saveScrollPosition");
                  localStorage.removeItem("scrollList");
                  localStorage.removeItem("scrollProduct");
                },
              }
            );
          });
        },
      }
    );

    return this;
  }

  objectToQueryParams(obj) {
    return Object.keys(obj)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${
            Array.isArray(obj[key]) ? obj[key].join(",") : obj[key]
          }`
      )
      .join("&");
  }

  queryParamsToObject(queryString) {
    const pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    return pairs.reduce((accumulator, pair) => {
      const [key, value] = pair.split("=").map(decodeURIComponent);
      accumulator[key] = value;
      return accumulator;
    }, {});
  }

  getSearchTerm() {
    return this.getPage().getQuery("term", "");
  }

  prepareParams() {
    let pageAmount = this.pageAmount;
    let pageNumber = this.getData("default.params.pageNumber", 0);
    const { type, value } = this.getPage().getParams();

    const persistentFilters = {};

    persistentFilters[`default.activeFilters.${type}`] = [value];

    if (!this.hasSortBy()) {
      this.setDefaultSort();
    }

    this.setData(persistentFilters);

    return { pageAmount, pageNumber };
  }

  fetchMore() {
    const pageNumber = this.getData("default.params.pageNumber", 0) * 1;
    this.setData({ "default.params.pageNumber": pageNumber + 1 });
    this.fetchProducts(true);
  }

  canFetchMore() {
    const pageNumber = this.getData("default.params.pageNumber", 0) * 1;
    const total = this.getData("default.total", 0);
    return pageNumber * this.pageAmount + this.pageAmount < total;
  }

  getMoreAmount() {
    const pageNumber = this.getData("default.params.pageNumber", 0) * 1;
    const total = this.getData("default.total", 0);
    const more = total - (pageNumber * this.pageAmount + this.pageAmount);

    return more < this.pageAmount ? more : this.pageAmount;
  }

  toggleFilter(type, item) {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    const { _values = {} } = item;
    const { slug } = _values;
    const activeFilters = this.getData("default.activeFilters", {});
    let activeFilter = null;

    if (!activeFilters[type]) {
      activeFilters[type] = [];
    }

    activeFilter = activeFilters[type];

    if (activeFilter.find((filter) => filter === slug)) {
      activeFilters[type] = activeFilter.filter((filter) => filter !== slug);
    } else {
      activeFilter.push(slug);
    }

    this.setData({ "default.activeFilters": activeFilters });

    this.fetchProducts();
  }

  getFilterAmount(type, item) {
    const { _values = {} } = item;
    const filter = this.getData("default.filters", []).find(
      (filter) => filter.type === type
    );

    let count = 0;

    if (filter) {
      const { values } = filter;
      const filterValue = values.find((value) => value._id === _values.slug);

      if (filterValue) {
        count = filterValue.count;
      }
    }

    return count;
  }

  clearFilters() {
    this.setData({ "default.activeFilters": {} });
    return this;
  }

  clearFiltersAndFetch() {
    this.clearFilters().fetchProducts();
    return this;
  }

  isFilterSelected(type, item) {
    const { _values = {} } = item;
    const { slug } = _values;
    let selected = false;

    const activeFilters = this.getData("default.activeFilters", {});

    if (activeFilters[type]) {
      selected = activeFilters[type].find((filter) => filter === slug)
        ? true
        : false;
    }

    return selected;
  }

  getPriceRange(filter) {
    const { values = [] } = filter;
    const filtered = this.getData("default.priceRange.value", []);

    if (filtered.length) {
      return [filtered[0], filtered[1]];
    }

    return values.length ? [values[0].minPrice, values[0].maxPrice] : [0, 0];
  }

  changePriceRange(priceRange, doFilter) {
    this.setData({
      "default.activeFilters.price": priceRange,
      "default.priceRange.value": priceRange,
    });

    if (doFilter) {
      this.fetchProducts();
    }
  }

  typeIsVisible(type) {
    const { _values = {} } = type;
    const { values = [] } =
      this.getData("default.filters", []).find(({ type }) => type === "type") ||
      {};

    return values.find(({ _id }) => _id === _values.slug);
  }
}
