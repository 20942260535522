import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import ProductCartView from "../../../../Components/Widgets/ProductCartView";

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0px",
  borderBottom: "none",
  fontSize: 16,
  fontWeight: 500,
  textTransform: "uppercase",
}));

export default function SummarySection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { order = null } = component.getData("default", {});

  return (
    <>
      {order && (
        <Paper
          square
          elevation={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
            px: 3,
          }}
        >
          <Box style={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
                lineHeight: 1,
                mb: 2,
              }}
            >
              {component.ucfirst("checkout-title-6")}
            </Typography>
            {order && order.items.length !== 0 && (
              <>
                <List>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          borderBottom: "1px solid rgba(0,0,0,.1)",
                          verticalAlign: "top",
                        }}
                      >
                        <MyTableCell>{component.trans("item")}</MyTableCell>
                        <MyTableCell align="right">
                          {component.trans("total")}
                        </MyTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.items.map((product, index) => (
                        <ProductCartView
                          key={devHelper.getObjectValue(product, "sku")}
                          {...{
                            ...props,
                            ...{
                              product,
                              checkout: true,
                            },
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </List>
                <Table>
                  <TableBody>
                    <TableRow>
                      <MyTableCell>{component.trans("subtotal")}</MyTableCell>
                      <MyTableCell align="right">
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.items")
                          )}
                      </MyTableCell>
                    </TableRow>
                    {devHelper.getObjectValue(order, "totals.discount") !==
                      0 && (
                      <TableRow>
                        <MyTableCell>{component.trans("discount")}</MyTableCell>
                        <MyTableCell align="right">
                          {`- ${component
                            .getApp()
                            .priceFormater(
                              devHelper.getObjectValue(order, "totals.discount")
                            )}`}
                        </MyTableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <MyTableCell>
                        {component.trans("shipping-text")}
                      </MyTableCell>
                      <MyTableCell align="right">
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.shipping")
                          )}
                      </MyTableCell>
                    </TableRow>
                    <TableRow>
                      <MyTableCell sx={{ fontSize: 20 }}>
                        {component.trans("total")}
                      </MyTableCell>
                      <MyTableCell
                        align="right"
                        sx={{ fontSize: 20, color: "#ff6363" }}
                      >
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.items")
                          )}
                      </MyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography
                  align="right"
                  sx={{
                    fontSize: 12,
                    color: "#9b9b9b",
                    fontWeight: 400,
                    mt: -2,
                  }}
                >
                  {component.trans("vat-included")}
                </Typography>
              </>
            )}
          </Box>
        </Paper>
      )}
    </>
  );
}
