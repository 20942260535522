import React from "react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  Link,
  Box,
  Badge,
  Button,
  IconButton,
  InputBase,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LanguageSelect from "../../../../Components/Widgets/LanguageSelect";

const MyLink = styled(Link)(({ theme }) => ({
  color: "#000",
  padding: "12px",
  backgroundColor: "transparent",
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "16px",
  fontWeight: 500,
  borderBottom: "1px solid #e4e5e7",
  "& i": {
    minWidth: 30,
    fontSize: "20px",
  },
  "& div": {
    textAlign: "center",
    display: "inline-flex",
    marginRight: "12px",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 2,
    color: "#fff",
    backgroundColor: "#000",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "30px",
  margin: "8px auto",
  display: "block",
  backgroundColor: "#f7f8fa",
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#000 !important",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#777",
  width: "100%",
  "& .MuiInputBase-input": {
    color: "#000",
    padding: "12px 8px 12px 0px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export default function MenuDrawer(props) {
  const { component } = props;

  const wishlist = component.getData("default.wishlist", []);
  const isAuth = component.isAuthenticated();
  const mainMenu = component.getData("default.mainMenu", []);
  return (
    <SwipeableDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => component.toggle()}
      onOpen={() => {}}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        "& .MuiDrawer-paper": {
          p: 0,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: { xs: "90%", sm: "500px" },
        },
        "& .MuiDrawer-paper::-webkit-scrollbar": {
          display: "none",
        },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
      swipeAreaWidth={0}
    >
      <Box
        sx={{
          width: "100%",
          position: "relative",
          mt: 1,
          pt: 1,
          px: 3,
        }}
      >
        <IconButton
          onClick={() => component.toggle()}
          style={{
            position: "absolute",
            right: 24,
            top: 0,
            fontSize: 22,
            color: "#000",
          }}
        >
          <i className="fa-light fa-xmark" />
        </IconButton>
        <Box sx={{ textAlign: "center" }}>
          <LanguageSelect {...props} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <form onSubmit={(e) => component.checkSearch(e)} method="get">
            <Search
              sx={{
                width: { md: "500px", lg: "600px" },
              }}
            >
              <SearchIconWrapper type="submit" disableRipple>
                <i className="fa-light fa-magnifying-glass" />
              </SearchIconWrapper>
              <StyledInputBase
                value={component.getData("default.search_term", "")}
                onChange={(e) => {
                  component.setData({
                    "default.search_term": e.target.value,
                  });
                }}
                placeholder={component.ucfirst("search-holder")}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </form>
        </Box>
      </Box>
      <Box sx={{ px: 2, py: 1 }}>
        {mainMenu.map((menu, index) => {
          const {
            slug,
            menu_template,
            menu_types = [],
            url,
            color,
            icon,
          } = menu;
          if (slug !== "shop" && slug !== "event") {
            return (
              <MyLink
                key={`mobile-main-menu-root-${slug}`}
                underline="none"
                disabled={menu_types.length !== 0}
                onClick={() => {
                  menu_types.length
                    ? component.toggleSubMenuDrawer(menu_template)
                    : component.toggle();
                  menu_types.length === 0 && component.getPage().redirect(url);
                }}
                sx={{
                  "& i": {
                    color: slug === "new" ? color : "#000",
                  },
                }}
              >
                <span>
                  <Box>
                    <i className={icon} />
                  </Box>
                  {component.ucfirst(slug)}
                </span>
                <i
                  className="fa-solid fa-chevron-right"
                  style={{
                    fontSize: "16px",
                    visibility: menu_types.length ? "visible" : "hidden",
                  }}
                />
              </MyLink>
            );
          } else {
            return (
              <Button
                key={`mobile-main-menu-root-${slug}`}
                to={url}
                component={RouterLink}
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  textTransform: "none",
                  fontSize: "16px",
                  p: "12px 8px",
                  bgcolor: color,
                  borderRadius: 0,
                  width: "100%",
                  my: 1,
                  mb: 3,
                  "&:hover,&:focus": {
                    bgcolor: color,
                    opacity: 0.9,
                  },
                }}
                onClick={() => component.toggle()}
                disableRipple
              >
                {component.trans(slug)}
              </Button>
            );
          }
        })}

        {isAuth && (
          <>
            <MyLink underline="none">
              <span>
                <Box>
                  <i className="fa-light fa-user" />
                </Box>
                {component.ucfirst("my-account")}
              </span>
              <i
                className="fa-solid fa-chevron-down"
                style={{ fontSize: "16px" }}
              />
            </MyLink>
            <MyLink
              underline="none"
              component={RouterLink}
              to="profile"
              onClick={() => component.toggle()}
              sx={{ pl: "75px" }}
            >
              {component.ucfirst("profile-title")}
            </MyLink>
            <MyLink
              underline="none"
              component={RouterLink}
              to="/addresses"
              onClick={() => component.toggle()}
              sx={{ pl: "75px" }}
            >
              {component.ucfirst("addresses-title")}
            </MyLink>
            <MyLink
              underline="none"
              component={RouterLink}
              to="/orders"
              onClick={() => component.toggle()}
              sx={{ pl: "75px" }}
            >
              {component.ucfirst("orders")}
            </MyLink>
            <MyLink
              underline="none"
              component={RouterLink}
              to="/coupons"
              onClick={() => component.toggle()}
              sx={{ pl: "75px" }}
            >
              {component.ucfirst("coupons-title")}
            </MyLink>
          </>
        )}
        <MyLink
          underline="none"
          component={RouterLink}
          to="/wishlist"
          onClick={() => component.toggle()}
        >
          <span>
            <Box>
              <StyledBadge badgeContent={wishlist.length}>
                <i className="fa-light fa-heart" />
              </StyledBadge>
            </Box>
            {component.ucfirst("wishlist")}
          </span>
        </MyLink>
        {isAuth ? (
          <MyLink underline="none" onClick={() => component.getPage().logout()}>
            <span>
              <Box>
                <i className="fa-light fa-right-from-bracket" />
              </Box>
              {component.ucfirst("logout")}
            </span>
          </MyLink>
        ) : (
          <MyLink
            underline="none"
            onClick={() => {
              component.toggle();
              component
                .getComponents()
                .findById("login-register-dialog")
                .first()
                .setData({
                  "default.open": true,
                });
            }}
          >
            <span>
              <Box>
                <i className="fa-light fa-right-to-bracket" />
              </Box>
              {component.ucfirst("sign-in")}
            </span>
          </MyLink>
        )}
      </Box>
    </SwipeableDrawer>
  );
}
