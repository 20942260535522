import React, { useLayoutEffect, useState, useRef } from "react";
import { Grid, Stack, Skeleton } from "@mui/material";

export default function Poduct_List_Skeleton_View(props) {
  const { grid = 3 } = props;

  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        if (targetRef.current) {
          setHeight(732 * (targetRef.current.clientWidth / 732));
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }

  const img_height = SetImageHeight();

  return (
    <Grid item md={grid} sm={6} xs={6} justifycontent="center" ref={targetRef}>
      <Skeleton variant="rectangular" width="100%" height={img_height} />
      <Stack
        spacing={0}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        sx={{ px: 2, py: 1 }}
      >
        <Skeleton variant="text" height={20} width={100} />
        <Skeleton variant="text" height={20} width={160} />
        <Skeleton variant="text" height={20} width={120} />
      </Stack>
    </Grid>
  );
}
