import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Menu,
  Button,
  MenuItem,
  InputBase,
  Badge,
  Fade,
  Container,
  // Link,
} from "@mui/material";
import MainMenu from "../../../../Components/Widgets/MainMenu";
import LanguageSelect from "../../../../Components/Widgets/LanguageSelect";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "30px",
  margin: "8px auto",
  display: "block",
  backgroundColor: "#f7f8fa",
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#000 !important",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#777",
  width: "100%",
  "& .MuiInputBase-input": {
    color: "#000",
    padding: "12px 8px 12px 0px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const IconsButton = styled(Button)(({ theme }) => ({
  color: "#000",
  fontSize: 22,
  backgroundColor: "transparent",
  marginRight: 5,
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 40,
  "&:hover": {
    backgroundColor: "transparent",
    opacity: 0.6,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": { color: "#fff", backgroundColor: "#000" },
}));

const MyMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "#000",
  backgroundColor: "transparent",
  "&:hover,&:focus": {
    backgroundColor: "transparent",
    opacity: 0.6,
  },
}));

export default function MainAppbar(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("new_files_url");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const wishlist = component.getData("default.wishlist", []);
  const isAuth = component.isAuthenticated();
  const order = component.getData("default.order", null);
  const devHelper = component.getHelpers("dev");
  const [type, setMenuType] = React.useState("");

  function handleClick(event, type) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
      setMenuType(type);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          px: { xs: 0, md: 1 },
          py: "4px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            alignItems: "center",
          }}
        >
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <LanguageSelect {...props} />
          </Box>
          {/* <Link
            target="_blank"
            href={`${filesUrl}/assets/espa.pdf`}
            sx={{
              display:
                window.location.pathname === "/" ? "inline-block" : "none",
              "& img": { width: { xs: "300px", md: "320px" }, height: "auto" },
            }}
          >
            <img
              src={`${filesUrl}/assets/images/espa.jpg?tr=w-320`}
              width="320"
              height="40"
              sizes="(max-width: 900px) 300px, (min-width: 901px) 320px"
              alt="Casa Di Patsi Espa"
              title="Casa Di Patsi Espa"
            /> */}
          {/* <CardMedia
              component="img"
              image={filesUrl.concat(`/assets/images/espa.webp`)}
              alt="Casa Di Patsi Espa"
              title="Casa Di Patsi Espa"
              width="730"
              height="91"
              sx={{
                width: { xs: "300px", md: "320px" },
                height: "auto",
              }}
              sizes="(max-width: 900px) 300px, (min-width: 901px) 320px"
            /> */}
          {/* </Link> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            minHeight: 0,
            justifyContent: { xs: "flex-start", md: "space-between" },
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconsButton
              disableRipple
              onClick={() => {
                component.toggleMenuDrawer();
              }}
              aria-label="Mobile Menu"
            >
              <i className="fa-light fa-bars" />
            </IconsButton>
          </Box>
          <Button
            sx={{
              outline: "none",
              p: 0,
              "&:hover": {
                bgcolor: "transparent",
              },
              "& img": { width: { xs: "150px", md: "200px" }, height: "auto" },
            }}
            disableRipple
            component={RouterLink}
            to="/"
          >
            <img
              src={`${filesUrl}/assets/images/logo.png?tr=w-320`}
              width="200"
              height="57"
              sizes="(max-width: 900px) 150px, (min-width: 901px) 200px"
              alt="Casa Di Patsi Home"
              title="Casa Di Patsi Home"
            />
            {/* <CardMedia
              component="img"
              image={filesUrl.concat(`/assets/images/logo.webp`)}
              alt="Casa Di Patsi Logo"
              title="Casa Di Patsi Home"
              width="261"
              height="73"
              sx={{
                width: { xs: "150px", md: "200px" },
                height: "auto",
              }}
              sizes="(max-width: 900px) 150px, (min-width: 901px) 200px"
            /> */}
          </Button>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          />
          <Box>
            <form onSubmit={(e) => component.checkSearch(e)} method="get">
              <Search
                sx={{
                  display: { xs: "none", md: "block" },
                  width: { md: "500px", lg: "600px" },
                }}
              >
                <SearchIconWrapper
                  type="submit"
                  disableRipple
                  aria-label="Search"
                >
                  <i className="fa-light fa-magnifying-glass" />
                </SearchIconWrapper>
                <StyledInputBase
                  value={component.getData("default.search_term", "")}
                  onChange={(e) => {
                    component.setData({
                      "default.search_term": e.target.value,
                    });
                  }}
                  placeholder={component.ucfirst("search-holder")}
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </form>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "flex" },
            }}
          >
            <IconsButton
              disableRipple
              component={RouterLink}
              to="/wishlist"
              aria-label="Wishlist"
            >
              <StyledBadge badgeContent={wishlist.length}>
                <i className="fa-light fa-heart" />
              </StyledBadge>
            </IconsButton>
            <IconsButton
              disableRipple
              onClick={() => {
                component.toggleCartDrawer();
              }}
              aria-label="Cart"
            >
              <StyledBadge
                badgeContent={devHelper.getObjectValue(
                  order,
                  "amounts.items",
                  0
                )}
                showZero
                sx={{
                  "& .MuiBadge-badge": {
                    visibility:
                      devHelper.getObjectValue(order, "amounts.items", 0) === 0
                        ? "hidden"
                        : "visible",
                  },
                }}
              >
                <i className="fa-light fa-cart-shopping" />
              </StyledBadge>
            </IconsButton>
            {isAuth ? (
              <>
                <IconsButton
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => handleClick(e, "account")}
                  disableRipple
                  aria-label="User Account"
                >
                  <i className="fa-light fa-user" />
                </IconsButton>
                <Menu
                  MenuListProps={{
                    onMouseLeave: handleClose,
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  elevation={2}
                >
                  <Box>
                    {type === "account" && (
                      <>
                        <MyMenuItem
                          component={RouterLink}
                          to="/profile"
                          onClick={handleClose}
                        >
                          {component.ucfirst("profile-btn")}
                        </MyMenuItem>
                        <MyMenuItem
                          component={RouterLink}
                          to="/addresses"
                          onClick={handleClose}
                        >
                          {component.ucfirst("addresses-btn")}
                        </MyMenuItem>
                        <MyMenuItem
                          component={RouterLink}
                          to="/orders"
                          onClick={handleClose}
                        >
                          {component.ucfirst("orders-btn")}
                        </MyMenuItem>
                        <MyMenuItem
                          component={RouterLink}
                          to="/coupons"
                          onClick={handleClose}
                        >
                          {component.ucfirst("coupons-btn")}
                        </MyMenuItem>
                        <MyMenuItem
                          onClick={() => component.getPage().logout()}
                        >
                          {component.ucfirst("logout-btn")}
                        </MyMenuItem>
                      </>
                    )}
                  </Box>
                </Menu>
              </>
            ) : (
              <IconsButton
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={() =>
                  component
                    .getComponents()
                    .findById("login-register-dialog")
                    .first()
                    .setData({
                      "default.open": true,
                    })
                }
                disableRipple
                aria-label="User Account"
              >
                <i className="fa-light fa-user" />
              </IconsButton>
            )}
          </Box>
        </Box>
      </Container>
      <MainMenu {...props} />
    </>
  );
}
