import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import CartDrawer from "../../../Components/Widgets/CartDrawer";
import Footer from "../../../Components/Widgets/Footer";
import GridCategories from "../../../Components/Widgets/Sliders/GridCategories";
import BrandsSlider from "../../../Components/Widgets/Sliders/BrandsSlider";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar {...props} />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <CartDrawer {...props} />
      <GridCategories {...props} />
      <BrandsSlider
        {...{
          ...props,
          ...{
            fscope: "slider-brands",
          },
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
