import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/designers/designer_list_view";

export default class DesignerListView extends Component {
  id = "designer-list-view";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();
  }

  setProduct() {
    var designer = { ...this.props.designer };

    this.setData({
      "default.designer": designer,
    });
  }
}
