import React, { useState, useEffect, useRef } from "react";
import { Container, Grid, Box, Fade } from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

export default function Hotels(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("new_files_url");

  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  // Function to update image width
  const updateImageWidth = () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }
  };

  // UseEffect to add event listener for resize
  useEffect(() => {
    updateImageWidth(); // Initial width on component mount
    window.addEventListener("resize", updateImageWidth); // Update on resize
    return () => window.removeEventListener("resize", updateImageWidth); // Cleanup
  }, []);

  return (
    <Container
      sx={{
        px: 2,
        my: 3,
        "& h5": {
          fontWeight: 400,
          lineHeight: 2,
          fontSize: 15,
        },
      }}
      disableGutters
      maxWidth="lg"
    >
      <Translate
        entity="item"
        eslug="hotels"
        scope="default"
        slug="info"
        auto
      />
      <Grid
        container
        justifycontent="center"
        direction="row"
        rowSpacing={1}
        columnSpacing={1}
      >
        {[...new Array(28)].map((img, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Box
                ref={imageRef}
                sx={{
                  "&:hover": {
                    "& > div > a > img": {
                      transform: "scale(1.1)",
                      webkitTransform: "scale(1.1)",
                    },
                  },
                }}
              >
                <Fade in={component.getData(`default.ready-${index}`, false)}>
                  <Box
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      "& img": {
                        width: imageWidth,
                        height: "auto",
                        transition: "transform .8s ease",
                        webkitTransition: "transform .8s ease",
                      },
                    }}
                  >
                    <img
                      src={`${filesUrl}/marketing/hotels/${
                        index + 1
                      }.jpeg??tr=w-${imageWidth}`}
                      alt={`hotel ${index + 1}`}
                      width={imageWidth}
                      onLoad={() => {
                        component.setData({
                          [`default.ready-${index}`]: true,
                        });
                      }}
                      onError={() => {
                        component.setData({
                          [`default.ready-${index}`]: true,
                        });
                      }}
                    />
                  </Box>
                </Fade>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
