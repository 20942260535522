import React from "react";
import { styled } from "@mui/material/styles";
import {
  CardMedia,
  Link,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  Fade,
  Grid,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const MyTableCell = styled(TableCell)({
  padding: "0px 0px 8px",
  borderBottom: "none",
});

export default function ProductCartView(props) {
  const { component } = props;

  const { checkout = false } = component.props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const { ready = false, product = null } = component.getData("default", {});

  const {
    slug = null,
    name = null,
    code = null,
    group = null,
    price = null,
    // combination = null,
    // type = "",
    brand = "",
    // collection = "",
    simple = true,
    comb_materials = [],
    size = "",
    // categorie = "",
  } = devHelper.getObjectValue(product, "item", {});

  return (
    <>
      {product && (
        <TableRow
          sx={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            verticalAlign: "top",
          }}
        >
          <MyTableCell component="th" scope="row" sx={{ py: 0 }}>
            <Grid
              container
              sx={{
                py: 1,
                px: 0,
                alignItems: "flex-start",
                diplay: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid item xs={12} sm={4}>
                <Fade in={ready}>
                  <Link
                    component={RouterLink}
                    to={`/product/${slug}`}
                    title={`${devHelper.getObjectValue(
                      brand,
                      "item._values.name"
                    )} - ${name}`}
                  >
                    <Box
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      <CardMedia
                        sx={{
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                        component="img"
                        image={filesUrl.concat(
                          `/products/${devHelper.getObjectValue(
                            brand,
                            "item._values.slug"
                          )}/images/${group}/1.jpg?v=${img_version}`
                        )}
                        alt={`${name} image`}
                        onLoad={() => {
                          component.setData({ "default.ready": true });
                        }}
                        onError={(e) => {
                          e.target.src = filesUrl.concat(
                            `/assets/images/not-found.jpg`
                          );
                          component.setData({ "default.ready": true });
                        }}
                      />
                    </Box>
                  </Link>
                </Fade>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box sx={{ ml: { xs: 1, sm: 3 } }}>
                  {brand && (
                    <Button
                      component={RouterLink}
                      to={`/products/brand/${devHelper.getObjectValue(
                        brand,
                        "item._values.slug"
                      )}`}
                      disableRipple
                      sx={{
                        fontSize: 16,
                        color: "#000",
                        fontWeight: 500,
                        p: 0,
                        textTransform: "none",
                        minWidth: 0,
                        "&:hover,&:focus": {
                          bgcolor: "transparent",
                        },
                      }}
                    >
                      {devHelper.getObjectValue(brand, "item._values.name")}
                    </Button>
                  )}
                  <Button
                    component={RouterLink}
                    to={`/product/${slug}`}
                    disableRipple
                    sx={{
                      display: "block",
                      fontSize: 16,
                      color: "#777",
                      fontWeight: 400,
                      mb: 1,
                      p: 0,
                      textTransform: "none",
                      minWidth: 0,
                      "&:hover,&:focus": {
                        bgcolor: "transparent",
                      },
                    }}
                  >
                    {name}
                  </Button>
                  <Box></Box>
                  <Table>
                    <TableBody>
                      {!simple ? (
                        <>
                          {comb_materials.map((mat) => (
                            <TableRow
                              key={devHelper.getObjectValue(
                                mat,
                                "item._values.slug"
                              )}
                            >
                              <MyTableCell
                                sx={{
                                  width: { xs: "40px", sm: "60px" },
                                }}
                              >
                                {`${component.ucfirst(
                                  devHelper.getObjectValue(
                                    mat,
                                    "item._values.type"
                                  )
                                )}:`}
                              </MyTableCell>
                              <MyTableCell>
                                {devHelper.getObjectValue(
                                  mat,
                                  "item._values.name"
                                )}
                              </MyTableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                      {size ? (
                        <TableRow>
                          <MyTableCell
                            sx={{ width: { xs: "45px", sm: "60px" } }}
                          >
                            {`${component.ucfirst("size")}:`}
                          </MyTableCell>
                          <MyTableCell>{size}</MyTableCell>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                      <TableRow>
                        <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                          Qty:
                        </MyTableCell>
                        <MyTableCell>
                          <Box
                            sx={{
                              display: checkout ? "none" : "inline-block",
                              background: "transparent",
                              color: "#000",
                              p: 0,
                            }}
                          >
                            <Button
                              sx={{
                                backgroundColor: "transparent",
                                color: "#000",
                                height: "100%",
                                fontSize: "16px",
                                padding: 0,
                                fontWeight: 300,
                                minWidth: "initial",
                                "&:hover, &:focus": {
                                  background: "transparent",
                                  opacity: 0.8,
                                },
                                "&:disabled": {
                                  color: "#808080",
                                },
                              }}
                              disableRipple
                              disabled={
                                devHelper.getObjectValue(product, "amount") ===
                                1
                              }
                              onClick={() => {
                                component.updateCart(
                                  product.item,
                                  "combinations.code",
                                  code,
                                  -1
                                );
                              }}
                            >
                              <i className="fa-regular fa-minus" />
                            </Button>
                            <Typography
                              component="span"
                              sx={{
                                margin: "0px 12px",
                                fontSize: "16px",
                                fontWeight: 400,
                              }}
                            >
                              {devHelper.getObjectValue(product, "amount")}
                            </Typography>
                            <Button
                              sx={{
                                backgroundColor: "transparent",
                                color: "#000",
                                height: "100%",
                                fontSize: "16px",
                                padding: 0,
                                fontWeight: 300,
                                minWidth: "initial",
                                "&:hover, &:focus": {
                                  background: "transparent",
                                  opacity: 0.8,
                                },
                                "&:disabled": {
                                  color: "#808080",
                                },
                              }}
                              disableRipple
                              onClick={() => {
                                component.updateCart(
                                  product.item,
                                  "combinations.code",
                                  code,
                                  1
                                );
                              }}
                            >
                              <i className="fa-regular fa-plus" />
                            </Button>
                          </Box>
                          {checkout &&
                            devHelper.getObjectValue(product, "amount")}
                        </MyTableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          display: { xs: "table-row", sm: "none" },
                        }}
                      >
                        <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                          {`${component.ucfirst("price")}:`}
                        </MyTableCell>
                        <MyTableCell
                          sx={{
                            fontSize: 16,
                            color: "#ff6363",
                            fontWeight: 500,
                          }}
                        >
                          {component.getApp().priceFormater(price)}
                        </MyTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Link
                    sx={{
                      display: checkout ? "none" : "inline-flex",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "400",
                      cursor: "pointer",
                      borderBottom: "1px solid #000",
                      "&:hover, &:focus": {
                        borderBottom: "1px solid transparent",
                      },
                    }}
                    underline="none"
                    onClick={() => {
                      component.updateCart(
                        product.item,
                        "combinations.code",
                        code,
                        0
                      );
                    }}
                  >
                    {component.ucfirst("remove-btn")}
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </MyTableCell>
          <MyTableCell
            sx={{
              display: { xs: "none", sm: "table-cell" },
            }}
            align="right"
          >
            <Box
              sx={{
                mt: "38.7px",
                fontSize: 16,
                color: "#ff6363",
                fontWeight: 500,
              }}
            >
              {component
                .getApp()
                .priceFormater(
                  devHelper.getObjectValue(product, "price") *
                    devHelper.getObjectValue(product, "amount")
                )}
            </Box>
          </MyTableCell>
        </TableRow>
      )}
    </>
  );
}
