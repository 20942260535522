import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Dialog,
  DialogContent,
  Box,
  CardMedia,
  IconButton,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@mui/icons-material/Close";

const BackNextButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "50%",
  backgroundColor: "#fff",
  color: "#000",
  fontSize: "20px",
  zIndex: 1500,
  width: "35px",
  height: "35px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  "&:disabled": {
    backgroundColor: "#fff",
    color: "#000",
    opacity: "0.5",
  },
  "&:hover": {
    backgroundColor: "#fff",
  },
}));

export default function Zoom(props) {
  const { component } = props;
  const { group, brand } = component.props;
  const theme = useTheme();
  const filesUrl = component.getHelpers("env").get("files_url");
  const images = component.getData("default.images", []);
  const activeImage = component.getData("default.activeImage", 0);

  const handleNext = () => {
    const activeImage = component.getData("default.activeImage", 0);
    component.setData({ "default.activeImage": activeImage + 1 });
  };

  const handleBack = () => {
    const activeImage = component.getData("default.activeImage", 0);
    component.setData({ "default.activeImage": activeImage - 1 });
  };

  const handleImageChange = (step) => {
    component.setData({ "default.activeImage": step });
  };

  return (
    <>
      {images !== 0 && (
        <Dialog
          onClose={() => component.toggle()}
          open={component.getData("default.opened", false)}
          sx={{
            padding: 0,
            "& .MuiDialog-paper": {
              backgroundColor: "transparent",
            },
          }}
          fullScreen
        >
          <DialogContent
            sx={{
              bgcolor: "#f7f8fa",
              p: 0,
              outline: 0,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                background: "transparent",
              }}
            >
              <IconButton
                onClick={() => component.toggle()}
                sx={{
                  zIndex: "1400",
                  position: "absolute",
                  top: { xs: 15, md: 20 },
                  right: { xs: 15, md: 20 },
                  fontSize: { xs: "24px", sm: "28px" },
                  color: "#000",
                  bgcolor: "#fff",
                  "&:hover": {
                    bgcolor: "#fff",
                    opacity: 0.8,
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <BackNextButton
                sx={{
                  left: 16,
                  fontSize: { xs: "18px", sm: "20px" },
                }}
                onClick={handleBack}
                disabled={activeImage === 0}
              >
                <i className="fa-solid fa-chevron-left" />
              </BackNextButton>
              <BackNextButton
                sx={{
                  right: 16,
                  fontSize: { xs: "18px", sm: "20px" },
                }}
                onClick={() => handleNext()}
                disabled={activeImage === images.length - 1}
              >
                <i className="fa-solid fa-chevron-right" />
              </BackNextButton>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeImage}
                onChangeIndex={handleImageChange}
                enableMouseEvents
              >
                {images.map((image, index) => (
                  <CardMedia
                    key={"product-img-" + index}
                    sx={{
                      maxWidth: "100%",
                      objectFit: "contain",
                      height: { xs: "350px", sm: "450px", md: "630px" },
                    }}
                    component="img"
                    image={filesUrl.concat(
                      `/products/${brand}/images/${group}/${image}`
                    )}
                    alt={`${image}`}
                    onLoad={() => {
                      component.setData({ "default.ready": true });
                    }}
                    onError={() => {
                      component.setData({ "default.ready": true });
                    }}
                  />
                ))}
              </SwipeableViews>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
