import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  TextField,
  Link,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Alert,
  Autocomplete,
  Grid,
} from "@mui/material";

const MyCouponsBtn = styled(Link)(({ theme }) => ({
  float: "right",
  color: "#000",
  fontSize: "15px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  pointerEvents: "auto",
  height: 25,
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

const PromoCode = styled(Alert)(({ theme }) => ({
  marginTop: "8px",
  border: "1px solid #ff6363",
  backgroundColor: "#ff6363",
  position: "relative",
  textAlign: "center",
  alignItems: "center",
  borderRadius: "4px",
  color: "#fff",
  fontSize: 20,
  fontWeight: 400,
  "& .MuiAlert-action": {
    padding: 0,
  },
}));

const CouponAutocomplete = styled(Autocomplete)(({ theme }) => ({
  marginTop: "8px",
  backgroundColor: "#fff",
  borderRadius: "4px",
  "& > *": {
    color: "#28282B",
    borderRadius: "4px",
  },
}));

const CouponOptions = styled(Box)(({ theme }) => ({
  color: "#28282B",
  padding: "6px 16px",
  fontWeight: "450",
  minHeight: "auto !important",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  cursor: "pointer",
  "&:hover, &:focus": {
    color: "#28282B",
  },
  "& > i": {
    marginRight: "8px",
  },
}));

const NewCouponField = styled(FormControl)(({ theme }) => ({
  margin: "8px 0px",
  width: "100%",
  "& > *": {
    color: "#28282B",
    borderRadius: "4px",
  },
}));

export default function CouponsSection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { order = null, guest = false } = component.getData("default", {});

  const coupons = component.getData("default.coupons", []);

  const [couponAutocomplete, showCouponAutocomplete] = React.useState(true);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 3 },
        mb: 3,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          {!devHelper.getObjectValue(order, "coupon") ? (
            <>
              {!guest && coupons.length > 1 && couponAutocomplete ? (
                <>
                  {component.ucfirst("checkout-subtitle-2-2")}
                  <CouponAutocomplete
                    id="mui-autocomplete"
                    fullWidth={true}
                    autoComplete={false}
                    options={coupons}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => {
                      return option.slug !== "add" ? (
                        <CouponOptions
                          key={option._id}
                          onClick={() => component.activateCoupon(option._id)}
                        >
                          <i className="fa-light fa-ticket" />
                          {option.name}
                        </CouponOptions>
                      ) : (
                        <CouponOptions
                          id={option.slug}
                          key={option.slug}
                          onClick={() => showCouponAutocomplete(false)}
                          sx={{ textAlign: "center" }}
                        >
                          <i className="fak fa-light-ticket-circle-plus" />
                          {component.trans("add-new-coupon-btn")}
                        </CouponOptions>
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label={component.trans("promo-code")}
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      );
                    }}
                  />
                </>
              ) : (
                <Box>
                  {component.ucfirst("checkout-subtitle-2-1")}
                  <form onSubmit={(e) => component.updateCouponByCode(e)}>
                    <NewCouponField
                      variant="outlined"
                      fullWidth
                      name="couponCode"
                      type="text"
                      id="couponCode"
                      error={component.dataExists("error.couponCode")}
                    >
                      <InputLabel>{component.trans("promo-code")}</InputLabel>
                      <OutlinedInput
                        placeholder={component.trans("promo-holder")}
                        value={component.getData("default.couponCode", "")}
                        onChange={(e) => {
                          component.setData({
                            "default.couponCode": e.target.value,
                          });
                        }}
                        label={component.trans("promo-code")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              disableRipple
                              type="submit"
                              sx={{
                                color: "#000",
                                fontSize: "20px",
                                px: 2,
                                "&:hover, &:focus": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              <i className="fa-solid fa-chevron-right" />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {component.dataExists("error.couponCode") && (
                        <FormHelperText error style={{ margin: "3px 14px 0" }}>
                          {component.getData("error.couponCode")}
                        </FormHelperText>
                      )}
                    </NewCouponField>
                  </form>

                  {!guest && coupons.length > 1 && (
                    <Box
                      sx={{
                        marginBottom: "40px",
                      }}
                    >
                      <MyCouponsBtn
                        underline="none"
                        sx={{
                          float: "left !important",
                        }}
                        onClick={() => showCouponAutocomplete(true)}
                      >
                        {component.ucfirst("my-coupons")}
                      </MyCouponsBtn>
                    </Box>
                  )}
                </Box>
              )}
            </>
          ) : (
            <>
              <PromoCode
                onClose={() => {
                  component.releaseCoupon();
                  showCouponAutocomplete(true);
                }}
                elevation={0}
                variant="filled"
                icon={false}
              >
                {devHelper.getObjectValue(order, "coupon.name")}
              </PromoCode>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
