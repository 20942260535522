import React from "react";
import { Box, Container } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import CartDrawer from "../../../Components/Widgets/CartDrawer";
import TopBannersSlider from "../../../Components/Widgets/Sliders/TopBannersSlider";
import Translate from "../../../../modules/Core/Components/Translate";
import Footer from "../../../Components/Widgets/Footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#F7F8FA",
      }}
    >
      <NavBar {...props} />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <CartDrawer {...props} />
      <TopBannersSlider
        {...{
          ...props,
          ...{
            text_color: "#fff",
            top: "50%",
            left: "50%",
            center: "-50%,-50%",
            slides: [
              {
                slug: "slider-bazaar",
                url: "/bzr",
              },
            ],
          },
        }}
      />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          mt: 2,
          px: 2,
          textAlign: "center",
          "& h5": {
            fontWeight: 400,
            lineHeight: 2,
            fontSize: { xs: 18, md: 20 },
          },
          "& a": {
            textTransform: "none",
            textDecoration: "none",
            color: "#333",
            "&:hover,&:focus": { color: "#333", opacity: 0.9 },
          },
          "& i": {
            mr: 1,
          },
        }}
      >
        <Translate
          entity="item"
          eslug="bazaar"
          scope="default"
          slug="default"
          auto
        />
        <div>
          <h5>
            <b>
              <a
                href="https://www.google.com/maps/place/CASA+DI+PATSI+Stockhouse+%CE%99%CF%84%CE%B1%CE%BB%CE%B9%CE%BA%CE%AC+%CE%AD%CF%80%CE%B9%CF%80%CE%BB%CE%B1/@38.0678071,23.7552914,17z/data=!4m6!3m5!1s0x14a1a1f89df1a515:0xaed40861765ff4d9!8m2!3d38.0678071!4d23.7552914!16s%2Fg%2F1td2r5kn?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fa-solid fa-location-dot"></i>
                <Translate
                  entity="item"
                  eslug="store-4"
                  scope="default"
                  slug="address"
                  auto
                />
              </a>
            </b>
          </h5>
        </div>
        <div>
          <h5>
            <b>
              <a href="tel:2102850233">
                <i className="fa-solid fa-circle-phone"></i>
                2102850233
              </a>
            </b>
          </h5>
        </div>
      </Container>
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
