import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/b2b_brands";

export default class B2BBrands extends Page {
  title = "b2b-brands";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    //Set window title
    this.getApp().updateWindowTitle(
      `${this.ucfirst("brands")} | ${this.ucfirst("window-title")}`
    );
    this.scrollToTop();
  }
}
