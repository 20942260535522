import React from "react";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import CheckoutNavbar from "../../../Components/Widgets/CheckoutNavbar";
import Checkout from "../../../Components/Widgets/Checkout";
import Footer from "../../../Components/Widgets/Footer";

const ExportDefault = (props) => {
  const { component } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={component.getData("default.fullLoading", false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CheckoutNavbar {...props} />
      <Checkout {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
