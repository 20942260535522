import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Link,
  Grid,
  Typography,
  Container,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function GridBanners(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("new_files_url");
  const upSm = useMediaQuery(useTheme().breakpoints.up("sm"));

  const {
    top = null,
    bottom = null,
    left = null,
    right = null,
    center = null,
    text_color = "#fff",
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    slides = [],
    grids = [],
  } = props;

  const text = (slide, f_color) => {
    const { slug } = slide;

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          px: 0,
        }}
      >
        {!component.trans(`${slug}-title`).includes("slider") && (
          <Typography
            component="h1"
            sx={{
              color: f_color,
              fontSize: { xs: 22, md: 28 },
              textTransform: "none",
              lineHeight: 1,
              pt: 2,
              pl: 2,
              maxWidth: {
                xs: titleMaxWidthMobile ? titleMaxWidthMobile : "unset",
                lg: titleMaxWidthDesktop ? titleMaxWidthDesktop : "unset",
              },
            }}
          >
            {component.trans(`${slug}-title`)}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{
        px: { xs: 1, md: 1 },
        py: 4,
      }}
    >
      <Grid
        container
        direction="row"
        sx={{ alignItems: "center", justifyContent: "center" }}
        rowSpacing={2}
        columnSpacing={2}
      >
        {slides.map((slide, index) => {
          const { slug, url, width, height } = slide;

          const contWidth = window.innerWidth > 1300 ? 1300 : window.innerWidth;

          const imgWidth = Math.trunc(
            upSm
              ? (contWidth / 12) * grids[index].sm
              : (contWidth / 12) * grids[index].xs
          );

          return (
            <Grid
              item
              xs={grids[index].xs}
              sm={grids[index].sm}
              key={slug}
              sx={{
                "&:hover": {
                  "& > div > a > img": {
                    transform: "scale(1.1)",
                    webkitTransform: "scale(1.1)",
                  },
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Link
                  component={RouterLink}
                  to={url}
                  sx={{
                    display: "flex",
                    "& img": {
                      width: "100%",
                      height: "auto",
                      transition: "transform .8s ease",
                      webkitTransition: "transform .8s ease",
                    },
                  }}
                >
                  <img
                    src={`${filesUrl}/marketing/sliders/grid-banners/${slug}.jpg?tr=w-${imgWidth}`}
                    alt={`${component.trans(slug)}`}
                    width={width}
                    height={height}
                    component="img"
                  />
                </Link>
                <Box
                  sx={{
                    position: "absolute",
                    top: top ? top : "unset",
                    bottom: bottom ? bottom : "unset",
                    left: left ? left : "unset",
                    right: right ? right : "unset",
                    transform: center ? `translate(${center})` : "unset",
                  }}
                >
                  {text(slide, text_color)}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
