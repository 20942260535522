import React, { useState, useEffect, useRef } from "react";
import { Box, Link, Grid, Container, Fade } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function GridBanners(props) {
  const { component } = props;
  const { b2b = false } = component.props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("new_files_url");
  const sliders = component.getData("default.sliders", []);

  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  // Function to update image width
  const updateImageWidth = () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }
  };

  // UseEffect to add event listener for resize
  useEffect(() => {
    updateImageWidth(); // Initial width on component mount
    window.addEventListener("resize", updateImageWidth); // Update on resize
    return () => window.removeEventListener("resize", updateImageWidth); // Cleanup
  }, [sliders]);

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{
        px: { xs: 1, md: 1 },
        py: 4,
      }}
    >
      <Grid
        container
        justifycontent="center"
        direction="row"
        rowSpacing={1}
        columnSpacing={1}
      >
        {sliders.map((slider) => {
          const { slug, name } = devHelper.getObjectValue(slider, "_values");

          return (
            <Grid item xs={6} sm={4} md={3} key={slug}>
              <Box
                ref={imageRef}
                sx={{
                  "&:hover": {
                    "& > div > a > img": {
                      transform: "scale(1.1)",
                      webkitTransform: "scale(1.1)",
                    },
                  },
                }}
              >
                <Fade in={component.getData(`default.ready-${slug}`, false)}>
                  <Box
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <Link
                      component={RouterLink}
                      to={
                        b2b
                          ? `/products/brand/${slug}?scope=b2b`
                          : `/products/brand/${slug}`
                      }
                      sx={{
                        "& img": {
                          width: imageWidth,
                          height: "auto",
                          transition: "transform .8s ease",
                          webkitTransition: "transform .8s ease",
                        },
                      }}
                    >
                      <img
                        src={`${filesUrl}/brands/images/portrait/${slug}.jpg?tr=w-${imageWidth}`}
                        alt={`${name}`}
                        title={`${name}`}
                        width={imageWidth}
                        onLoad={() => {
                          component.setData({
                            [`default.ready-${slug}`]: true,
                          });
                        }}
                        onError={() => {
                          component.setData({
                            [`default.ready-${slug}`]: true,
                          });
                        }}
                      />
                    </Link>
                  </Box>
                </Fade>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
