import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert as MuiAlert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  color: "#fff",
  "&:hover": {
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function Alert(props) {
  return (
    <MuiAlert
      elevation={0}
      variant="filled"
      {...props}
      style={{ borderRadius: 0 }}
    />
  );
}

export default function ForgotPasswordDialog(props) {
  const { component } = props;
  const { open = false } = component.props;

  const errorMessagePasswordRequest = component.getData(
    "error.message.password.request"
  );

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <MyDialogTitle onClose={() => component.props.setOpen(false)}>
        {component.ucfirst("reset-password-title")}
      </MyDialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">
          {component.trans("reset-password-text-1")}
        </Typography>
        <form onSubmit={(e) => component.requestPassword(e)}>
          {errorMessagePasswordRequest && (
            <Alert style={{ marginTop: "10px" }} severity="error">
              {component.ucfirst(errorMessagePasswordRequest)}
            </Alert>
          )}
          <MyTextField
            error={component.dataExists("error.fields.request.username")}
            value={component.getData("default.fields.request.username", "")}
            helperText={component.getData("error.fields.request.username")}
            onChange={(e) => {
              component.setData({
                "default.fields.request.username": e.target.value,
              });
              component.deleteData("error.fields.request.username");
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={component.ucfirst("email-textfield")}
            name="email"
          />
          <MyButton type="submit" fullWidth>
            {component.trans("submit-btn")}
          </MyButton>
        </form>
      </DialogContent>
    </Dialog>
  );
}
