import React from "react";
import { Toolbar, AppBar, Button, Container, CardMedia } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function CheckoutNavbar(props) {
  const { component } = props;

  return (
    <>
      <AppBar
        position="relative"
        elevation={0}
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar
          disableGutters
          sx={{ minHeight: "0px !important", backgroundColor: "#fff" }}
        >
          <Container
            maxWidth="xl"
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 0,
            }}
          >
            <Button
              sx={{
                color: "#000",
                fontSize: { xs: "25px", md: "30px" },
                p: 0,
                "&:hover": {
                  bgcolor: "transparent",
                },
              }}
              disableRipple
              onClick={() => {
                component.getPage().goBack();
              }}
            >
              <i className="fa-light fa-circle-chevron-left" />
            </Button>
            <Button
              sx={{
                width: { xs: "160px", md: "200px" },
                outline: "none",
                p: 0,
                "&:hover": {
                  bgcolor: "transparent",
                },
              }}
              disableRipple
              component={RouterLink}
              to="/"
            >
              <CardMedia
                component="img"
                image="/images/logos/logo.png"
                alt="Casa Di Patsi Logo"
                title="Casa Di Patsi Home"
                sx={{
                  maxWidth: "100%",
                }}
              />
            </Button>
            <Button
              sx={{
                visibility: "hidden",
                color: "#fff",
                fontSize: { xs: "30px", md: "30px" },
                p: 0,
                "&:hover": {
                  bgcolor: "#f05d32",
                },
              }}
              disableRipple
              onClick={() => {
                component.getPage().goBack();
              }}
            >
              <i className="fa-light fa-circle-chevron-left"></i>
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" style={{ minHeight: 1 }} />
    </>
  );
}
