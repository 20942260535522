import App from "../../modules/Core/Components/App";
import template from "../templates/app";
import Services from "../Services/Services";

export default class MainApp extends App {
  template = template;

  onLoad(data) {
    super.onLoad(data);

    // this.installUserway();
  }

  getOnResizeResolusion(width) {
    if (width < 500) {
      return "mobile";
    }

    if (width >= 500) {
      return "default";
    }
  }

  onNotAllowedResponse(r, err) {
    if (this.getPage().isFullAuth()) {
      this.getPage().logout();
    } else {
      this.getHelpers("auth").clear();
    }
  }

  newsletterSubscribe(email) {
    Services.get("hook").then(async ([hookService]) => {
      const response = await hookService.post("subscribe", {
        email,
      });

      if (response.isValid()) {
        const data = response.getData();
        const valid = data === "ok";

        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            [`${valid ? "success" : "error"}-message`]: valid
              ? "subscribed"
              : data,
          });
      }
    });
  }

  priceFormater(price) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(price);
  }

  priceFormaterDecimal(price) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(price);
  }

  installUserway() {
    (function (d) {
      var s = d.createElement("script");
      s.setAttribute("data-account", "Ba1rT85pF4");
      s.setAttribute("src", "https://cdn.userway.org/widget.js");
      (d.body || d.head).appendChild(s);
    })(document);

    return this;
  }

  updateWindowTitle(title) {
    this.getPage().setWindowTitle(title);

    window.dataLayer.push({
      event: "pageviewCustomEvent",
      pagePath: window.location.pathname,
      pageTitle: title,
    });
    return this;
  }

  sendToGA4(product, event, amount) {
    if (product) {
      const { slug, name, price, type, brand, combination } = product._values
        ? product._values
        : product;

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: event,
        ecommerce: {
          items: [
            {
              item_id: slug,
              item_name: name,
              affiliation: "Casa di Patsi",
              index: 0,
              item_brand: brand ? brand.item._values.name : null,
              item_category: type ? type.item._values.name : null,
              item_variant: combination,
              price: price,
              quantity: amount || 1,
            },
          ],
        },
      });
    }
  }

  sendToGA4Order(order) {
    const { amounts, coupon, guest, items, paymentType, totals, _id } = order;

    const final_items = items.map((pr, index) => {
      return {
        item_id: pr.item.slug,
        item_name: pr.item.name,
        affiliation: "Casa di Patsi",
        index: index,
        item_brand:
          pr.item.brands.length !== 0
            ? pr.item.brands[0].item._values.name
            : null,
        item_category:
          pr.item.types.length !== 0
            ? pr.item.types[0].item._values.name
            : null,
        item_variant: pr.item.code,
        price: pr.price,
        quantity: pr.amount,
      };
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: _id,
        user: guest ? "guest" : "subscribed",
        total_items: amounts.items,
        value: totals.grand,
        items_total: totals.items,
        discount: totals.discount,
        shipping: totals.services,
        payment_type: paymentType.name,
        currency: "EUR",
        coupon: coupon ? coupon.name : null,
        items: final_items,
      },
    });
  }

  async addClick(data) {
    const { store = "default", type = "default", info = "" } = data;
    const [hookService] = await Services.get("hook");
    await hookService.post("add-click", { store, type, info });
  }
}
