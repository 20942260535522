import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product/first_section";
import Services from "../../Services/Services";
import axios from "axios";

export default class Product extends Component {
  template = templateDefault;
  id = "product";
  group = "wishlist,order";

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();
    this.checkWishlist();
    this.getOrder();
  }

  updateProduct() {
    this.setData({
      "default.filteredMaterials": {},
      "default.fields": {},
    });
    this.setProduct();
    this.checkWishlist();
    this.getOrder();
  }

  setProduct() {
    //Get product
    Services.get("hook").then(([service]) => {
      service
        .exec("product", {
          params: { slug: this.getPage().getParam("slug", "") },
        })
        .then((response) => {
          const product = response.getData() || {};

          var { combinations = [], materials = [], slug } = product._values;

          this.addToRecentlyViewed(slug);

          this.setSelects(product, combinations, materials);
        });
    });
  }

  addToRecentlyViewed(slug) {
    Services.get("recentlyViewed").then(([recentlyViewed]) => {
      recentlyViewed.addToRecentlyViewed(slug);
    });

    return this;
  }

  setSelects(product, combinations, materials) {
    const fields = {};

    //sort combinations by price
    combinations.sort(function (a, b) {
      return a.final_price - b.final_price;
    });

    const newMaterials = {};
    const finalMaterials = {};
    const allMaterialsTypes = JSON.parse(
      this.getHelpers("value").getValue("materials-types")
    );

    //build newMaterials
    materials.forEach((mat) => {
      const { slug, name, type } = mat.item._values;

      const comb_filtered = combinations.filter((comb) =>
        comb.options.split(",").some((val) => val === slug)
      );

      if (comb_filtered.length) {
        if (!newMaterials[type]) {
          newMaterials[type] = [];
        }

        newMaterials[type].push({ slug, name });
      }
    });

    combinations.forEach((comb) => {
      const { size } = comb;
      if (size) {
        if (!newMaterials["size"]) {
          newMaterials["size"] = [];
        }
        if (!newMaterials["size"].some((typ) => typ.slug === size)) {
          newMaterials["size"].push({ slug: size, name: size });
        }
      }
    });

    // sort newMaterials
    allMaterialsTypes.forEach((type) => {
      if (newMaterials[type]) {
        finalMaterials[type] = newMaterials[type];
      }
    });

    Object.keys(finalMaterials).forEach((type) => {
      fields[type] = "";
    });

    this.setProductValues(product, combinations[0]);

    this.setData({
      "default.filteredMaterials": finalMaterials,
      "default.fields": fields,
    });
    return this;
  }

  updateSelects(product, combinations, materials, index) {
    const { filteredMaterials = {}, fields = {} } = this.getData("default", {});

    //sort combinations by price
    combinations.sort(function (a, b) {
      return a.final_price - b.final_price;
    });

    Object.keys(fields).forEach((type, i) => {
      if (i > index) {
        fields[type] = "";
      }
    });

    const newMaterials = {};
    const allMaterials = JSON.parse(
      this.getHelpers("value").getValue("materials-types")
    );

    //filter combinations by fields selected
    Object.keys(fields).forEach((type) => {
      if (type !== "size" && fields[type] !== "") {
        combinations = combinations.filter((comb) =>
          comb.options.split(",").some((val) => val === fields[type])
        );
      }
    });

    //build newMaterials
    materials.forEach((mat) => {
      const { slug, name, type } = mat.item._values;

      const comb_filtered = combinations.filter((comb) =>
        comb.options.split(",").some((val) => val === slug)
      );

      if (comb_filtered.length) {
        if (!newMaterials[type]) {
          newMaterials[type] = [];
        }

        newMaterials[type].push({ slug, name });
      }
    });

    combinations.forEach((comb) => {
      const { size } = comb;
      if (size) {
        if (!newMaterials["size"]) {
          newMaterials["size"] = [];
        }
        if (!newMaterials["size"].some((typ) => typ.slug === size)) {
          newMaterials["size"].push({ slug: size, name: size });
        }
      }
    });

    // sort newMaterials and update below options
    allMaterials.forEach((type) => {
      if (
        newMaterials[type] &&
        Object.keys(filteredMaterials).findIndex((ftype) => ftype === type) >
          index
      ) {
        filteredMaterials[type] = newMaterials[type];
      }
    });

    this.setProductValues(product, combinations[0]);

    this.setData({
      "default.filteredMaterials": filteredMaterials,
      "default.fields": fields,
    });
    return this;
  }

  setSize(product, combinations, size) {
    const { fields = {} } = this.getData("default", {});

    //sort combinations by price
    combinations.sort(function (a, b) {
      return a.final_price - b.final_price;
    });

    //filter combinations by fields selected
    Object.keys(fields).forEach((type) => {
      if (type !== "size" && fields[type] !== "") {
        combinations = combinations.filter((comb) =>
          comb.options.split(",").some((val) => val === fields[type])
        );
      }
    });

    const combination = combinations.find((comb) => comb.size === size);

    this.setProductValues(product, combination);

    return this;
  }

  setProductValues(product, firstComb) {
    const { code, final_price, price, options } = firstComb;

    const {
      types = [],
      brands = [],
      collections = [],
      designers = [],
      categories = [],
      name,
      group,
    } = product._values;

    const brand = brands ? brands[0] : null;

    product._values = {
      ...product._values,
      code: code,
      combination: options,
      price: final_price,
      first_price: price,
      type: types ? types[0] : null,
      brand: brand,
      collection: collections ? collections[0] : null,
      designer: designers ? designers[0] : null,
      categorie: categories ? categories[0] : null,
      simple: options === "default",
    };

    //Set window title
    const dev = this.getHelpers("dev");

    const b_name = dev.getObjectValue(brand, "item._values.name");
    const type = dev.getObjectValue(product, "_values.type.item._values.name");

    this.getApp().updateWindowTitle(
      `${name} | ${this.ucfirst(b_name)} | ${this.ucfirst(
        type
      )} | ${this.ucfirst("window-title")}`
    );

    //get products files
    this.setData({
      "default.product": product,
      "default.images": [],
      "default.pdfs": [],
    });

    this.getProductFiles(group, dev.getObjectValue(brand, "item._values.slug"));

    this.getApp().sendToGA4(product, "view_item");

    return this;
  }

  getProductFiles(group, b_slug) {
    axios
      .get(
        `https://files.automatapp.com/listing/casadipatsi/shop/products/${b_slug}/images/${group}`
      )
      .then((res) => {
        this.setData({
          "default.images": res.data.sort(),
        });
      });

    axios
      .get(
        `https://files.automatapp.com/listing/casadipatsi/shop/products/${b_slug}/pdfs/${group}`
      )
      .then((res) => {
        this.setData({
          "default.pdfs": res.data.sort(),
        });
      });

    axios
      .get(
        `https://files.automatapp.com/listing/casadipatsi/shop/brands/catalogues/${b_slug}`
      )
      .then((res) => {
        const catalogues = res.data;

        this.setData({
          "default.catalogues": catalogues
            .filter((cat) => cat.includes(".pdf"))
            .map((cat) => cat.replace(".pdf", ""))
            .sort(),
        });
      });

    return this;
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      var product_slug = this.getPage().getParam("slug", null);
      this.setData({
        "default.wishlist": wishlist.some((el) => el === product_slug),
      });
    });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }

  toggleZoom(images, activeImage) {
    this.getComponents()
      .findById("zoom")
      .forEach((zoom) => {
        zoom.setData({
          "default.images": images,
          "default.activeImage": activeImage,
        });
        zoom.toggle();
      });
  }

  fieldsChecker() {
    const { fields = {} } = this.getData("default", {});

    const error = { fields: {} };
    Object.keys(fields).forEach((type) => {
      if (fields[type] === "") {
        error.fields[type] = "";
      }
    });

    this.setData({
      error,
    });
    return Object.keys(error.fields).length === 0;
  }

  updateCart(product, skuPath, sku, amount) {
    if (this.fieldsChecker()) {
      Services.get("order").then(async ([orderService]) => {
        const { _id } = product;
        sku = Array.isArray(sku) ? sku.join(",") : sku;

        amount = this.getProductAmount(product, skuPath, sku) + amount;

        await orderService.updateItem({
          itemId: _id,
          itemType: "product",
          sku: skuPath ? `${skuPath}:${sku}` : sku,
          amount,
        });

        this.getApp().sendToGA4(product, "add_to_cart", amount);

        this.getComponents()
          .findByGroup("order")
          .forEach((order) => {
            order.setData({ "default.order": orderService.getOrder() });
          });

        if (this.getProductAmount(product, skuPath, sku) !== amount) {
          product.in_stock = false;
          this.setData({ "default.product": product });
        }
      });

      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "message-duration": 700,
          "success-message": this.trans("product-added-to-cart-message"),
        });
    }
  }

  getProductAmount(product, skuPath, sku) {
    const { items = [] } = this.getData("default.order", {}) || {};

    const { amount = 0 } =
      items.find((orderItem) => {
        return (
          product._id === orderItem.item.id &&
          (!sku || orderItem.sku === `${skuPath}:${sku}`)
        );
      }) || {};

    return amount;
  }

  RequestInfo(product) {
    const { slug, group, code, combination } = product._values;

    const productInfo = {
      slug,
      group,
      code,
      combination,
    };

    this.getPage().setData({ "default.product_info": productInfo });
  }

  shareProduct() {
    const product = this.getData("default.product._values");

    if (product && navigator.canShare) {
      navigator.share({
        title: this.getHelpers("title"),
        text: product.name,
        url: document.location.href,
      });
    }

    return this;
  }
}
