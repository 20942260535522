import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/footer";
import Services from "../../Services/Services";

export default class Footer extends Component {
  id = "footer";
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getFooterLinks();
  }

  getFooterLinks() {
    Services.get("hook").then(([service]) => {
      service
        .exec("statics", {
          params: { scope: "legal-and-privacy" },
        })
        .then((response) => {
          const links = response.getData() || {};
          this.setData({
            "default.links": links,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
