import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, TextField } from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& > *": {
    color: "#28282B",
    // borderRadius: "12px !important",
  },
}));

export default function Address(props) {
  const { component, type } = props;
  var order = "";
  if (type !== "address") {
    order = "order.";
  }

  return (
    <Grid container justifycontent="center" spacing={1}>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("first_name-textfield")}
          name="first_name"
          helperText={
            component.getData("error." + type + ".first_name", [
              { message: "" },
            ])[0].message
          }
          value={component.getData(
            "default." + order + "" + type + ".first_name",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".first_name"]: e.target.value,
            });
            component.deleteData("error." + type + ".first_name");
          }}
          onFocus={(e) => component.setData({ onSubmit: true })}
          error={component.dataExists("error." + type + ".first_name")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("last_name-textfield")}
          name="last_name"
          helperText={
            component.getData("error." + type + ".last_name", [
              { message: "" },
            ])[0].message
          }
          value={component.getData(
            "default." + order + "" + type + ".last_name",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".last_name"]: e.target.value,
            });
            component.deleteData("error." + type + ".last_name");
          }}
          onFocus={(e) => component.setData({ onSubmit: true })}
          error={component.dataExists("error." + type + ".last_name")}
        />
      </Grid>
      <Grid item xs={12}>
        <MyTextField
          id={type !== "address" ? type + "_autocomplete" : "autocomplete"}
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("street_address-textfield")}
          name="street"
          helperText={
            component.getData("error." + type + ".street", [{ message: "" }])[0]
              .message
          }
          value={component.getData(
            "default." + order + "" + type + ".street",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".street"]: e.target.value,
            });
            component.deleteData("error." + type + ".street");
          }}
          onFocus={(e) => component.setData({ onSubmit: false })}
          error={component.dataExists("error." + type + ".street")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("city-textfield")}
          name="city"
          helperText={
            component.getData("error." + type + ".city", [{ message: "" }])[0]
              .message
          }
          value={component.getData(
            "default." + order + "" + type + ".city",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".city"]: e.target.value,
            });
            component.deleteData("error." + type + ".city");
          }}
          error={component.dataExists("error." + type + ".city")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("state-textfield")}
          name="state"
          autoComplete="state"
          value={component.getData(
            "default." + order + "" + type + ".state",
            ""
          )}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Grid item xs={12}>
          <MyTextField
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("postal_code-textfield")}
            name="postal_code"
            helperText={
              component.getData("error." + type + ".postal_code", [
                { message: "" },
              ])[0].message
            }
            value={component.getData(
              "default." + order + "" + type + ".postal_code",
              ""
            )}
            onChange={(e) => {
              component.setData({
                ["default." + order + "" + type + ".postal_code"]:
                  e.target.value,
              });
              component.deleteData("error." + type + ".postal_code");
            }}
            error={component.dataExists("error." + type + ".postal_code")}
          />
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("country-textfield")}
          name="country"
          autoComplete="country"
          value={"Ελλάδα"}
        />
      </Grid>
      <Grid item xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("phone-textfield")}
          name="phone"
          helperText={
            component.getData("error." + type + ".phone", [{ message: "" }])[0]
              .message
          }
          value={component.getData(
            "default." + order + "" + type + ".phone",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".phone"]: e.target.value,
            });
            component.deleteData("error." + type + ".phone");
          }}
          onFocus={(e) => component.setData({ onSubmit: true })}
          error={component.dataExists("error." + type + ".phone")}
        />
      </Grid>
    </Grid>
  );
}
