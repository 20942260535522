import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/top_banners_slider";

export default class TopBannersSlider extends Component {
  id = "main-slider";
  interval;

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSlides();
  }

  getSlides() {
    this.startInterval(this.props.slides, 0);
  }

  startInterval(slides, activeSlide) {
    clearInterval(this.interval);
    this.setData({
      "default.activeSlide": activeSlide,
    });
    this.interval = setInterval(() => {
      this.setData({
        "default.activeSlide": ++activeSlide % slides.length,
      });
    }, 6000);
  }
}
