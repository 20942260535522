import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/stores/first_section";
import Services from "../../Services/Services";

export default class Stores extends Component {
  id = "stores";
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.getStores();
  }

  getStores() {
    Services.get("hook").then(([service]) => {
      service
        .exec("stores", {
          params: {},
        })
        .then((response) => {
          const stores = response.getData() || [];
          this.setData({
            "default.stores": stores.sort(function (a, b) {
              a.position =
                typeof a._values.position === "undefined"
                  ? 10000
                  : a._values.position;
              b.position =
                typeof b._values.position === "undefined"
                  ? 10000
                  : b._values.position;

              return a._values.position - b._values.position;
            }),
          });
        });
    });
  }
}
